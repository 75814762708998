import { Button, Flex, HStack, Icon, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { useContext } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { UserContext } from '../../contexts/UserContext';

export default function SortBar(props: any) {
    const { gotchiPocketsLoading } = useContext(UserContext);
    const {
        setSortBy,
        setSortDir,
        options,
        sortByPressed,
        sortDirPressed,
        ...rest } = props

    return (
        <HStack w='full' h='full' spacing='' align='center' justify='center'>
            <VStack h='fit-content' w='fit-content' spacing='' justify='center'>
            <Button
                leftIcon={IoChevronUpOutline(Icon)}
                flexDirection='column'
                variant='sort'
                border=''
                size='xs'
                iconSpacing=''
                color='mainUi.warm'
                isActive={(sortDirPressed === 'asc') ? (true) : (false)}
                onClick={() => setSortDir('asc')} />
            <Button
                leftIcon={IoChevronDownOutline(Icon)}
                flexDirection='column'
                iconSpacing=''
                variant='sort'
                border=''
                size='xs'
                color='mainUi.warm'
                isActive={(sortDirPressed === 'desc') ? (true) : (false)}
                onClick={() => setSortDir('desc')} />
            </VStack>
            <Wrap pr='5px' pl='5px' pb='8px' pt='8px' w='full' h='full' justify='start' align='start' spacing='4px'>
            {options.map((sortOption: any, index: any) => {
                if (sortOption.name === 'ITEMS') {
                    return (
                    <WrapItem key={sortOption.name + sortOption.sort + index}>
                    <Button
                        key={sortOption.name + 'Sort'}
                        variant='sort'
                        w='fit-content'
                        size='xs'
                        filter={gotchiPocketsLoading ? 'auto' : 'none'}
                        blur={gotchiPocketsLoading ? 'sm' : 'none'}
                        isActive={(sortByPressed === sortOption.sort) ? (true) : (false)}
                        onClick={() => {
                            if (!gotchiPocketsLoading) {
                                setSortBy(sortOption.sort)
                            }
                        }}
                        _active={{
                            borderColor: 'mainUi.ggMagenta'
                        }}>
                        <Text variant='identifier' color='mainUi.ggMagenta'>
                            {sortOption.name}
                        </Text>
                    </Button>
                    </WrapItem>)
                } else return (
                    <WrapItem key={sortOption.name + sortOption.sort + index}>
                    <Button
                        key={sortOption.name + 'Sort'}
                        variant='sort'
                        size='xs'
                        isActive={(sortByPressed === sortOption.sort) ? (true) : (false)}
                        onClick={() => setSortBy(sortOption.sort)}
                        _active={{
                            borderColor: 'mainUi.ggMagenta'
                        }}>
                        <Text variant='identifier' color='mainUi.ggMagenta'>
                            {sortOption.name}
                    </Text>
                    </Button>
                    </WrapItem>
                )
            })}
            </Wrap>
        </HStack>
    )
}