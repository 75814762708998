import diamondABI from '../abi/diamond.json'
import installationABI from '../abi/installation_diamond.json'
import tileABI from '../abi/tile_diamond.json'
import realmABI from '../abi/realm_diamond.json'

const abis: any = {
    'tiles': {
        abi: tileABI,
        address: '0x9216c31d8146bCB3eA5a9162Dc1702e8AEDCa355'
    },
    'installations': {
        abi: installationABI,
        address: '0x19f870bD94A34b3adAa9CaA439d333DA18d6812A'
    },
    'items': {
        abi: diamondABI,
        address: '0x86935F11C86623deC8a25696E1C19a8659CbF95d'
    },
    'tickets': {
        abi: diamondABI,
        address: '0x86935F11C86623deC8a25696E1C19a8659CbF95d'
    },
    'gotchiChannel': {
        abi: realmABI,
        address: '0x1D0360BaC7299C86Ec8E99d0c1C9A95FEfaF2a11'
    }
}

export async function callContract(props: any) {
    let ethers = require('ethers')
    const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
    let contract = new ethers.Contract(abis[props.desired].address, abis[props.desired].abi, provider);

    try {
        let promise: any;
        switch (props.desired) {
            case 'tiles':
                promise = await contract.tilesBalancesWithTypes(props.userAddress);
                break
            case 'installations':
                promise = await contract.installationsBalancesWithTypes(props.userAddress);
                break
            case 'items':
                promise = await contract.itemBalancesWithTypes(props.userAddress)
                break
            case 'gotchiChannel':
                promise = await contract.getLastChanneled(props.id)
                break
        } return (
            { status: 'success', data: promise }
        )
    } catch (error: any) {
        return (
            { status: 'failure', data: error }
        )
    }
}

export default callContract;