import { Contract, Provider } from 'ethers-multicall';  


const realmAbiAddress = '0x1D0360BaC7299C86Ec8E99d0c1C9A95FEfaF2a11'
const abi = [
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "_realmId",
                        "type": "uint256"
                    }
                ],
                "name": "getRealmAlchemica",
                "outputs": [
                    {
                        "internalType": "uint256[4]",
                        "name": "",
                        "type": "uint256[4]"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
        ]

 
        export default async function getParcelAlchemica(props: any) {
            let ethers = require('ethers')
            const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com')
            const realmContract = new Contract(realmAbiAddress, abi);
            const maticCallProvider = new Provider(provider, 137);
    
            const result = await maticCallProvider.all(props.parcels.map((parcel: any) => {
                    return realmContract.getRealmAlchemica(parcel.id)
                }));
            return (
                result
            );
        }
        
