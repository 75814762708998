import { useContext, useRef, useState } from "react";
import { VStack, Wrap, useMediaQuery, Flex, Spinner, Icon, Text, HStack } from "@chakra-ui/react";
import GotchiCard from "./GotchiCard";
import { UserContext } from "../../contexts/UserContext";
import FilterByStat from "../Common/FilterByStat";
import SortBar from "../Common/SortBar";
import { fadeIn } from "../../styles/Animations";
import { AavegotchiHome } from "../../utils/EcosystemIcons";
import SectionHeader from "../Common/SectionHeader";

const GOTCHI_FILTERS: { [key: string]: any } = {
  't': (gotchi: any) => gotchi.id,
  'haunt1': (gotchi: any) => gotchi.hauntId == 1,
  'equipped': (gotchi: any) => gotchi.naked == false,
  'naked': (gotchi: any) => gotchi.naked == true,
  'lent': (gotchi: any) => gotchi.lentOut == true,
}

const FILTER_NAMES = Object.keys(GOTCHI_FILTERS);

const GOTCHI_SORT_OPTIONS = [
  { name: 'ID', sort: 'id' },
  { name: 'BRS', sort: 'modifiedRarityScore' },
  { name: 'KIN', sort: 'kinship' },
  { name: 'ITEMS', sort: 'pocket' },
  { name: 'AGE', sort: 'claimedAt' }
]

const styles = {
  a: {
    background: 'none',
    color: '#FA34F3'
  }
};

export default function GotchiCards() {
  const { gotchisOwned, gotchiPocketValues, gotchisLoading } = useContext(UserContext);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [sortGotchisBy, setSortGotchisBy] = useState('modifiedRarityScore');
  const [gotchiSortDir, setGotchiSortDir] = useState('asc');
  const [filterState, setGotchiFilter] = useState('t');
  const viewPortRef = useRef(null)

  const propComparator = (toSort: any, sortDir: any) => {
    if (toSort == 'pocket') {
      return (a: any, b: any) => sortDir === 'asc' ? gotchiPocketValues[a.id] - gotchiPocketValues[b.id] : gotchiPocketValues[b.id] - gotchiPocketValues[a.id];
    } else if (toSort == 'claimedAt') {
      return (a: any, b: any) => sortDir === 'asc' ? b[toSort] - a[toSort] : a[toSort] - b[toSort];
    } else {
      return (a: any, b: any) => sortDir === 'asc' ? a[toSort] - b[toSort] : b[toSort] - a[toSort];
    }
  };

  return (
    gotchisLoading ? (
      <Flex
        animation={fadeIn}
        w='full'
        h='full'
        bg=''
        justify='center'
        align='center'
        position='relative'>
        <Spinner
          h='50px'
          w='50px'
          thickness='3px'
          color='mainUi.warmDark'
          position='absolute' />
        <Icon
          as={AavegotchiHome}
          h='24px'
          w='24px'
          fill='mainUi.warm' />
      </Flex>
    ) : (
      gotchisOwned.length > 0 ? (
      <VStack
        animation={fadeIn}
        spacing=''
        w={isMobile ? '98vw' : 'full'}
        h='full'
        bg=''>
        <SectionHeader title='Gotchis'/>
        <HStack
            spacing=''
            justify='center'
            align='center'
            w='full'
            h='fit-content'
            borderBottom='1px'
            borderColor='mainUi.darker'
            bgGradient='linear(to-r, mainUi.warmDark 4%, mainUi.dark 80%, mainUi.darker 50%, mainUi.dark 80%, mainUi.warmDark 96%)'>
        <SortBar
          options={GOTCHI_SORT_OPTIONS}
          setSortBy={setSortGotchisBy}
          setSortDir={setGotchiSortDir}
          sortByPressed={sortGotchisBy}
          sortDirPressed={gotchiSortDir} />
        <FilterByStat
          filterList={FILTER_NAMES}
          isPressed={filterState}
          setFilter={setGotchiFilter}
          filteringFor='gotchis' />
          </HStack>
          <Wrap
            ref={viewPortRef}
            justify='center'
            align='center'
            overflowY='scroll'
            paddingTop='10px'
            overflowX='hidden'
            bg='mainUi.dark'
            w='full'
            h='full'
            sx={{
              '&::-webkit-scrollbar': {
                borderRadius: '1px',
                width: '10px',
                backgroundColor: 'mainUi.dark',
              },
              '&::-webkit-scrollbar-track': {
                marginRight: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'mainUi.warmDark',
              },
            }}>
            {gotchisOwned.filter(GOTCHI_FILTERS[filterState])
              .sort(propComparator(sortGotchisBy, gotchiSortDir))
              .map((gotchi: any) => {
                return (
                  <GotchiCard
                    key={`#${gotchi.id} - ${gotchi.name}`}
                    name={gotchi.name}
                    viewPortRef={viewPortRef}
                    id={gotchi.id}
                    lvl={gotchi.level}
                    kinship={gotchi.kinship}
                    traits={gotchi.numericTraits}
                    modifiedTraits={gotchi.modifiedNumericTraits}
                    withSetsTraits= {gotchi.withSetsNumericTraits}
                    baseBrs={gotchi.withSetsRarityScore}
                    modifiedBrs={gotchi.modifiedRarityScore}
                    withSetsBrs={gotchi.withSetsRarityScore}
                    xp={gotchi.experience}
                    xpToLvl={gotchi.toNextLevel}
                    hauntId={gotchi.hauntId}
                    collateral={gotchi.collateral}
                    wearables={gotchi.equippedWearables}
                    pocketValue={gotchi.pocketValue}
                    claimedAt={gotchi.claimedAt}
                    naked={gotchi.naked}
                    lent={gotchi.lentOut}
                    svg={gotchi.svg} />
                )
              })
            })
          </Wrap>
          </VStack >
        ) : (
            <VStack animation={fadeIn} w='full' bg='' h='full' align='center' justify='center' spacing='20px'>
              <Icon h='100px' w='100px' fill='mainUi.ggMagenta' as={AavegotchiHome} />
              <Text
                maxW='200px'
                variant='alternate'
                color='mainUi.warm'
                sx={{ lineHeight: '16px' }}>
                you don't own any gotchis...
                <a style={styles.a} href="https://app.aavegotchi.com/baazaar/aavegotchis?sort=priceLow" target="_blank"> visit the baazaar </a> 
                to purchase one and
                <a style={styles.a} href="https://app.aavegotchi.com/baazaar/aavegotchis?sort=priceLow" target="_blank"> join the gang!</a>
              </Text>
            </VStack>
        )
      )
  )
};