import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import Token from './Token';
import { UserContext } from '../../contexts/UserContext';
import SectionHeader from '../Common/SectionHeader';

export default function TokenTicker() {
    const { tokenData, erc20Loading } = useContext(UserContext)

    return (
        tokenData ? (
            <VStack h='full' w='full' spacing=''>
            <SectionHeader title='Tokens'/>
            <HStack
                spacing='5px'
                h='full'
                w='full'
                align='center'
                justify='center'
                padding='4px'>
                {
                // !tokenData[0].prices ? null :
                tokenData.map((token: any) => {
                    return (
                        <Token
                            key={token.ticker}
                            ticker={token.ticker}
                            marketPrice={token.prices}
                            change24hr={token.change24hr}
                            colour={token.colour}
                            address={token.address}
                            balance={token.balance}
                            ghst={tokenData[4].prices} />
                    )
                })}
            </HStack >
            </VStack>
        ) : (
            <Flex h='full'
            w='full'
            bg='mainUi.warmdark'
            align='center'
            justify='center'>
                <Text
                    variant='identifier'>
                    no response from server :3
                </Text>
            </Flex>
        )
    )
};