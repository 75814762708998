import { Button, Grid, GridItem, TabList, TabPanel, TabPanels, Tabs, useMediaQuery, useTab, Text, VStack, Flex, HStack, Spacer } from "@chakra-ui/react";
import GotchiCards from "../components/GotchiCards/GotchiCards";
import ParcelCards from "../components/ParcelCards/ParcelCards";
import ItemCards from "../components/ItemCards/ItemCards";
import {  AavegotchiHome, AavegotchiVerse } from "../utils/EcosystemIcons";
import React, { useContext } from "react";
import TokenTicker from "../components/TokenTicker/TokenTicker";
import InstCards from "../components/InstallationCards.tsx/InstCards";
import SitePanel from "../components/SitePanel/SitePanel";
import Refresh from "../components/Common/Refresh";
import CurrencySelect from "../components/CurrencySelect";
import { UserContext } from "../contexts/UserContext";

const CustomTab = React.forwardRef((props: any, ref: any) => {
  const { tab } = props;
  const tabProps = useTab({ ...props, ref })
  return (
    <Button
      bg=''
      variant='dash'
      color='mainUi.warm'
      size='xs'
      leftIcon={tab.icon}
      h='fit-content'
      w='fit-content'
      {...tabProps}>
      {tab.name.length > 7 ? (
        tab.name.includes("gotchi") ? (
          tab.name.replace("gotchi ", "")
        ) : (
          tab.name.replace("parcel ", "")
        )
        ) : (tab.name)}
    </Button>
  )
})

const tabs = [
  {
    name: 'gotchis',
    icon: <AavegotchiHome fill='currentColor' />,
    place: <GotchiCards />
  },
  {
    name: 'parcels',
    icon: <AavegotchiVerse fill='currentColor' />,
    place: <ParcelCards />
  },
  {
    name: 'gotchi items',
    icon: <AavegotchiHome fill='currentColor' />,
    place: <ItemCards />
  },
  {
    name: 'parcel items',
    icon: <AavegotchiVerse fill='currentColor' />,
    place: <InstCards />
  }
]

export default function Dash() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { userAddress } = useContext(UserContext);

  return (
    isMobile ? (
      <VStack align='center' justify='center' w='98vw' h='100vh' spacing=''>
        <HStack align='center' justify='center' w='full' spacing='10px'>
          <Spacer/>
          <CurrencySelect />
        <Spacer/>
        <Refresh stuff='Gotchi Stuff' iconSide='right'/>
        <Text variant='identifier'>
            REFRESH
          </Text>
        <Refresh stuff='Parcel Stuff' iconSide='left'/>
        <Spacer/>
        <Text
          w='fit-content'
          fontSize={['8px', '9px', '10px', '10px']}
          variant='identifier'>
          {userAddress.substring(0, 6)}
          <br />
          ...{userAddress.substring(37, 41)}
        </Text>
        <Spacer/>
      </HStack>
      <Spacer/>
      <Flex w='98vw' h='10vh' align='center' justify='center'>
      <TokenTicker/>
      </Flex>
      <Spacer/>
      <Tabs variant='unstyled' align='center'>
        <TabList gap=''>
          {tabs.map((tab: any) => {
            return (
              <CustomTab
                key={tab.name + 'Tab'}
                _selected={{ color: 'mainUi.ggMagenta', bg: '' }}
                tab={tab} />)
          })}
        </TabList>
        <TabPanels w='98vw' h='75vh'>
          {tabs.map((tab: any) => {
            return (
              <TabPanel pl='0' pr='0' pb='0' pt='8px' key={tab.name + 'TabPanel'} w='full' h='full'>
                {tab.place}
              </TabPanel>)
          })}
        </TabPanels>
      </Tabs>
      </VStack>
    ) : (
      <Grid
        alignContent='center'
        justifyContent='center'
        templateAreas={`"settings tickets tokens"
                        "gotchis parcels items" 
                        "gotchis parcels installations"`}
        gridTemplateRows={'10vh 40vh 40vh'}
        gridTemplateColumns={'32vw 32vw 32vw'}
        rowGap='1.6vh'
        columnGap='1vw'>
        <GridItem bg='mainUi.dark' area={'settings'}>
          <SitePanel />
        </GridItem>
        <GridItem bg='mainUi.dark' area={'tickets'}>
        </GridItem>
        <GridItem bg='mainUi.dark' area={'tokens'}>
          <TokenTicker />
        </GridItem>
        <GridItem area={'gotchis'}>
          <GotchiCards />
        </GridItem>
        <GridItem area={'parcels'}>
          <ParcelCards />
        </GridItem>
        <GridItem area={'items'}>
          <ItemCards />
        </GridItem>
        <GridItem area={'installations'}>
          <InstCards />
        </GridItem>
      </Grid>
    )
  )
};
