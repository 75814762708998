import { Text, HStack, Image, Icon, useMediaQuery, VStack, Spinner, Flex } from '@chakra-ui/react';
import { IoCaretDown, IoCaretUp } from 'react-icons/io5';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { ethers, utils } from 'ethers';
import millify from 'millify';
import erc20ABI from '../../abi/erc20.json'
import { FaEquals, FaTimes } from 'react-icons/fa';

export default function Token(props: any) {
    const { ticker, marketPrice, change24hr, colour, address, ghst } = props;
    const { userAddress, userCurrency, floorPriceTotalValue, tokenBalances, tokenData } = useContext(UserContext);
    const [isHalfWidth] = useMediaQuery("(max-width: 1100px)");
    const [isWidthOne] = useMediaQuery("(max-width: 1300px)");
    const [isHeightOne] = useMediaQuery("(max-height: 800px)");
    const [isHeightTwo] = useMediaQuery("(max-height: 610px)");
    const [isHeightThree] = useMediaQuery("(max-height: 490px)");
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const componentMounted = useRef(true);
    const [loading, setLoading] = useState(true)

    const tickerToDecimal: { [key: string]: number } = {
        'FUD': 4,
        'FOMO': 3,
        'ALPHA': 3,
        'KEK': 3,
        'GHST': 2,
        'GLTR': 5
    }

    useEffect(() => {
        getBalance()
        let i = setInterval(getBalance, (2 * 60000));
        return () => {
            componentMounted.current = false;
            clearInterval(i);
        }
    }, [])

    const getBalance = async () => {
        const provider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
        const token = new ethers.Contract(address, erc20ABI, provider)
        const balance = await token.balanceOf(userAddress)
            .then((res: any) => { if (componentMounted.current) { return (res) } })
            .catch((error: any) => console.log(error))

        const formattedBalance = parseFloat(utils.formatEther(balance))

        if (tokenBalances[ticker] == undefined || tokenBalances[ticker] != formattedBalance) {
            floorPriceTotalValue('tokens', ((formattedBalance - (tokenBalances[ticker] == undefined ? 0 : tokenBalances[ticker])) * marketPrice.gbp) / ghst.gbp, null, [])
            tokenBalances[ticker] = formattedBalance
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    return (
        loading ? (
            <VStack
                    border='1px'
                    borderColor={colour}
                    spacing=''
                    w='full'
                    h='full'
                    align='self-start'
                    bg='mainUi.darker'>
                    <HStack
                        bg={colour}
                        w='full'
                        h={isHeightThree ? 'full' : 'fit-content'}
                        spacing='2px'
                        justify='center'
                        align='center'>
                        <Image
                                h='10px'
                                w='10px'
                                sx={{ filter: 'drop-shadow(0px 0px 1px rgb(0 0 0 / 1))' }}
                                src={AssetLib.tickerToImage(ticker)} />
                        <HStack align='center' justify='center' spacing=''>
                            <Icon
                                h='13px'
                                w='13px'
                                as={change24hr[userCurrency] < 0 ? IoCaretDown : IoCaretUp}
                                color='mainUi.darker'>
                            </Icon>
                            <Text
                                fontSize={['8px', '8px', '8px', '9px', '9px']}
                                key='24hrChange'
                                variant='identifier'
                                color='mainUi.darker'>
                                {(Object.keys(change24hr).length > 0 ? change24hr[userCurrency].toFixed(1) : 'null')}%
                            </Text>
                        </HStack>
                    </HStack>
                    <Flex h='full' w='full' align='center' justify='center'>
                    <Spinner color={colour}/>
                    </Flex>
                    </VStack>
        ) : (
            isMobile ? (
                <VStack
                    border='1px'
                    borderColor={colour}
                    spacing=''
                    w='full'
                    h='full'
                    align='self-start'
                    bg='mainUi.darker'>
                    <HStack
                        bg={colour}
                        w='full'
                        h={isHeightThree ? 'full' : 'fit-content'}
                        spacing='2px'
                        justify='center'
                        align='center'>
                        <Image
                                h='10px'
                                w='10px'
                                sx={{ filter: 'drop-shadow(0px 0px 1px rgb(0 0 0 / 1))' }}
                                src={AssetLib.tickerToImage(ticker)} />
                        <HStack align='center' justify='center' spacing=''>
                            <Icon
                                h='13px'
                                w='13px'
                                as={change24hr[userCurrency] < 0 ? IoCaretDown : IoCaretUp}
                                color='mainUi.darker'>
                            </Icon>
                            <Text
                                fontSize={['8px', '8px', '8px', '9px', '9px']}
                                key='24hrChange'
                                variant='identifier'
                                color='mainUi.darker'>
                                {(Object.keys(change24hr).length > 0 ? change24hr[userCurrency].toFixed(1) : 'null')}%
                            </Text>
                        </HStack>
                    </HStack>
                    {isHeightThree ? (null) : (
                        <VStack
                            w='full'
                            h='full'
                            spacing=''
                            align='center'
                            justify='center'>
                            {isHeightOne ? (null) : (
                                <Text
                                    fontSize={['8px', '8px', '8px', '9px', '9px']}
                                    key='24hrChange'
                                    variant='identifier'
                                    color={colour}>
                                    {(marketPrice != undefined ? marketPrice[userCurrency].toFixed(tickerToDecimal[ticker]) : 'null')}
                                    {userCurrency.toUpperCase()}
                                </Text>)}
                            {isHeightTwo ? (null) : (
                                <HStack align='center' justify='center' spacing=''>
                                    <Icon
                                        w={['8px', '8px', '8px', '10px', '10px']}
                                        h={['8px', '8px', '8px', '10px', '10px']}
                                        color={colour}
                                        as={FaTimes} />
                                    <Text
                                        fontSize={['8px', '8px', '8px', '9px', '9px']}
                                        key='balance'
                                        variant='identifier'
                                        color={colour}>
                                        {millify(tokenBalances[ticker])}
                                         BAL
                                    </Text>
                                </HStack>)}
                            <HStack align='center' justify='center' spacing=''>
                                <Icon
                                    w={['7px', '7px', '7px', '9px', '9px']}
                                    h={['7px', '7px', '7px', '9px', '9px']}
                                    color={colour}
                                    as={FaEquals} />
                                <Text
                                    fontSize={['8px', '8px', '8px', '9px', '9px']}
                                    key='value'
                                    variant='identifier'
                                    color={colour}>
                                    {millify(marketPrice[userCurrency] * tokenBalances[ticker])}
                                    {userCurrency.toUpperCase()}
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </VStack>
            ) : (
                <VStack
                    border='1px'
                    borderColor={colour}
                    spacing=''
                    w='full'
                    h='full'
                    align='self-start'
                    bg='mainUi.darker'>
                    <HStack
                        bg={colour}
                        w='full'
                        h={isHeightThree ? 'full' : 'fit-content'}
                        spacing='2px'
                        justify='center'
                        align='center'>
                        {isHalfWidth ? (null) : (
                            <Image
                                h='10px'
                                w='10px'
                                sx={{ filter: 'drop-shadow(0px 0px 1px rgb(0 0 0 / 1))' }}
                                src={AssetLib.tickerToImage(ticker)} />
                        )}
                        <HStack align='center' justify='center' spacing=''>
                            <Icon
                                h='13px'
                                w='13px'
                                as={change24hr[userCurrency] < 0 ? IoCaretDown : IoCaretUp}
                                color='mainUi.darker'>
                            </Icon>
                            <Text
                                fontSize={['8px', '8px', '8px', '9px', '9px']}
                                key='24hrChange'
                                variant='identifier'
                                color='mainUi.darker'>
                                {(Object.keys(change24hr).length > 0 ? change24hr[userCurrency].toFixed(1) : 'null')}%
                            </Text>
                        </HStack>
                    </HStack>
                    {isHeightThree ? (null) : (
                        <VStack
                            w='full'
                            h='full'
                            spacing=''
                            align='center'
                            justify='center'>
                            {isHeightOne ? (null) : (
                                <Text
                                    fontSize={['8px', '8px', '8px', '9px', '9px']}
                                    key='24hrChange'
                                    variant='identifier'
                                    color={colour}>
                                    {(marketPrice != undefined ? marketPrice[userCurrency].toFixed(tickerToDecimal[ticker]) : 'null')}
                                    {isWidthOne ? (
                                        null
                                    ) : (
                                        ` ${userCurrency.toUpperCase()}`
                                    )}
                                </Text>)}
                            {isHeightTwo ? (null) : (
                                <HStack align='center' justify='center' spacing=''>
                                    <Icon
                                        w={['8px', '8px', '8px', '10px', '10px']}
                                        h={['8px', '8px', '8px', '10px', '10px']}
                                        color={colour}
                                        as={FaTimes} />
                                    <Text
                                        fontSize={['8px', '8px', '8px', '9px', '9px']}
                                        key='balance'
                                        variant='identifier'
                                        color={colour}>
                                        {millify(tokenBalances[ticker])}
                                        {isWidthOne ? (
                                            null
                                        ) : (
                                            ' BAL'
                                        )}
                                    </Text>
                                </HStack>)}
                            <HStack align='center' justify='center' spacing=''>
                                <Icon
                                    w={['7px', '7px', '7px', '9px', '9px']}
                                    h={['7px', '7px', '7px', '9px', '9px']}
                                    color={colour}
                                    as={FaEquals} />
                                <Text
                                    fontSize={['8px', '8px', '8px', '9px', '9px']}
                                    key='value'
                                    variant='identifier'
                                    color={colour}>
                                    {millify(marketPrice[userCurrency] * tokenBalances[ticker])}
                                    {isWidthOne ? (
                                        null
                                    ) : (
                                        ` ${userCurrency.toUpperCase()}`
                                    )}
                                </Text>
                            </HStack>
                        </VStack>
                    )}
                </VStack>
        )
        
        )
    )
}