import { ethers, utils } from "ethers";
import { Contract, Provider } from "ethers-multicall";
import React from "react";
import { useState } from "react";
import callContract from "../api/CallContracts";
import QueryGraph from "../api/QueryGraph";
import { alchemicaToTicker, altarLvlToCooldown, formatInstallationAlchemicaCost, installationNameToRarity } from "../utils/EcosystemHelpers";
import { items } from "../utils/ItemTypes";
import realmABI from '../abi/realm_diamond.json'
import getParcelAlchemica from "../api/methods/getParcelAlchemica";
import getParcelAlchemicaClaimed from "../api/methods/getParcelAlchemicaClaimed";


interface UserMedia {
    'parcels': { [key: string]: string },
    'items': { [key: string]: string },
    'installations': { [key: number]: string },
    'tiles': { [key: number]: string }
    'gotchis': {
        [key: string]: { svg: string }
        // , variant: string, eyeColour: string, setHandsOut: string, setHandsIn: string }
    },
}

interface TokenBalances {
    [key: string]: number
}

interface DailyHarvestTotals {
    [key: string]: any
}

interface CraftCosts {
    [key: string]: number
}
interface FloorPrices {
    [key: string]: { [key: number | string]: number },
}

interface ParcelData {
    [key: number]: {},
}

interface InstallationTypes {
    [key: number]: {
        name: string,
        level: number,
        nextLvlId: number,
        disc: boolean,
        alchemicaCost: any[],
        spillRadius: number,
        spillRate: number,
        harvestRate: number,
        capacity: number
    }
}
interface Token {
    ticker: string,
    address: string,
    id: string,
    prices: any,
    change24hr: any,
    colour: string
}

interface Value {
    total: number,
    count: number
}

interface GotchiPockets {
    [key: number]: number
}

interface Stats {
    [key: string]: number
}

interface UserContextProps {
    getClientData: () => void,
    getUserGotchis: (refresh: any) => Promise<void>,
    getUserParcels: (refresh: any) => Promise<void>,

    setCurrency: (currency: string) => void,
    userCurrency: string,
    setBlock: (block: number) => void,
    block: number;
    setAddress: (address: string) => void,
    userAddress: string,

    setUserMedia: (userMedia: UserMedia) => void,
    userMedia: UserMedia,
    setFloorPrices: (floorPrices: FloorPrices) => void,
    floorPrices: FloorPrices,
    floorPriceAppend: (type: any, id: any, price: any) => void,

    setTokenData: (tokens: [Token]) => void,
    tokenData: any[],
    setTokenBalances: (tokenBalances: TokenBalances) => void,
    tokenBalances: TokenBalances
    setERC20Loading: (status: boolean) => void,
    erc20Loading: boolean,

    setItems: (wearables: []) => void,
    itemsOwned: {}[],
    setGotchiItemStats: (stats: Stats) => void,
    gotchiItemStats: Stats
    setItemsLoading: (status: boolean) => void,
    itemsLoading: boolean,
    setItemsTVLoading: (status: boolean) => void,
    itemsTVLoading: boolean
    handleItemFloorPrice: (id: number, floorPrice: number) => void,
    floorPriceTotalValue: (type: string, value: number, setSectionTotalLoading: any, dataArray: any) => void,
    setCraftCosts: (craftCosts: CraftCosts) => void,
    craftCosts: CraftCosts,

    setGotchis: (gotchis: []) => void,
    gotchisOwned: {}[],
    setGotchiStats: (stats: Stats) => void,
    gotchiStats: Stats
    setGotchisLoading: (status: boolean) => void,
    gotchisLoading: boolean,
    setGotchiPocketValues: (floorPrices: { [key: number]: number }) => void,
    gotchiPocketValues: { [key: number]: number },
    setGotchiPocketsLoading: (status: boolean) => void,
    gotchiPocketsLoading: boolean,

    setParcels: (parcels: []) => void,
    parcelsOwned: any[],
    setParcelStats: (stats: Stats) => void,
    parcelStats: Stats
    setParcelData: (parcelData: ParcelData) => void,
    parcelData: ParcelData,
    setParcelsLoading: (status: boolean) => void,
    parcelsLoading: boolean,
    setHandleParcelChannelLoading: (status: boolean) => void,
    handleParcelChannelLoading: boolean,
    setDailyHarvestTotals: (totals: DailyHarvestTotals) => void,
    dailyHarvestTotals: DailyHarvestTotals,

    setInstallations: (installations: []) => void,
    installationsOwned: {}[],
    setParcelItemStats: (stats: Stats) => void,
    parcelItemStats: Stats
    setInstallationTypes: (installationType: InstallationTypes) => void,
    installationTypes: InstallationTypes,
    setInstsTVLoading: (status: boolean) => void,
    instsTVLoading: boolean
    setInstallationsLoading: (status: boolean) => void,
    installationsLoading: boolean,
};

export const UserContext = React.createContext<UserContextProps>({
    getClientData: () => { },
    getUserGotchis: async () => { },
    getUserParcels: async () => { },

    setCurrency: () => { },
    userCurrency: "",
    setBlock: () => { },
    block: 0,
    setAddress: () => { },
    userAddress: "",

    setUserMedia: () => { },
    userMedia: { gotchis: {}, parcels: {}, items: {}, installations: {}, tiles: {} },
    setFloorPrices: () => { },
    floorPrices: { items: {}, installations: {}, tiles: {} },
    floorPriceAppend: () => { },
    floorPriceTotalValue: () => { },
    setCraftCosts: () => { },
    craftCosts: {},

    setTokenData: () => { },
    tokenData: [{}],
    setTokenBalances: () => { },
    tokenBalances: {},
    setERC20Loading: () => { },
    erc20Loading: true,

    setItems: () => { },
    itemsOwned: [{}],
    setGotchiItemStats: () => { },
    gotchiItemStats: {},
    setItemsLoading: () => { },
    itemsLoading: true,
    setItemsTVLoading: () => { },
    itemsTVLoading: true,
    handleItemFloorPrice: () => { },

    setGotchis: () => { },
    gotchisOwned: [{}],
    setGotchiStats: () => { },
    gotchiStats: {},
    setGotchisLoading: () => { },
    gotchisLoading: true,
    setGotchiPocketValues: () => { },
    gotchiPocketValues: {},
    setGotchiPocketsLoading: () => { },
    gotchiPocketsLoading: true,

    setParcels: () => { },
    parcelsOwned: [{}],
    setParcelStats: () => { },
    parcelStats: {},
    setParcelData: () => { },
    parcelData: {},
    setParcelsLoading: () => { },
    parcelsLoading: true,
    setHandleParcelChannelLoading: () => { },
    handleParcelChannelLoading: true,
    setDailyHarvestTotals: () => { },
    dailyHarvestTotals: {},

    setInstallations: () => { },
    installationsOwned: [{}],
    setParcelItemStats: () => { },
    parcelItemStats: {},
    setInstallationsLoading: () => { },
    installationsLoading: true,
    setInstsTVLoading: () => { },
    instsTVLoading: true,
    setInstallationTypes: () => { },
    installationTypes: {},
});

export default function UserContextProvider(props: any) {
    const [userAddress, setAddress] = useState("");
    const [userCurrency, setCurrency] = useState("usd")
    const [block, setBlock] = useState(0)

    const [craftCosts, setCraftCosts] = useState<CraftCosts>({})
    const [floorPrices, setFloorPrices] = useState<FloorPrices>({ 'installations': {}, 'items': {}, 'tiles': {} })
    const [userMedia, setUserMedia] = useState<UserMedia>({ 'gotchis': {}, 'installations': {}, 'items': {}, 'parcels': {}, 'tiles': {} })

    const [tokenData, setTokenData] = useState<any[]>([])
    const [tokenBalances, setTokenBalances] = useState<TokenBalances>({ 'FUD': 0, 'FOMO': 0, 'ALPHA': 0, 'KEK': 0, 'GLTR': 0, 'GHST': 0 })
    const [erc20Loading, setERC20Loading] = useState(true)

    const [gotchisOwned, setGotchis] = useState<any[]>([]);
    const [gotchisLoading, setGotchisLoading] = useState(true)
    const [gotchiPocketValues, setGotchiPocketValues] = useState<GotchiPockets>({})
    const [gotchiPocketsLoading, setGotchiPocketsLoading] = useState(true)
    const [gotchiStats, setGotchiStats] = useState<Stats>({})

    const [parcelsOwned, setParcels] = useState<any[]>([]);
    const [parcelData, setParcelData] = useState<ParcelData>({})
    const [parcelsLoading, setParcelsLoading] = useState(true)
    const [handleParcelChannelLoading, setHandleParcelChannelLoading] = useState(true)
    const [parcelStats, setParcelStats] = useState<Stats>({})
    const [dailyHarvestTotals, setDailyHarvestTotals] = useState<DailyHarvestTotals>({})

    const [itemsOwned, setItems] = useState<any[]>([]);
    const [itemsLoading, setItemsLoading] = useState(true)
    const [itemsTVLoading, setItemsTVLoading] = useState(true)
    const [gotchiItemStats, setGotchiItemStats] = useState<Stats>({})

    const [installationsOwned, setInstallations] = useState<any[]>([])
    const [installationsLoading, setInstallationsLoading] = useState(true)
    const [instsTVLoading, setInstsTVLoading] = useState(true)
    const [installationTypes, setInstallationTypes] = useState<InstallationTypes>({})
    const [parcelItemStats, setParcelItemStats] = useState<Stats>({})

    // "https://api.5b40d241fa65120d117f22d54-19535.sites.k-hosting.co.uk"
    // "http://localhost:3001"
    let serverURL = "http://api.5b40d241fa65120d117f22d54-19535.sites.k-hosting.co.uk"

    const getBlock = async () => {
        let i = await fetch(serverURL + "/polygon-block")
        .then((res: any) => { 
            return res.json()
        }).then((jsonRes: any) => {
            setBlock(jsonRes);
        }).catch((error: any) => {
            console.log(error + ' (polygon block from server)');
        })
    }

    async function getERC20Data() {
        let i = await fetch(serverURL + "/aavegotchi-token-prices")
            .then((res: any) => {
                return res.json()
            }).then((jsonRes: any) => {
                setTokenData(jsonRes);
                setERC20Loading(false)
            }).catch((error: any) => {
                console.log(error + ' (tokenData from server)');
            })
    }

    const getUserGotchis = async (setRefreshing: any) => {
        setItemsTVLoading(true)
        setGotchisLoading(true)
        setItemsLoading(true)
        let gotchis = await QueryGraph({ desired: 'userGotchis', variables: { userAddress: userAddress }})
            .then((res: any) => {
                if (res.status === 'success') {
                    let data = res.data.data
                    if (data['gotchis0'] !== null) {
                        let gotchis = data['gotchis0'].gotchisOwned.concat(Object.keys(data).map((key: any, index: any) => {
                            if (index > 0 && data[key].gotchisOwned.length > 0) {
                                return data[key].gotchisOwned
                            }
                        })).filter((gotchi: any) => gotchi)

                        let arraysAtTheEnd = gotchis.filter((gotchis: any) => gotchis.length)
                        let cleanedGotchis = gotchis.filter((gotchis: any) => !gotchis.length)
                        arraysAtTheEnd.forEach((array: any) => Array.prototype.push.apply(cleanedGotchis, array))

                        return cleanedGotchis
                    } else {
                        return []
                    }
                } else {
                    return []
                }
            })
            // 0x47eb98abb32976bc1172ff6ad41831677e4865a0
            // 0xb9ff017c875f5c39d0018d1df86fbd92943d5b82
        let lentGotchis = await QueryGraph({ desired: 'lentGotchis', variables: { userAddress: userAddress}})
        .then((res: any) => {
            if (res.status === 'success') {
                let data = res.data.data
                if (data['lentGotchis0'].length > 0) {
                    let lentGotchis = data['lentGotchis0'].concat(Object.keys(data).map((key: any, index: any) => {
                        if (index > 0 && data[key].length > 0) {
                            return data[key]
                        }
                    })).filter((gotchi: any) => gotchi)
            
                    let arraysAtTheEnd = lentGotchis.filter((lentGotchisArray: any) => lentGotchisArray.length)
                    let cleanedGotchis = lentGotchis.filter((lentGotchisArray: any) => !lentGotchisArray.length)
                    arraysAtTheEnd.forEach((array: any) => Array.prototype.push.apply(cleanedGotchis, array))
                    return cleanedGotchis
                } else {
                    return []
                }
            } else {
                return []
            }

        })

        if(lentGotchis.length > 0) { 
            lentGotchis.forEach((lentGotchi: any) => {
                let checkIfLentOut = gotchis.findIndex((gotchi: any) => lentGotchi.gotchi.id === gotchi.id)
                if(checkIfLentOut === -1) {
                    gotchis.push(Object.assign({}, lentGotchi.gotchi, { lentOut: true }))
                }
            })
        }

        let borrowedGotchis = await QueryGraph({ desired: 'borrowedGotchis', variables: { userAddress: userAddress}})
        if(borrowedGotchis.status === 'success') {
            borrowedGotchis.data.data.user.gotchisBorrowed.forEach((borrowedId: any) => {
                let gotchiCheck = gotchis.findIndex((gotchi: any) => gotchi.id === borrowedId)
                if(gotchiCheck !== -1) {
                    gotchis.splice(gotchis[gotchiCheck], 1);
                }
            })
        }

        let itemCache: any[] = [];
        let gotchiItemsStatCache = {
            total: 0,
            common: 0,
            uncommon: 0,
            rare: 0,
            legendary: 0,
            mythical: 0,
            godlike: 0
        }

        let gotchiCache: any[] = [];
        let gotchiStatCache = {
            haunt1: 0,
            equipped: 0,
            naked: 0,
            total: 0,
            lent: 0
        };

        if (gotchis.length > 0) {
            let gotchiIds: any[] = []
            gotchis.forEach((gotchi: any) => {
                gotchiIds.push(gotchi.id)
                let hasWearables = false;
                if (gotchi.hauntId === '1') {
                    gotchiStatCache.haunt1 += 1;
                }

                if(gotchi.lentOut) {
                    gotchiStatCache.lent += 1;
                }

                for (let i = 0; i < 8; i += 1) {
                    if (i !== 7 && gotchi.equippedWearables[i] !== 0) {
                        hasWearables = true;
                        const index = itemCache.findIndex((item: any) => item.id === gotchi.equippedWearables[i])
                        gotchiItemsStatCache.total += 1;

                        if (index < 0) {
                            itemCache.push({
                                name: items[gotchi.equippedWearables[i].toString()].name,
                                id: gotchi.equippedWearables[i],
                                stored: 0,
                                equipped: 1,
                                rarity: items[gotchi.equippedWearables[i].toString()].rarity,
                                rarityScoreModifier: items[gotchi.equippedWearables[i].toString()].rarityScoreModifier,
                                stats: items[gotchi.equippedWearables[i].toString()].stats
                            })
                        } else {
                            itemCache[index].equipped += 1;
                        }
                    }
                }

                // let gotchiData = await QueryGraph({ desired: 'gotchiData', variables: { id: gotchis[i].id }})
                // console.log(gotchiData)
                // let lastChanneled = null
                // if(gotchiData.status == 'success') {
                //     lastChanneled = gotchiData.data.lastChanneledAlchemica
                //     console.log(lastChanneled)
                // }

                gotchiCache.push(Object.assign({}, gotchi, { naked: !hasWearables }))
                switch (hasWearables) {
                    case true:
                        gotchiStatCache.equipped += 1;
                        break
                    case false:
                        gotchiStatCache.naked += 1;
                }
            }
            )

            gotchiStatCache.total = gotchis.length;

            let gotchiSvgs = await QueryGraph({ desired: 'gotchiSvgs', variables: { gotchiIds: gotchiIds }})
            .then((res: any) => {
                if (res.status === 'success') {
                    let data = res.data.data
                    console.log('res')
                    console.log(res)
                    if (data['gotchiSvgs0'].length > 0) {
                        let gotchiSvgs = data['gotchiSvgs0'].concat(Object.keys(data).map((key: any, index: any) => {
                            if (index > 0 && data[key].length > 0) {
                                return data[key]
                            }
                        })).filter((gotchiSvg: any) => gotchiSvg)

                
                        let arraysAtTheEnd = gotchiSvgs.filter((gSvg: any) => gSvg.length)
                        let cleanedGotchiSvgs = gotchiSvgs.filter((lentGotchisArray: any) => !lentGotchisArray.length)
                        arraysAtTheEnd.forEach((array: any) => Array.prototype.push.apply(cleanedGotchiSvgs, array))
                        return cleanedGotchiSvgs
                    } else {
                        return []
                    }
                } else {
                    return []
                }
            })

            if(gotchiSvgs.length > 0) {
                gotchiSvgs.forEach((svgObj: any) => {
                        userMedia['gotchis'][svgObj.id] = {svg: svgObj.svg.replaceAll(".gotchi-", `.gotchi${svgObj.id} .gotchi-`)}
                    })
            }
        }

        setGotchiStats(gotchiStatCache)
        setGotchisLoading(false)
        setGotchis(gotchiCache);
        getUserGotchiItems(itemCache, gotchiItemsStatCache, setRefreshing)
    }

    const getUserGotchiItems = async (itemCache: any[], gotchiItemsStatCache: any, setRefreshing: any) => {
        let result = await callContract({ desired: 'items', userAddress: userAddress })
        if (result.status != 'failure') {
            result.data.forEach((newItem: any) => {
                const id: number = newItem.itemId.toNumber()
                gotchiItemsStatCache.total += newItem.balance.toNumber();
                const index = itemCache.findIndex((item: any) => item.id === id)

                if (index < 0) {
                    itemCache.push({
                        name: newItem.itemType.name,
                        id: id,
                        stored: newItem.balance.toNumber(),
                        equipped: 0,
                        rarity: items[id].rarity,
                        rarityScoreModifier: newItem.itemType.rarityScoreModifier,
                        stats: newItem.itemType.traitModifiers
                    })
                } else {
                    itemCache[index].stored = newItem.balance.toNumber()
                }
            })
        }

        itemCache.forEach((item: any) => {
            switch (item.rarity) {
                case 'Common':
                    gotchiItemsStatCache.common += item.stored + item.equipped
                    break;
                case 'Uncommon':
                    gotchiItemsStatCache.uncommon += item.stored + item.equipped
                    break;
                case 'Rare':
                    gotchiItemsStatCache.rare += item.stored + item.equipped
                    break;
                case 'Legendary':
                    gotchiItemsStatCache.legendary += item.stored + item.equipped
                    break;
                case 'Mythical':
                    gotchiItemsStatCache.mythical += item.stored + item.equipped
                    break;
                case 'Godlike':
                    gotchiItemsStatCache.godlike += item.stored + item.equipped
                    break;
            }
        })

        if (setRefreshing) {
            let anyChanges = false;
            itemsOwned.forEach((item: any) => {
                let cItemIndex = itemCache.findIndex((cItem: any) => cItem.id === item.id)
                if (cItemIndex < 0) {
                    anyChanges = true;
                    floorPrices['items'].total -= floorPrices['items'][item.id] * (item.equipped + item.stored)
                    delete floorPrices['items'][item.id]
                } else {
                    let totalCache = floorPrices['items'].total
                    totalCache += floorPrices['items'][item.id] * ((itemCache[cItemIndex].equipped - item.equipped) + (itemCache[cItemIndex].stored - item.stored))
                    if (totalCache == floorPrices['items'].total) {
                        anyChanges = false;
                    } else {
                        floorPrices['items'].total = totalCache
                        anyChanges = true;
                    }
                }
            })

            itemCache.forEach((cItem: any) => {
                let oItemIndex = itemsOwned.findIndex((oItem: any) => oItem.id === cItem.id)
                if (oItemIndex < 0) {
                    anyChanges = true;
                }
            })

            if (!anyChanges) {
                setItemsTVLoading(false)
            }
        }

        setGotchiItemStats(gotchiItemsStatCache)
        setItems(itemCache)
        setItemsLoading(false)
        if (setRefreshing) {
            setRefreshing(false)
        }
    }

    const getUserParcels = async (setRefreshing: any) => {
        setParcelsLoading(true)
        setInstallationsLoading(true)
        setInstsTVLoading(true)
        let parcels = await QueryGraph({ desired: 'userParcels', variables: { userAddress: userAddress } })
            .then((res: any) => {
                if (res.status === 'success') {
                    let data = res.data.data
                    if (data['parcels0'] !== null && data['parcels0'] !== undefined) {
                        let parcels = data['parcels0'].parcelsOwned.concat(Object.keys(data).map((key: any, index: any) => {
                            if (index > 0 && data[key].parcelsOwned.length > 0) {
                                return data[key].parcelsOwned
                            }
                        })).filter((parcel: any) => parcel)

                        let arraysAtTheEnd = parcels.filter((parcels: any) => parcels.length)
                        let cleanedParcels = parcels.filter((parcels: any) => !parcels.length)
                        arraysAtTheEnd.forEach((array: any) => Array.prototype.push.apply(cleanedParcels, array))

                        return cleanedParcels
                    } else {
                        return []
                    }
                } else {
                    return []
                }
            })

        const parcelCache: any[] = []
        const installationCache: any[] = []
        const tileCache: any[] = []
        const parcelIds: any[] = []

        const parcelStatCache = {
            humble: 0,
            reasonable: 0,
            spacious: 0,
            boosted: 0,
            total: 0,
            farming: 0,
            channel: 0
        }
        let parcelItemsStatCache = {
            tiles: 0,
            disc: 0,
            cos: 0,
            func: 0,
            total: 0,
            common: 0,
            uncommon: 0,
            rare: 0,
            legendary: 0,
            mythical: 0,
            godlike: 0
        }

        if (parcels.length > 0) {
            let parcelAlchemicas = await getParcelAlchemica({parcels: parcels});
            let parcelAlchemicasClaimed = await getParcelAlchemicaClaimed({parcels: parcels});
            parcels.forEach((parcel: any, index: any) => {
                if (parcel.size == 0) {
                    parcelStatCache.humble += 1
                } else if (parcel.size == 1) {
                    parcelStatCache.reasonable += 1
                } else {
                    parcelStatCache.spacious += 1
                }
                
                let formattedAlchemicas: any = formatInstallationAlchemicaCost(null, { level: 0 }, 0, parcelAlchemicas[index])
                let formattedAlchemicasClaimed: any = formatInstallationAlchemicaCost(null, { level: 0 }, 0, parcelAlchemicasClaimed[index])

                let overallTotal = {
                    'FUD': formattedAlchemicas[0] + formattedAlchemicasClaimed[0],
                    'FOMO': formattedAlchemicas[1] + formattedAlchemicasClaimed[1],
                    'ALPHA': formattedAlchemicas[2] + formattedAlchemicasClaimed[2],
                    'KEK': formattedAlchemicas[3] + formattedAlchemicasClaimed[3],
                }
                if(formattedAlchemicas.length === 0) {
                    formattedAlchemicas = {
                        'FUD': 0,
                        'FOMO': 0,
                        'ALPHA': 0,
                        'KEK': 0,
                    }
                    overallTotal = {
                        'FUD': 0,
                        'FOMO': 0,
                        'ALPHA': 0,
                        'KEK': 0,
                    }
                }

                if(formattedAlchemicasClaimed.length === 0) {
                    formattedAlchemicasClaimed = {
                        'FUD': 0,
                        'FOMO': 0,
                        'ALPHA': 0,
                        'KEK': 0,
                    }
                }

                let alchStats: any = {
                    alchemicaLeft: formattedAlchemicas,
                    alchemicaRemoved: formattedAlchemicasClaimed,
                    overallTotal: overallTotal
                }

                let boostValueUSD = 0;
                if (parcel.fudBoost != 0 || parcel.fomoBoost != 0 || parcel.alphaBoost != 0 || parcel.kekBoost != 0) {
                    parcelStatCache.boosted += 1

                    const alchemicaBoostValues = [
                        { name: 'fudBoost', value: 998 * 1 },
                        { name: 'fomoBoost', value: 499 * 2 },
                        { name: 'alphaBoost', value: 251 * 4 },
                        { name: 'kekBoost', value: 102 * 10 }
                    ]

                    alchemicaBoostValues.forEach((boost: any, index: any) => {
                        boostValueUSD += parcel[boost.name] * boost.value
                    })
                }
                parcelCache.push(Object.assign({}, parcel, { boostValueUSD: boostValueUSD, alchStats: alchStats }))
                parcelIds.push(parcel.id.toString())
            })

            const equippedTiles = await QueryGraph({ desired: 'equippedTiles', variables: { userAddress: userAddress, parcelIds: parcelIds } })
                .then((res: any) => {
                    if (res.status === 'success') {
                        let data = res.data.data
                        if (data['equippedTiles0'] !== null && data['equippedTiles0'] !== undefined) {
                            let eTiles = data['equippedTiles0'].concat(Object.keys(data).map((key: any, index: any) => {
                                if (index > 0 && data[key].length > 0) {
                                    return data[key]
                                }
                            })).filter((eTile: any) => eTile)

                            let arraysAtTheEnd = eTiles.filter((gotchis: any) => gotchis.length)
                            let cleanedETiles = eTiles.filter((gotchis: any) => !gotchis.length)
                            arraysAtTheEnd.forEach((array: any) => Array.prototype.push.apply(cleanedETiles, array))
                            return cleanedETiles
                        } else {
                            return []
                        }
                    } else {
                        return []
                    }
                })

            const equippedInsts = await QueryGraph({ desired: 'equippedInsts', variables: { userAddress: userAddress, parcelIds: parcelIds } })
                .then((res: any) => {
                    if (res.status === 'success') {
                        let data = res.data.data
                        if (data['equippedInsts0'] !== null) {
                            let eInsts = data['equippedInsts0'].concat(Object.keys(data).map((key: any, index: any) => {
                                if (index > 0 && data[key].length > 0) {
                                    return data[key]
                                }
                            })).filter((eInst: any) => eInst)

                            let arraysAtTheEnd = eInsts.filter((eInst: any) => eInst.length)
                            let cleanedEInsts = eInsts.filter((eInst: any) => !eInst.length)
                            arraysAtTheEnd.forEach((array: any) => Array.prototype.push.apply(cleanedEInsts, array))

                            return cleanedEInsts
                        } else {
                            return []
                        }
                    } else {
                        return []
                    }
                })

            for (let i = 0, n = parcelCache.length; i < n; i += 1) {
                let equippedInstCache: any[] = []
                let parcelInstallations = equippedInsts.filter((eInst: any) => eInst.parcel.id === parcelCache[i].id)

                if (parcelInstallations.length > 0) {
                    parcelInstallations.forEach((eInst: any) => {
                        const instCacheCheck = installationCache.findIndex((inst: any) => inst.id == eInst.type.id)
                        if (instCacheCheck < 0) {
                            installationCache.push({
                                id: parseFloat(eInst.type.id),
                                stored: 0,
                                equipped: 1,
                            })
                        } else {
                            installationCache[instCacheCheck].equipped += 1;
                        }

                        equippedInstCache.push({
                            id: eInst.type.id,
                            typeId: `i${eInst.type.id}`,
                            type: 'installation',
                            quantity: 1
                        })
                    })
                    parcelCache[i]['lastChanneled'] = parcelInstallations[0].parcel.lastChanneledAlchemica
                    parcelCache[i]['lastClaimedAlchemica'] = parcelInstallations[0].parcel.lastClaimedAlchemica
                }

                let parcelTiles = equippedTiles.filter((eTile: any) => eTile.parcel.id == parcelCache[i].id)

                if (parcelTiles.length > 0) {
                    parcelTiles.forEach((eTile: any) => {
                        parcelItemsStatCache.tiles += 1
                        const tileCacheCheck = tileCache.findIndex((t: any) => t.id == eTile.type.id)
                        const equippedCacheCheck = equippedInstCache.findIndex((tile: any) => tile.id == eTile.type.id && tile.type === 'tile')
                        if (tileCacheCheck < 0) {
                            tileCache.push({
                                id: parseFloat(eTile.type.id),
                                stored: 0,
                                type: 'tile',
                                typeId: `t${eTile.type.id}`,
                                name: eTile.type.name,
                                alchemicaCost: formatInstallationAlchemicaCost(null, 'tile', eTile.type.id, eTile.type.alchemicaCost),
                                disc: eTile.type.deprecated,
                                equipped: 1,
                                amount: eTile.type.amount
                            })
                        } else {
                            tileCache[tileCacheCheck].equipped += 1;
                        }

                        if (equippedCacheCheck < 0) {
                            equippedInstCache.push({
                                id: eTile.type.id,
                                typeId: `t${eTile.type.id}`,
                                type: 'tile',
                                quantity: 1
                            })
                        } else {
                            equippedInstCache[equippedCacheCheck].quantity += 1;
                        }
                    })
                }

                parcelCache[i]['equippedInstallations'] = equippedInstCache
            }
            parcelStatCache.total = parcels.length
        }
        getUserParcelItems(parcelCache, installationCache, parcelItemsStatCache, parcelStatCache, setRefreshing, tileCache)
    }

    async function getUserParcelItems(parcelCache: any[], installationCache: any[], parcelItemsStatCache: any, parcelStatCache: any, setRefreshing: any, tileCache: any[]) {
        const balanceResult = await callContract({ desired: 'installations', userAddress: userAddress });
        if (balanceResult.status === 'success') {
            balanceResult.data.forEach((installation: any) => {
                const index = installationCache.findIndex((inst: any) => inst.id == installation.itemId.toNumber())
                if (index < 0) {
                    installationCache.push({
                        id: installation.itemId.toNumber(),
                        stored: installation.balance.toNumber(),
                        equipped: 0,
                    })
                } else {
                    installationCache[index].stored += installation.balance.toNumber()
                }
            })
        }
        let harvestDailyTotals: any[] = [
            { ticker: 'FUD', total: 0, colour: '#298356' },
            { ticker: 'FOMO', total: 0, colour: '#c1421c' },
            { ticker: 'ALPHA', total: 0, colour: '#3cccff' },
            { ticker: 'KEK', total: 0, colour: '#8f17f9' }
        ]

        if (installationCache.length > 0) {
            const highestIdPlusNextLevel: number = installationCache.sort((a: any, b: any) => a.id - b.id).at(installationCache.length - 1).id + 1;
            const ids: any[] = []
            for (let i = 0; i <= highestIdPlusNextLevel; i += 1) {
                ids.push(i)
            }
            let typeResult = await QueryGraph({ desired: 'instTypes', variables: { instIds: ids }})

            let typesObject: any;

            if (typeResult.status == 'success') {
                let array = typeResult.data.data.installationTypes
                typesObject = array.reduce((obj: any, item: any) => { obj[item.id] = item; return obj } ,{});

                installationCache.forEach((inst: any) => {
                    const type = typesObject[inst.id]
                    let typeCache;

                    if (type.level == 1) {
                        inst['disc'] = type.deprecated;
                        if (type.nextLevelId == 0) {
                            typeCache = 'cosmetic'
                        } else {
                            typeCache = 'functional'
                        }
                    } else if (type.level > 1) {
                        typeCache = 'functional'
                        inst['disc'] = typesObject[inst.id - (type.level - 1)].deprecated
                    }

                    inst['type'] = typeCache;
                    inst['typeId'] = `i${inst.id}`;
                    inst['rarity'] = installationNameToRarity(type.name);
                    inst['name'] = inst['rarity'] == 'None' ? (
                        type.name.replace(` Level ${type.level}`, '')
                    ) : (
                        type.name.replace(`${inst['rarity']} `, '')
                    );

                    inst['level'] = type.level;
                    inst['nextLvlId'] = type.nextLevelId;
                    inst['alchemicaCost'] = formatInstallationAlchemicaCost(typesObject, type, inst.id, type.alchemicaCost);
                    inst['spillRadius'] = type.spillRadius;
                    inst['spillRate'] = type.spillRate;
                    inst['harvestRate'] = utils.formatEther(type.harvestRate);
                    inst['capacity'] = utils.formatEther(type.capacity);
                    inst['upgradeQueueBoost'] = type.upgradeQueueBoost;
                    inst['alchemicaType'] = type.name.includes('Harvester') || type.name.includes('Reservoir') ? alchemicaToTicker[type.alchemicaType] : '';
                    let nextLvlType = type.nextLevelId !== 0 ? typesObject[type.nextLevelId] : undefined
                    inst['nextLvlStats'] = nextLvlType ? (
                        {
                            spillRate: nextLvlType.spillRate,
                            spillRadius: nextLvlType.spillRadius,
                            harvestRate: utils.formatEther(nextLvlType.harvestRate),
                            capacity: utils.formatEther(nextLvlType.capacity),
                            upgradeQueueBoost: nextLvlType.upgradeQueueBoost,
                            alchemicaCost: formatInstallationAlchemicaCost(null, {level: 0}, 0, nextLvlType.alchemicaCost)
                        }
                    ) : (
                        {}
                    )
                    parcelItemsStatCache.total += inst.stored + inst.equipped;

                    if (type.deprecated) {
                        parcelItemsStatCache.disc += inst.stored + inst.equipped
                    }


                    if (inst.rarity == 'None') {
                        parcelItemsStatCache.func += inst.stored + inst.equipped;
                    } else {
                        parcelItemsStatCache.cos += inst.stored + inst.equipped;
                    }

                    switch (inst.rarity) {
                        case 'Common':
                            parcelItemsStatCache.common += inst.stored + inst.equipped;
                            break;
                        case 'Uncommon':
                            parcelItemsStatCache.uncommon += inst.stored + inst.equipped;
                            break;
                        case 'Rare':
                            parcelItemsStatCache.rare += inst.stored + inst.equipped;
                            break;
                        case 'Legendary':
                            parcelItemsStatCache.legendary += inst.stored + inst.equipped;
                            break;
                        case 'Mythical':
                            parcelItemsStatCache.mythical += inst.stored + inst.equipped;
                            break;
                        case 'Godlike':
                            parcelItemsStatCache.godlike += inst.stored + inst.equipped;
                            break;
                    }
                })
            }

            let tempTokenData: any;
            if (tokenData[0] != undefined) {
                tempTokenData = tokenData
            } else {
                tempTokenData = await fetch(serverURL + "/aavegotchi-token-prices")
                    .then((res: any) => {
                        return res.json()
                    }).catch((error: any) => {
                        console.log(error + ' (tokenData from server)');
                    })
            }

            parcelCache.forEach((parcel: any) => {
                const alch = ['FUD', 'FOMO', 'ALPHA', 'KEK']
                const harvestDataCache: any = {}
                let harvestTotalInGHST: number = 0;

                let parcelHarvesters: any[] = []
                let parcelReservoirs: any[] = []

                alch.forEach((alch: string, index: number) => {
                    parcelHarvesters = parcel.equippedInstallations.filter((inst: any) => typesObject[inst.id].name.includes(alch + ' Harvester')).sort((a: any, b: any) => a.level - b.level)
                    parcelReservoirs = parcel.equippedInstallations.filter((inst: any) => typesObject[inst.id].name.includes(alch + ' Reservoir')).sort((a: any, b: any) => a.level - b.level)

                    if (parcelReservoirs.length > 0 && parcelHarvesters.length > 0) {
                        let alchHarvest: number = 0; 
                        let alchCapacity: number = 0;
                        let fullRateAfterSpill: number = 0;
                        let dailyRateAfterSpill: number = 0;

                        parcelHarvesters.forEach((h: any) => {
                            alchHarvest += parseFloat(utils.formatEther(typesObject[h.id].harvestRate))
                        })

                        parcelReservoirs.forEach((r: any) => {
                            let capacity = parseFloat(utils.formatEther(typesObject[r.id].capacity))
                            alchCapacity += capacity;
                            fullRateAfterSpill += capacity - ((typesObject[r.id].spillRate / 10000) * capacity)
                        })

                        let twentyFourHoursInMins = 1440
                        let freqMulti = alchCapacity / alchHarvest;
                        let minsBetweenEmpty = Math.floor(Math.abs(twentyFourHoursInMins * freqMulti));
                        let emptyTimesADay = twentyFourHoursInMins / minsBetweenEmpty
                        dailyRateAfterSpill = fullRateAfterSpill * (emptyTimesADay > 3 ? 3 : emptyTimesADay)
                        let minuteRemainder = minsBetweenEmpty % 60;
                        let hours: any = Math.floor(minsBetweenEmpty / 60);
                        if (hours.toString().length < 3) {
                            hours = ('0' + hours).slice(-2)
                        }

                        harvestDataCache[alch] = {
                            dailyHarvestRate: alchHarvest,
                            fullRateAfterSpill: parseFloat(fullRateAfterSpill.toFixed(2)),
                            dailyRateAfterSpill: parseFloat(dailyRateAfterSpill.toFixed(2)),
                            emptyFreq: { hours: hours, minutes: ('0' + minuteRemainder).slice(-2), totalMinutes: minsBetweenEmpty},
                            totalCapacity: alchCapacity,
                            emptyTimesADay: emptyTimesADay > 3 ? 3 : parseFloat(emptyTimesADay.toFixed(2))
                        }

                        harvestDailyTotals[index].total += dailyRateAfterSpill
                        harvestTotalInGHST += (dailyRateAfterSpill * tempTokenData[index].prices.gbp) / tempTokenData[4].prices.gbp
                    } else {
                        harvestDataCache[alch] = {
                            dailyHarvestRate: 0,
                            fullRateAfterSpill: 0,
                            dailyRateAfterSpill: 0,
                            emptyFreq: { hours: 0, minutes: 0, totalMinutes: 0 },

                            totalCapacity: 0,
                            emptyTimesADay: 0
                        }
                    }
                })
                if (harvestTotalInGHST != 0) {
                    parcelStatCache.farming += 1
                }

                parcel['harvestTotalInGHST'] = harvestTotalInGHST
                parcel['harvestData'] = harvestDataCache
                parcel['harvesters'] = parcelHarvesters
                parcel['reservoirs'] = parcelReservoirs
                parcel['instQuantity'] = parcel.equippedInstallations.length

                let parcelAaltar = parcel.equippedInstallations.find((inst: any) => typesObject[inst.id].name.includes('Aaltar'))
                if (parcelAaltar) {
                    parcelAaltar = parcelAaltar.id
                    parcel['aaltarLevel'] = typesObject[parcelAaltar].level
                } else {
                    parcelAaltar = null
                    parcel['aaltarLevel'] = 0
                }
            })
        }

        setDailyHarvestTotals(harvestDailyTotals)

        const result = await callContract({ desired: 'tiles', userAddress: userAddress });
        if (result.status === 'success') {
            result.data.forEach((tile: any) => {
                let tileId = tile[1].toNumber()
                let tileWalletBalance = tile[0].toNumber()
                parcelItemsStatCache.tiles += tileWalletBalance
                const tileCacheCheck = tileCache.findIndex((tile: any) => tile.id === tileId)
                if (tileCacheCheck < 0) {
                    tileCache.push({
                        id: tileId,
                        stored: tileWalletBalance,
                        type: 'tile',
                        typeId: `t${tileId}`,
                        name: tile[2][6],
                        height: tile[2][0],
                        width: tile[2][1],
                        alchemicaCost: formatInstallationAlchemicaCost(null, 'tile', tileId, tile[2][5]),
                        disc: tile[2][2],
                        equipped: 0,
                    })
                } else {
                    tileCache[tileCacheCheck].stored += tileWalletBalance;
                }
            })
        }

        let instsAndTiles = installationCache.concat(tileCache)

        if (setRefreshing) {
            let anyChanges = false;
            installationsOwned.forEach((oInst: any) => {
                let cInstIndex = instsAndTiles.findIndex((cInst: any) => oInst.typeId === cInst.typeId)
                let oInstID = oInst.type == 'tile' ? oInst.typeId : oInst.id
                let totalValue = (craftCosts[oInst.typeId] ? craftCosts[oInst.typeId] : 0) + (floorPrices['installations'][oInstID] ? floorPrices['installations'][oInstID] : 0)
                if (cInstIndex < 0) {
                    anyChanges = true;
                    floorPrices['installations'].total -= totalValue * (oInst.equipped + oInst.stored)
                    delete floorPrices['installations'][oInstID]
                    delete craftCosts[oInst.typeId]
                } else {
                    let totalCache = floorPrices['installations'].total
                    totalCache += totalValue * ((instsAndTiles[cInstIndex].equipped - oInst.equipped) + (instsAndTiles[cInstIndex].stored - oInst.stored))
                    if (totalCache == floorPrices['installations'].total) {
                        anyChanges = false;
                    } else {
                        floorPrices['installations'].total = totalCache
                        anyChanges = true;
                    }
                }
            })

            instsAndTiles.forEach((cInst: any) => {
                let oInstIndex = installationsOwned.findIndex((oInst: any) => oInst.typeId === cInst.typeId)
                if (oInstIndex < 0) {
                    anyChanges = true;
                }
            })

            if (!anyChanges) {
                setInstsTVLoading(false)
            }
        }

        setParcelItemStats(parcelItemsStatCache)
        setInstallations(instsAndTiles)
        setInstallationsLoading(false);
        handleParcelChannel(parcelCache, installationCache, parcelStatCache, setRefreshing, false)
    }

    const handleParcelChannel = async (parcels: any[], installations: any[], stats: any, setRefreshing: any, autoRefresh: boolean) => {
        let parcelCache = parcels
        if (autoRefresh) {
            const parcelData = await QueryGraph({ desired: 'parcelData', variables: { userAddress: userAddress } });

            if (parcelData.status == 'success') {
                const data = parcelData.data.data
                data.parcels.forEach((parcel: any) => {
                    let parcelCacheIndex = parcelCache.findIndex((p: any) => p.id === parcel.id)
                    parcelCache[parcelCacheIndex]['lastChanneled'] = parcel.lastChanneledAlchemica
                })
            }
        }

        parcelCache.forEach((parcel: any) => {
            let lastChanneledDate = parcel.lastChanneled * 1000;
            let aaltarIndex = parcel.equippedInstallations.findIndex((inst: any) => inst.id <= 19)
            let now = new Date().getTime();
            if (aaltarIndex >= 0) {
                let aaltarIndex2 = installations.findIndex((inst: any) =>
                    inst.id == parcel.equippedInstallations[aaltarIndex].id && inst.type != 'tile')
                let inst: any = installations[aaltarIndex2];

                if ((now - lastChanneledDate) / (1000 * 60) < altarLvlToCooldown[inst.level]) {
                    let totalMinutesLeft = altarLvlToCooldown[inst.level] - ((now - lastChanneledDate) / 1000 / 60);
                    let minuteRemainder = totalMinutesLeft % 60;
                    let hours = Math.floor(totalMinutesLeft / 60);

                    if (parcel.canChannel) {
                        stats -= 1;
                    }

                    parcel['canChannel'] = false;
                    parcel['channelStatus'] = { hours: hours, minuteRemainder: minuteRemainder, totalMinutesLeft: totalMinutesLeft }
                } else {
                    stats.channel += 1;
                    parcel['canChannel'] = true;
                    parcel['channelStatus'] = { totalMinutesLeft: 0 }
                }
            } else {
                parcel['canChannel'] = false;
                parcel['channelStatus'] = { aaltarless: true }
            }
        })

        setParcelStats(stats)
        setParcels(parcelCache);
        setParcelsLoading(false)
        setHandleParcelChannelLoading(false)
        if (setRefreshing) {
            setRefreshing(false)
        }
    }

    const floorPriceAppend = (type: any, id: any, price: any) => {
        if (floorPrices[type] == undefined) {
            floorPrices[type] = { [id]: price }
        } else if (floorPrices[type][id] == undefined) {
            floorPrices[type][id] = price;
        }
    }

    const floorPriceTotalValue = (type: any, price: any, setSectionTotalLoading: any, dataArray: any) => {
        if (!floorPrices[type]) {
            floorPrices[type] = { ['total']: price }
        } else if (!floorPrices[type]['total']) {
            floorPrices[type]['total'] = price;
        } else {
            floorPrices[type]['total'] += price;
        }

        if (type === 'installations' && (Object.keys(floorPrices[type]).length - 1) + Object.keys(floorPrices['tiles']).length + Object.keys(craftCosts).length === dataArray.length) {
            setSectionTotalLoading(false)
        } else if (type !== 'tokens' && Object.keys(floorPrices[type]).length - 1 === dataArray.length) {
            setSectionTotalLoading(false)
        }
    }

    const handleItemFloorPrice = (id: number, floorPrice: number) => {
        floorPriceAppend('items', id, floorPrice);
        if (Object.keys(gotchiPocketValues).length == 0 && Object.keys(floorPrices['items']).length >= itemsOwned.length) {
            setGotchiPocketsLoading(true)

            gotchisOwned.forEach((gotchi: any) => {
                let valueCache = 0;
                if (!gotchi.naked) {
                    for (let i = 0; i <= 6; i += 1) {
                        if (gotchi.equippedWearables[i] != 0 && floorPrices['items'][gotchi.equippedWearables[i]] != undefined) {
                            valueCache += floorPrices['items'][gotchi.equippedWearables[i]]
                        }
                    }
                }
                gotchiPocketValues[gotchi.id] = valueCache

                if (Object.keys(gotchiPocketValues).length == gotchiStats['equipped']) {
                    setGotchiPocketsLoading(false);
                }
            })
        }
    }

    const autoRefetch = () => {
        getBlock()
        getERC20Data()
    }

    const getClientData = async () => {
        let userCurrency = window.localStorage.getItem('userCurrency')
        if (userCurrency != null) {
            setCurrency(userCurrency)
        }
        getERC20Data()
        getBlock()
        getUserGotchis(null)
        getUserParcels(null)
        let i = setInterval(autoRefetch, 60000);
        return () => clearInterval(i)
    }

    return (
        <UserContext.Provider value={{
            getClientData,
            setCurrency,
            userCurrency,
            setBlock,
            block,
            setAddress,
            userAddress,
            setUserMedia,
            userMedia,
            setFloorPrices,
            floorPrices,
            setTokenData,
            tokenData,
            setERC20Loading,
            erc20Loading,
            setGotchis,
            gotchisOwned,
            setParcels,
            parcelsOwned,
            setItems,
            itemsOwned,
            setItemsLoading,
            itemsLoading,
            floorPriceAppend,
            setGotchiPocketValues,
            gotchiPocketValues,
            setGotchiPocketsLoading,
            gotchiPocketsLoading,
            setGotchisLoading,
            gotchisLoading,
            setParcelsLoading,
            parcelsLoading,
            setHandleParcelChannelLoading,
            handleParcelChannelLoading,
            handleItemFloorPrice,
            setInstallations,
            installationsOwned,
            setInstallationTypes,
            installationTypes,
            setInstallationsLoading,
            installationsLoading,
            floorPriceTotalValue,
            setCraftCosts,
            craftCosts,
            tokenBalances,
            setTokenBalances,
            getUserGotchis,
            getUserParcels,
            setGotchiStats,
            gotchiStats,
            setParcelStats,
            parcelStats,
            setParcelData,
            parcelData,
            setGotchiItemStats,
            gotchiItemStats,
            setParcelItemStats,
            parcelItemStats,
            setDailyHarvestTotals,
            dailyHarvestTotals,
            setItemsTVLoading,
            itemsTVLoading,
            instsTVLoading,
            setInstsTVLoading
        }}>
            {props.children}
        </UserContext.Provider>
    )
}
