import { VStack, Wrap, useMediaQuery, Flex, Spinner, Icon, Text, WrapItem, HStack, Image, Tooltip } from "@chakra-ui/react";
import { UserContext } from "../../contexts/UserContext";
import { useContext, useRef, useState } from "react";
import FilterByStat from "../Common/FilterByStat";
import SortBar from "../Common/SortBar";
import { AavegotchiVerse } from "../../utils/EcosystemIcons";
import { fadeIn } from "../../styles/Animations";
import ParcelCard from "./ParcelCard";
import SectionHeader from "../Common/SectionHeader";
import AssetLib from "../../assets/images/AssetLib";
import millify from "millify";

const PARCEL_SORT_OPTIONS = [
  { name: 'DISTRICT', sort: 'district' },
  { name: 'ID', sort: 'id' },
  { name: 'BOOST', sort: 'boostValueUSD' },
  { name: 'INSTS', sort: 'instQuantity' },
  { name: 'AALTAR', sort: 'aaltarLevel' },
  { name: 'D-RATE', sort: 'harvestTotalInGHST'}
]

const styles = {
  a: {
    background: 'none',
    color: '#FA34F3'
  }
};

export default function ParcelCards() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { parcelsOwned, parcelsLoading, dailyHarvestTotals, tokenData, userCurrency, installationsOwned } = useContext(UserContext);
  const [filterState, setParcelFilter] = useState('t');
  const [sortParcelsBy, setSortParcelsBy] = useState('district');
  const [parcelSortDir, setParcelSortDir] = useState('asc');
  const viewPortRef = useRef(null)

  const PARCEL_FILTERS: { [key: string]: any } = {
    't': (parcel: any) => parcel.id,
    'Humble': (parcel: any) => parcel.size == 0,
    'Reasonable': (parcel: any) => parcel.size == 1,
    'Spacious': (parcel: any) => parcel.size == 2 || parcel.size == 3,
    'boosted': (parcel: any) => parcel.fudBoost != 0 || parcel.fomoBoost != 0 || parcel.alphaBoost != 0 || parcel.kekBoost != 0,
    'channel': (parcel: any) => parcel.canChannel,
    'farming': (parcel: any) => parcel.harvestTotalInGHST != 0,
  }

  const FILTER_NAMES = Object.keys(PARCEL_FILTERS)

  const propComparator = (toSort: any, sortDir: any) => {
      return (a: any, b: any) => sortDir === 'asc' ? a[toSort] - b[toSort] : b[toSort] - a[toSort];
  }

  return (
    parcelsLoading ? (
      <Flex
        animation={fadeIn}
        w={isMobile ? ('90vw') : ('full')}
        h='full'
        justify='center'
        align='center'
        position='relative'>
        <Spinner
          h='50px'
          w='50px'
          thickness='3px'
          color='mainUi.warmDark'
          position='absolute' />
        <Icon
          as={AavegotchiVerse}
          h='24px'
          w='24px'
          fill='mainUi.warm' />
      </Flex>
    ) : (
      parcelsOwned.length > 0 ? (
        <VStack
          animation={fadeIn}
          spacing=''
          w={isMobile ? '98vw' : 'full'}
          h='full'
          bg=''>
          <SectionHeader title='Parcels' />
          <HStack
            spacing=''
            justify='center'
            align='center'
            w='full'
            h='fit-content'
            borderBottom='1px'
            borderColor='mainUi.darker'
            bgGradient='linear(to-r, mainUi.warmDark 4%, mainUi.dark 80%, mainUi.darker 50%, mainUi.dark 80%, mainUi.warmDark 96%)'>
          <SortBar
            options={PARCEL_SORT_OPTIONS}
            setSortBy={setSortParcelsBy}
            setSortDir={setParcelSortDir}
            sortByPressed={sortParcelsBy}
            sortDirPressed={parcelSortDir} />
          <FilterByStat
            filterList={FILTER_NAMES}
            isPressed={filterState}
            setFilter={setParcelFilter}
            filteringFor='parcels' />
            </HStack>
          <Wrap
            ref={viewPortRef}
            justify='center'
            overflowY='scroll'
            paddingTop='10px'
            h='full'
            w='full'
            overflowX='hidden'
            bg='mainUi.dark'
            sx={{
              '&::-webkit-scrollbar': {
                borderRadius: '1px',
                width: '10px',
                backgroundColor: 'mainUi.dark',
              },
              // '&::-webkit-scrollbar-track': {
              //   marginRight: '10px',
              // },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'mainUi.warmDark',
              },
            }}>
                      {/* <WrapItem
            animation={fadeIn}
            key={'Parcel:'}
            w='fit-content'
            h='fit-content'
            bg=''
            padding='5px'>
              <Flex
                    role='group'
                    bg='mainUi.warm'
                    border='1px'
                    rounded='sm'
                    justify='center'
                    align='center'
                    borderColor='mainUi.darker'
                    _hover={{ borderColor: 'mainUi.ggMagenta' }}
                    h='392px'
                    w='250px'
                    padding=''>
                    <Spinner
                        h='50px'
                        w='50px'
                        thickness='3px'
                        color='mainUi.ggMagenta' />
                </Flex>
                </WrapItem> */}
            {parcelsOwned.filter(PARCEL_FILTERS[filterState])
              .sort(propComparator(sortParcelsBy, parcelSortDir))
              .map((parcel: any, index: any) => {
                return (
                  <ParcelCard
                    key={parcel.id + ': ' + parcel.name}
                    viewPortRef={viewPortRef}
                    id={parcel.id}
                    size={parcel.size}
                    tokenId={parcel.tokenId}
                    parcelId={parcel.parcelId}
                    parcelHash={parcel.parcelHash}
                    district={parcel.district}
                    fudBoost={parcel.fudBoost}
                    fomoBoost={parcel.fomoBoost}
                    alphaBoost={parcel.alphaBoost}
                    kekBoost={parcel.kekBoost}
                    boostValueUSD={parcel.boostValueUSD}
                    harvestData={parcel.harvestData}
                    channelStatus={parcel.channelStatus}
                    canChannel={parcel.canChannel}
                    lastChanneled={parcel.lastChanneled}
                    equippedInstallations={parcel.equippedInstallations}
                    lastClaimedAlchemica={parcel.lastClaimedAlchemica}
                    alchemicaLeft={parcel.alchemicaLeft}
                    alchemicaRemoved={parcel.alchemicaRemoved}
                    overallTotalAlchemica={parcel.overallTotalAlchemica}
                    alchStats={parcel.alchStats}
                    // harvestTotalInGHST={parcel.harvestTotalInGHST} 
                    />
                )
              }
              )
            }
          </Wrap>
          <Tooltip variant='dash' label='Max harvest a day after spillover at current market value, assuming reservoirs are emptied prematurely or on time.'>
          <HStack w='full' bg='mainUi.warmDark' h='fit-content' align='center' justify='center' padding='4px' border='1px' borderColor='mainUi.ggMagenta'>
            <Text
              w='fit-content'
              key='24hrChange'
              variant='identifier'
              color='mainUi.warm'>
              HARVEST 24HR
            </Text>
            <Wrap h='fit-content' w='fit-content' bg='' gap='4px' spacing='5px' align='center' justify='center'>
              {dailyHarvestTotals.map((alch: any) => {
                return (
                  <WrapItem key={alch.ticker} h='fit-content' w='fit-content' bg=''>
                    <HStack
                      w='fit-content'
                      h='fit-content'
                      spacing='2px'
                      justify='center'
                      align='center'>
                      <Image
                        h='10px'
                        w='10px'
                        sx={{ filter: 'drop-shadow(0px 0px 1px rgb(0 0 0 / 1))' }}
                        src={AssetLib.tickerToImage(alch.ticker)} />
                      <HStack align='center' justify='center' spacing=''>
                        <Text
                          key='24hrChange'
                          variant='identifier'
                          color={alch.colour}>
                          {millify(alch.total, { precision: 2, lowercase: true })} / {millify(tokenData.find((token: any) => token.ticker == alch.ticker).prices[userCurrency] * alch.total, { precision: 2, lowercase: true })} {userCurrency.toUpperCase()}
                        </Text>
                      </HStack>
                    </HStack>
                  </WrapItem>
                )
              })}
            </Wrap>
          </HStack>
          </Tooltip>
        </VStack>
      ) : (
        <VStack animation={fadeIn} w='full' bg='' h='full' align='center' justify='center' spacing='20px'>
          <Icon h='100px' w='100px' fill='mainUi.ggMagenta' as={AavegotchiVerse} />
          <Text
            maxW='200px'
            fontWeight='bold'
            fontSize='sm'
            align='center'
            textColor='mainUi.warm'
            sx={{ lineHeight: '16px' }}>
            you don't own any parcels...
            <a style={styles.a} href="https://app.aavegotchi.com/baazaar/realm?sort=priceLow&size=all&district=all" target="_blank"> visit the baazaar </a>
            to purchase one and
            <a style={styles.a} href="https://app.aavegotchi.com/baazaar/realm?sort=priceLow&size=all&district=all" target="_blank"> become a laandlord!</a>
          </Text>
        </VStack>
      )
    )
  )
};