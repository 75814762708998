import { Button, VStack, Text, HStack, Flex, Spinner, Spacer, useMediaQuery } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { UserContext } from '../../contexts/UserContext';
import Refresh from '../Common/Refresh';
import SectionHeader from '../Common/SectionHeader';

export default function SitePanel(props: any) {
    const [isHeightTwo] = useMediaQuery("(max-height: 820px)");

    return (
        <VStack h='full' w='full' spacing=''>
            <SectionHeader title='Site Stuff' />
            <HStack
                w='full'
                h='full'
                flexDir='row'
                align='flex-start'
                padding='4px'>
                <VStack
                    w='90px'
                    h='full'
                    spacing='4px'
                    align='center'
                    justify='center'
                    pr='4px'
                    pl='4px'>
                    {!isHeightTwo ? (
                        <Text variant='identifier'>
                            REFRESH
                        </Text>
                    ) : (
                        null
                    )}
                    <Refresh stuff='Gotchi Stuff' />
                    <Refresh stuff='Parcel Stuff' />
                </VStack>
            </HStack>
        </VStack>
    );
}