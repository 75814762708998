import { VStack, Wrap, useMediaQuery, Flex, Spinner, Icon, Text, HStack, Spacer, Image } from "@chakra-ui/react";
import { UserContext } from "../../contexts/UserContext";
import { useContext, useReducer, useRef, useState } from "react";
import FilterByStat from "../Common/FilterByStat";
import SortBar from "../Common/SortBar";
import ItemCard from "./ItemCard";
import { AavegotchiItems } from "../../utils/EcosystemIcons";
import { fadeIn } from "../../styles/Animations";
import millify from "millify";
import AssetLib from "../../assets/images/AssetLib";
import { FaEquals } from "react-icons/fa";
import SectionHeader from "../Common/SectionHeader";

const ITEM_FILTERS: { [key: string]: any } = {
  't': (item: any) => item,
  'common': (item: any) => item.rarity === 'Common',
  'uncommon': (item: any) => item.rarity === 'Uncommon',
  'rare': (item: any) => item.rarity == 'Rare',
  'legendary': (item: any) => item.rarity === 'Legendary',
  'mythical': (item: any) => item.rarity === 'Mythical',
  'godlike': (item: any) => item.rarity === 'Godlike'
}

const FILTER_NAMES = Object.keys(ITEM_FILTERS)

const ITEM_SORT_OPTIONS = [
  { name: 'QTY', sort: 'quantity' },
  { name: 'ID', sort: 'id' },
  { name: 'TOTAL', sort: 'total' },
  { name: 'FLOOR', sort: 'floor' }
]

const styles = {
  a: {
    background: 'none',
    color: '#FA34F3'
  }
};



export default function ItemCards() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { itemsOwned, floorPrices, itemsLoading } = useContext(UserContext);
  const [filterState, setItemFilter] = useState('t');
  const [sortItemsBy, setSortItemsBy] = useState('id');
  const [itemSortDir, setItemSortDir] = useState('asc');
  const viewPortRef = useRef(null)

  const initialState = { count: 0 }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [ totalLoading, setTotalLoading ] = useState(true)

  function reducer(state: any, action: any) {
      switch (action.type) {
        case 'increment':
          return {count: state.count + 1};
        default:
          throw new Error();
      }
    }

  const propComparator = (toSort: any, sortDir: any) => {
    switch (toSort) {
      case 'floor':
        return (a: any, b: any) => sortDir === 'asc' ?
          floorPrices['items'][a.id] - floorPrices['items'][b.id] :
          floorPrices['items'][b.id] - floorPrices['items'][a.id];
      case 'total':
        return (a: any, b: any) => sortDir === 'asc' ?
          (floorPrices['items'][a.id] * (a.stored + a.equipped)) - (floorPrices['items'][b.id] * (b.stored + b.equipped)) :
          (floorPrices['items'][b.id] * (b.stored + b.equipped)) - (floorPrices['items'][a.id] * (a.stored + a.equipped));
      case 'quantity':
        return (a: any, b: any) => sortDir === 'asc' ?
          (a.stored + a.equipped) - (b.stored + b.equipped) :
          (b.stored + b.equipped) - (a.stored + a.equipped);
      case 'id':
        return (a: any, b: any) => sortDir === 'asc' ?
          a[toSort] - b[toSort] :
          b[toSort] - a[toSort];
    }
  }

  return (
    itemsLoading ? (
      <Flex
        animation={fadeIn}
        w='full'
        h='full'
        justify='center'
        align='center'
        position='relative'>
        <Spinner
          h='50px'
          w='50px'
          thickness='3px'
          color='mainUi.warmDark'
          position='absolute' />
        <Icon
          as={AavegotchiItems}
          h='24px'
          w='24px'
          fill='mainUi.warm' />
      </Flex>
    ) : (
      itemsOwned.length > 0 ? (
        <VStack
          animation={fadeIn}
          spacing=''
          w={isMobile ? '98vw' : 'full'}
          h='full'
          bg=''>
            <SectionHeader title='Gotchi Items'/>
            <HStack
            spacing=''
            justify='center'
            align='center'
            w='full'
            h='fit-content'
            borderBottom='1px'
            borderColor='mainUi.darker'
            bgGradient='linear(to-r, mainUi.warmDark 4%, mainUi.dark 80%, mainUi.darker 50%, mainUi.dark 80%, mainUi.warmDark 96%)'>
              <SortBar
                options={ITEM_SORT_OPTIONS}
                setSortBy={setSortItemsBy}
                setSortDir={setItemSortDir}
                sortByPressed={sortItemsBy}
                sortDirPressed={itemSortDir} />
              <FilterByStat
                filteringFor='items'
                filterList={FILTER_NAMES}
                isPressed={filterState}
                setFilter={setItemFilter} />
            </HStack>
          <Wrap
            ref={viewPortRef}
            justify='center'
            overflowY='scroll'
            paddingTop='10px'
            h='full'
            w='full'
            overflowX='hidden'
            bg='mainUi.dark'
            sx={{
              '&::-webkit-scrollbar': {
                borderRadius: '1px',
                width: '10px',
                backgroundColor: 'mainUi.dark',
              },
              '&::-webkit-scrollbar-track': {
                marginRight: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'mainUi.warmDark',
              },
            }}>
            {itemsOwned?.filter(ITEM_FILTERS[filterState])
              .sort(propComparator(sortItemsBy, itemSortDir)).map((item: any) => {
                return (
                  <ItemCard
                    key={'Wearable:' + item.id}
                    viewPortRef={viewPortRef}
                    id={item.id}
                    name={item.name}
                    stored={item.stored}
                    equipped={item.equipped}
                    rarity={item.rarity}
                    dispatch={dispatch}
                    rarityScoreModifier={item.rarityScoreModifier} />
                )
              }
              )
            }
          </Wrap>
        </VStack>
      ) : (
        <VStack animation={fadeIn} w='full' bg='' h='full' align='center' justify='center' spacing='20px'>
          <Icon h='100px' w='100px' fill='mainUi.ggMagenta' as={AavegotchiItems} />
          <Text
            maxW='200px'
            fontWeight='bold'
            fontSize='sm'
            align='center'
            textColor='mainUi.warm'
            sx={{ lineHeight: '16px' }}>
            you don't own any gotchi items...
            <a style={styles.a} href="https://app.aavegotchi.com/baazaar/equipment?sort=priceLow&filter=all&slot=all" target="_blank"> visit the baazaar </a>
            to purchase some and
            <a style={styles.a} href="https://app.aavegotchi.com/baazaar/equipment?sort=priceLow&filter=all&slot=all" target="_blank"> dress your gotchis!</a>
          </Text>
        </VStack>
      )
    )

  )
};