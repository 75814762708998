import { Image, Flex, Spinner, Icon } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { IoScanSharp } from 'react-icons/io5';
import { UserContext } from '../../contexts/UserContext';
import { fadeIn } from '../../styles/Animations';
import { parcelToColour } from '../../utils/EcosystemHelpers';

export default function PaarcelMap(props: any) {
    const { id, size } = props;
    const componentMounted = useRef(true)
    const { userMedia } = useContext(UserContext)
    const [ loading, setLoading ] = useState(true);

    const tickerSomething = async () => {
        const response = await fetch(`https://api.gotchiverse.io/realm/map/load?map=citaadel&format=image&tokenId=${id},${100}}`)
            .then((res: any) => {
                return res.blob();
            }).then((blob: any) => {
                return URL.createObjectURL(blob);
            }).catch((err: any) => {
                console.log(err + ' (paarcelMap from Gotchiverse API)')
            })
        
        if(response) {
            userMedia['parcels'][id] = response;
            if (componentMounted.current) {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (!userMedia['parcels'][id]) {
            tickerSomething()
        } else {
            setLoading(false)
        }
        return () => {
            componentMounted.current = false;
        }
    }, [])
    
    return (
        <Flex
            justify='center'
            align='center'
            w='200px'
            pl='10px'
            pr='10px'
            pb='10px'
            h='190px'>
            {!loading ? (
                <Flex
                    w='180px'
                    align='center'
                    justify='center'
                    position='relative'>
                    <Image
                        animation={fadeIn}
                        // sx={{ filter: 'drop-shadow(0px 0px 12px rgb(0 0 0 / 0.4))' }}
                        h='fit-content'
                        w='full'
                        src={userMedia['parcels'][id]} />
                    <Icon
                        animation={fadeIn}
                        as={IoScanSharp}
                        zIndex='1'
                        w='20px'
                        h='20px'
                        position='absolute'
                        color='mainUi.ggMagenta' />
                </Flex>
            ) : (
                <Spinner
                    h='30px'
                    w='30px'
                    thickness='3px'
                    color={parcelToColour[size].dark} />
            )}
        </Flex>
    )
}