import { Flex, HStack, VStack, Text, Spacer, Spinner, WrapItem, Icon, Tooltip } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { utils } from 'ethers';
import millify from 'millify';
import { useContext, useEffect, useRef, useState } from 'react';
import { FaTimes, FaEquals } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import QueryGraph from '../../api/QueryGraph';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';
import { fadeIn } from '../../styles/Animations';
import { rarityToColour } from '../../utils/EcosystemHelpers';
import ItemSvg from './ItemSvg';

export default function ItemCard(props: any) {
    const { floorPrices, handleItemFloorPrice, floorPriceTotalValue, itemsOwned, setItemsTVLoading } = useContext(UserContext)
    const [priceLoading, setPriceLoading] = useState(true)
    const {
        id,
        name,
        stored,
        equipped,
        rarity,
        viewPortRef,
        dispatch,
        valueState,
        ...rest
    } = props

    const options = {
        root: viewPortRef.current,
        rootMargin: '100px',
        threshold: 0,
        pollInterval: 7200000
    }
    const { ref, inView } = useInView(options);
    const componentMounted = useRef(true)

    const getFloorPrice = async (id: number) => {
        const potions = [126, 127, 128, 129]
        const index = potions.findIndex((potion: any) => potion === id)
        let desired = rarity == 'Godlike' ? 'lastSold' : 'floorPrice'

        let category = 0;
        if (index >= 0) {
            category = 2
        }
        

        const result = await QueryGraph({ desired: desired, variables: { id: id, category: category } })
        let listing = result.data.data.erc1155Listings[0]

        if (result.status == 'success' && listing != undefined) {
            handleItemFloorPrice(id, parseFloat(utils.formatEther(listing.priceInWei)))
            floorPriceTotalValue('items', parseFloat(utils.formatEther(listing.priceInWei)) * (equipped + stored), setItemsTVLoading, itemsOwned)
        } else {
            handleItemFloorPrice(id, 1)
            floorPriceTotalValue('items', 1 * (equipped + stored), setItemsTVLoading, itemsOwned)
            console.log(`no last sold price for wearable #${id} - ${name}`)
        }

        if (componentMounted.current) {
            setPriceLoading(false)
        }
    }

    useEffect(() => {
        if (!floorPrices['items'][id]) {
            getFloorPrice(id);
        } else {
            setPriceLoading(false)
        }

        

        return () => {
            componentMounted.current = false;
        }
    }, [])

    return (
        <WrapItem
            key={'Wearable:' + id}
            ref={ref}
            w='fit-content'
            h='fit-content'
            padding='5px'>
            {inView ? (
                <VStack
                    role='group'
                    bg={rarityToColour[rarity].bright}
                    border='1px'
                    rounded='sm'
                    align='center'
                    borderColor='mainUi.darker'
                    _hover={{
                        borderColor: 'mainUi.ggMagenta'
                    }}
                    h='fit-content'
                    w='110px'
                    spacing=''>
                    <VStack
                        position='relative'
                        w='100px'
                        h='129px'
                        spacing=''
                        align='center'
                        animation={fadeIn}
                        justify='center'>
                        <ItemSvg id={id} rarity={rarity} />
                        <Text
                            pt='4px'
                            zIndex='1'
                            h='fit-content'
                            w='full'
                            align='center'
                            fontWeight='black'
                            fontSize='x-small'
                            textColor={rarityToColour[rarity].dark}
                            _groupHover={{
                                textColor: 'mainUi.ggMagenta',
                            }}
                            sx={{
                                lineHeight: '9px'
                            }}>
                            {name.replace(`${rarity}`, '')}
                        </Text>
                        <Spacer />
                        <Text
                            zIndex='1'
                            textColor={rarityToColour[rarity].dark}
                            variant='identifier'
                            textAlign='center'>
                            #{id}
                        </Text>
                    </VStack>
                    <HStack
                        animation={fadeIn}
                        w='full'
                        spacing='3px'
                        bg='mainUi.darker'
                        paddingTop='4px'
                        paddingBottom='4px'
                        align='center'
                        justify='center'>
                        <HStack
                            spacing='1px'
                            align='center'
                            justify='center'>
                            <Image
                                h='9px'
                                w='9px'
                                src={AssetLib.tickerToImage('GHST')} />
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                <Tooltip label={rarity == 'Godlike' ? (
                                    floorPrices['items'][id] == 1 ? (
                                        'no accurate price'
                                        ) : (
                                            'last sold'
                                            )) : (
                                                'floor'
                                                )} variant='dash'>
                                <Text
                                    animation={fadeIn}
                                    textColor={rarityToColour[rarity].dark}
                                    variant='identifier'
                                    textAlign='center'
                                    color='mainUi.warm'>
                                    {millify(floorPrices['items'][id])}
                                </Text>
                                </Tooltip>
                            )}
                        </HStack>
                        <Icon
                            w='9px'
                            h='9px'
                            color='mainUi.ggMagenta'
                            as={FaTimes} />
                        <Tooltip label='quantity' variant='dash'>
                        <Text
                            align='center'
                            variant='identifier'
                            color='mainUi.warm'>
                            {equipped + stored}
                        </Text>
                        </Tooltip>
                        <Icon
                            w='9px'
                            h='9px'
                            color='mainUi.ggMagenta'
                            as={FaEquals} />
                        <HStack
                            spacing='1px'
                            align='center'
                            justify='center'>
                            <Image
                                h='9px'
                                w='9px'
                                src={AssetLib.tickerToImage('GHST')} />
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                <Tooltip label='total' variant='dash'>
                                <Text
                                    animation={fadeIn}
                                    textColor={rarityToColour[rarity].dark}
                                    variant='identifier'
                                    textAlign='center'
                                    color='mainUi.warm'>
                                    {millify(floorPrices['items'][id] * (stored + equipped))}
                                </Text>
                                </Tooltip>
                            )}
                        </HStack>
                    </HStack>
                </VStack>
            ) : (
                <Flex
                    role='group'
                    animation={fadeIn}
                    bg={rarityToColour[rarity].bright}
                    border='1px'
                    rounded='sm'
                    align='center'
                    borderColor='mainUi.darker'
                    _hover={{
                        borderColor: 'mainUi.ggMagenta'
                    }}
                    h='154px'
                    w='110px'
                    justify='center'>
                    <Spinner
                        h='20px'
                        w='20px'
                        color={rarityToColour[rarity].dark}
                        thickness='3px' />
                </Flex>
            )}
        </WrapItem>
    );
}