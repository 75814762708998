// Layout.tsx
import { useContext, useEffect, useRef, useState } from "react";
import { Flex, Spacer, VStack, Button, Text, useCheckbox, Tag, Box, useMediaQuery, Link, Tooltip, HStack, Input, Image } from "@chakra-ui/react";
import Header from "../components/Header";
import { UserContext } from '../contexts/UserContext';
import Dash from "./Dash";
import Donate from "../components/Donate";
import Github from "../components/Github";
import AssetLib from "../assets/images/AssetLib";

declare var window: any

const customAddressCheck = (address: string) => {
  return address.length === 42 && address.charAt(0) === '0' && address.charAt(1).toLowerCase() === 'x' && address.toLowerCase() !== '0xdd564df884fd4e217c9ee6f65b4ba6e5641eac63'
}

export default function Layout() {
  const { setAddress, userAddress, getClientData } = useContext(UserContext);
  const [enteredDash, setEntered] = useState(false)
  const [error, setError] = useState(false)
  const [customAddress, setCustomAddress] = useState("")
  const walletAddress = useRef("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  async function requestAccount() {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
        .then((res: any) => { return res })
        .catch((err: any) => {
          setError(true)
          console.log(`Error requesting wallet address: ${err}`)
        });

      if (!error) {
        walletAddress.current = accounts[0];
        setAddress(accounts[0])
      }
    } else {
      alert('MetaMask, where?...')
    }
  }

  const handleEnterDash = () => {
    if(userAddress.length === 42) {
      setEntered(true)
      getClientData()
    } else {
      requestAccount()
    }
  }

  // const handleVaultSelected = (e: any) => {
  //   if (e) {
  //     setAddress('0xdd564df884fd4e217c9ee6f65b4ba6e5641eac63')
  //   } else {
  //     setAddress(walletAddress.current)
  //   }
  // }

  const handleCustomWalletAddress = (e: any) => {
    setCustomAddress(e)
    if (customAddressCheck(e)) {
      setAddress(e.toLowerCase())
    } else {
      setAddress(walletAddress.current)
    }
  }

  useEffect(() => {
    requestAccount()
  }, [])

  const CustomCheckbox = (props: any) => {
    const { state, getInputProps, getCheckboxProps, getLabelProps, htmlProps } = useCheckbox(props);
    return (
      <Tag
        as='label'
        display='flex'
        flexDirection='row'
        alignItems='center'
        gridColumnGap={2}
        w='fit-content'
        rounded='lg'
        px={3}
        py={1}
        bg=''
        cursor='pointer'
        textColor='mainUi.warm'
        {...htmlProps}>
        <input {...getInputProps()} hidden />
        <Flex
          align='center'
          justify='center'
          justifyContent='center'
          border='2px solid'
          borderColor='mainUi.warm'
          w={4}
          h={4}
          {...getCheckboxProps()}>
          {state.isChecked && <Box w={2} h={2} bg='mainUi.ggMagenta' />}
        </Flex>
        <Text {...getLabelProps()} variant='identifier' fontSize='14px' textColor='mainUi.warm'>use the vault wallet.</Text>
      </Tag>
    )
  }

  return (
    <VStack
      bgGradient='linear(to-b, mainUi.darker 0%, mainUi.dark 90%, mainUi.warmDark)'
      flexDirection='column'
      spacing=''
      h='100vh'
      w='100vw'
      align='center'
      justify='start'>
      <Header />
      <Spacer/>
      {enteredDash ? (
        <Dash />
      ) : (
        <Flex
          justify='center'
          w='full'
          h='80vh'
          align='center'
          pb='200px'>
            <VStack
              w='full'
              align='center'
              justify='center'
              spacing='50px'>
                <VStack maxW='400px' spacing='10px'>
                {/* <Text variant='identifier' fontSize='14px' textColor='mainUi.warm'>current site quirks</Text> */}
                <Text variant='identifier' fontSize='12px' fontWeight='medium' textColor='mainUi.ggIndigo' sx={{lineHeight: '20px'}}>
                  site hopefully back online tomorrow (18th August)
                </Text>
                </VStack>
                <VStack spacing={['30px', '30px', '40px', '40px']}>
                {isMobile ? (
        <Image
          h='100px'
          w='fit-content'
          src={AssetLib.nameToLogo('gotchidash')} />) : (null)}
              <Button
                variant='dash'
                fontWeight={['bold', 'extrabold', 'black']}
                fontSize={['36px', '36px', '48px', '60px']}
                onClick={(() => { 
                  if(userAddress == '0x1441bae48b5da3da1d6e48c2c5b033fcbf6ee759') {
                    handleEnterDash()
                  } 
                })}
                >
                - offline -
              </Button>
              {/* <Tooltip variant='dash' label='currently limited to 5000 Gotchis + Paarcels'>
              <Flex>
                {isMobile ? null : <CustomCheckbox
                isChecked={userAddress === '0xdd564df884fd4e217c9ee6f65b4ba6e5641eac63'}
                onChange={(e: any) => handleVaultSelected(e.target.checked)} />}
                </Flex>
              </Tooltip> */}
              <Input
              value={customAddress}
              bg='mainUi.warmDark'
              border='1px'
              borderColor={customAddressCheck(customAddress) ? 'Green' : customAddress == '' ? 'mainUi.ggMagenta' : 'Red'}
              textColor='mainUi.warm'
              onChange={(e: any) => handleCustomWalletAddress(e.target.value)}
              placeholder='0x... custom matic address'
              _placeholder={{ opacity: 1, color: 'mainUi.darker' }}
              focusBorderColor=''
              _focus={{
                        border: '2px'}}
              size='sm'
              />
              </VStack>
              <Text maxW='400px' variant='identifier' color='mainUi.ggMagenta' fontSize='12px' textColor='mainUi.warm'>Gotchidash is an agnostic navigation hub for all things Aavegotchi.
              I am developing this app with the goal of saving Aavegotchi ecosystem users (you) time... whilst providing comfort & relief when handling a diverse collection of assets in an often overwhelming project.
              <br/>
              <br/>
              If Gotchidash succeeds in these goals, for you, please consider donating to support past and future development of the app.


</Text>
            </VStack>
        </Flex>
      )}
      {isMobile ? <HStack w='full' h='3vh' align='center' justify='center'><Donate /></HStack> : null}
    </VStack>
  )
}