import { utils } from "ethers"

export const parcelToColour: { [key: number]: { bright: string, dark: string } } = {
    0: { bright: '#5b40cf', dark: '#2500C2' },
    1: { bright: '#29a888', dark: '#006F53' },
    2: { bright: '#6b2698', dark: '#340055' },
    3: { bright: '#6b2698', dark: '#340055' },
}

export const parcelSizeToNumber: { [key: string]: number } = {
    'Humble': 0,
    'Reasonable': 1,
    'Spacious': 2
}

export const alchemicaToColour: { [key: string]: string } = {
    'FUD': '#298356',
    'FOMO': '#c1421c',
    'ALPHA': '#3cccff',
    'KEK': '#8f17f9'
}

export const alchemicaToTicker: { [key: number]: string } = {
    0: 'FUD',
    1: 'FOMO',
    2: 'ALPHA',
    3: 'KEK'
}

export const rarityToColour: { [key: string]: { bright: string, dark: string } } = {
    'Common': { bright: '#8064ff', dark: '#5d24bf' },
    'Uncommon': { bright: '#33bacc', dark: '#36818e' },
    'Rare': { bright: '#59bcff', dark: '#0064ff' },
    'Legendary': { bright: '#ffc36b', dark: '#ea8c27' },
    'Mythical': { bright: '#ff96ff', dark: '#ff00ff' },
    'Godlike': { bright: '#51ffa8', dark: '#1e661e' }
}

export const altarLvlToCooldown: { [key: number]: number } = {
    1: (24 * 60),
    2: (18 * 60),
    3: (12 * 60),
    4: (8 * 60),
    5: (6 * 60),
    6: (4 * 60),
    7: (3 * 60),
    8: (2 * 60),
    9: (1 * 60)
}

export const categoryToRarity: { [key: number]: string } = {
    0: 'Common',
    1: 'Uncommon',
    2: 'Rare',
    3: 'Legendary',
    4: 'Mythical',
    5: 'Godlike'
}

export const formatInstallationAlchemicaCost = (instTypes: any, currentType: any, id: any, alchemicaCosts: any[]) => {
    let indexToAlch: any = {
        0: 'FUD',
        1: 'FOMO',
        2: 'ALPHA',
        3: 'KEK'
    }

    let alchCostCache: any[] = [
        { 'FUD': 0 },
        { 'FOMO': 0 },
        { 'ALPHA': 0 },
        { 'KEK': 0 }
    ]

    if (currentType.level > 1 && instTypes != null) {
        let idCache = id
        let reduceCount = currentType.level - 1
        for (let i = reduceCount; i > 0; i -= 1) {
            idCache -= 1
            instTypes[idCache].alchemicaCost.map((alch: any, index: number) => {
                alchCostCache[index][indexToAlch[index]] += parseFloat(utils.formatEther(alch))
            })
        }
    }
    //first go through a for loop using current currentType.level - 1
    //inside: map through 
    let zeroCount = 0;
    alchemicaCosts.forEach((alch: any, index: number) => {
        let cost = utils.formatEther(alch)
        if (cost == '0.0' || undefined) {
            zeroCount += 1
        }
        alchCostCache[index][indexToAlch[index]] += parseFloat(cost)
    })

    if (zeroCount != 4) {
        return alchCostCache
    } else if (zeroCount >= 3) {
        return (
            [
                { 'FUD': 0 },
                { 'FOMO': 0 },
                { 'ALPHA': 0 },
                { 'KEK': 0 }
            ])
    } else {
        return []
    }

}

export const installationNameToRarity = (name: string) => {
    let rarityCache = 'None';
    let rarities = ['Common', 'Uncommon', 'Rare', 'Legendary', 'Mythical', 'Godlike']

    rarities.forEach((rarity: string) => {
        if (name.includes(rarity)) {
            rarityCache = rarity
        }
    })

    return (
        rarityCache
    )
}

export const convertMsToTime = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
    }

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds,
    )}`;
}

export const removeRarityFromName = (name: string) => {
    let rarities = ['Common', 'Uncommon', 'Rare', 'Legendary', 'Mythical', 'Godlike']
    let rarityCache: any;

    rarities.forEach((rarity: string) => {
        if (name.replace(`${rarity} `, '')) {
            rarityCache = rarity
        }
    })

    return (
        rarityCache
    )
}

export const collateralObjects: any = {
    '0xe0b22e0037b130a9f56bbb537684e6fa18192341': {
        name: "maDAI",
        primaryColor: "#FF7D00",
        secondaryColor: "#ffb165",
    },

    '0x20d3922b4a1a8560e1ac99fba4fade0c849e2142': {
        name: "maWETH",
        primaryColor: "#64438E",
        secondaryColor: "#a084c5",
    },

    '0x823cd4264c1b951c9209ad0deaea9988fe8429bf': {
        name: "maAAVE",
        primaryColor: "#B6509E",
        secondaryColor: "#d396c4",
    },

    '0x98ea609569bd25119707451ef982b90e3eb719cd': {
        name: "maLINK",
        primaryColor: "#0000B9",
        secondaryColor: "#3c3cff",
    },

    '0xdae5f1590db13e3b40423b5b5c5fbf175515910b': {
        name: "maUSDT",
        primaryColor: "#26a17b",
        secondaryColor: "#68dbb7",
    },

    '0x9719d867a500ef117cc201206b8ab51e794d3f82': {
        name: "maUSDC",
        primaryColor: "#2664BA",
        secondaryColor: "#70a0e1",
    },

    '0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9': {
        name: "maTUSD",
        primaryColor: "#2664BA",
        secondaryColor: "#70a0e1",
    },

    '0x8c8bdbe9cee455732525086264a4bf9cf821c498': {
        name: "maUNI",
        primaryColor: "#FF2A7A",
        secondaryColor: "#ff7faf",
    },

    '0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613': {
        name: "maYFI",
        primaryColor: "#0074F9",
        secondaryColor: "#62abff",
    },

    //H2
    '0x27f8d03b3a2196956ed754badc28d73be8830a6e': {
        name: "amDAI",
        primaryColor: "#FF7D00",
        secondaryColor: "#ffb165",
    },

    '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390': {
        name: "amWETH",
        primaryColor: "#000000",
        secondaryColor: "#666666",
    },

    '0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360': {
        name: "amAAVE",
        primaryColor: "#B6509E",
        secondaryColor: "#d396c4",
    },

    '0x60d55f02a771d515e077c9c2403a1ef324885cec': {
        name: "amUSDT",
        primaryColor: "#26a17b",
        secondaryColor: "#68dbb7",
    },

    '0x1a13f4ca1d028320a707d99520abfefca3998b7f': {
        name: "amUSDC",
        primaryColor: "#2664BA",
        secondaryColor: "#70a0e1",
    },

    '0x5c2ed810328349100a66b82b78a1791b101c9d61': {
        name: "amWBTC",
        primaryColor: "#FF5E00",
        secondaryColor: "#ff9e65",
    },

    '0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4': {
        name: "amWMATIC",
        primaryColor: "#824EE2",
        secondaryColor: "#b394ed",
    },
}

export const blocksToAge = (blocks: number) => {
    if (blocks < 1000000) {
        return (
            { BRSBoost: 0, tier: 'Newborn' }
        )
    } else if (blocks >= 1000000 && blocks < 2000000) {
        return (
            { BRSBoost: 1, tier: 'Newborn' }
        )
    } else if (blocks >= 2000000 && blocks < 3000000) {
        return (
            { BRSBoost: 2, tier: 'Youngin' }
        )
    } else if (blocks >= 3000000 && blocks < 5000000) {
        return (
            { BRSBoost: 3, tier: 'Youngin' }
        )
    } else if (blocks >= 5000000 && blocks < 8000000) {
        return (
            { BRSBoost: 4, tier: 'Zoomer' }
        )
    } else if (blocks >= 8000000 && blocks < 13000000) {
        return (
            { BRSBoost: 5, tier: 'Millennial' }
        )
    } else if (blocks >= 13000000 && blocks < 21000000) {
        return (
            { BRSBoost: 6, tier: 'Boomer' }
        )
    } else if (blocks >= 21000000 && blocks < 34000000) {
        return (
            { BRSBoost: 7, tier: 'TBD' }
        )
    } else if (blocks >= 34000000 && blocks < 55000000) {
        return (
            { BRSBoost: 8, tier: 'TBD' }
        )
    } else if (blocks >= 55000000 && blocks < 89000000) {
        return (
            { BRSBoost: 9, tier: 'TBD' }
        )
    } else if (blocks >= 89000000) {
        return (
            { BRSBoost: 10, tier: 'TBD' }
        )
    }
}