import { HStack, Text, Image, VStack } from '@chakra-ui/react';
import AssetLib from '../../assets/images/AssetLib';

export default function GotchiTrait(props: any) {
    const { modifiedTrait } = props
    let traitRarity;

    function traitBetween(min: number, max: number) {
        return modifiedTrait >= min && modifiedTrait <= max;
    }

    if (traitBetween(25, 74)) {
        traitRarity = '#5C25BF'
    } else if (traitBetween(10, 24) || traitBetween(75, 90)) {
        traitRarity = '#358F9D'
    } else if (traitBetween(2, 9) || traitBetween(91, 97)) {
        traitRarity = '#1478FE'
    } else {
        traitRarity = '#FE22FF'
    }

    return (props.side === 'left') ? (
        <HStack
            w='75px'
            h='29px'
            spacing='2px'
            bg={traitRarity}
            align='center'
            justify='center'>
            <Text
                variant='identifier'
                textColor='darker'
                sx={{ lineHeight: '9px' }}
                w='10px'
                h='full'
                paddingLeft='2px'
                align='center'>
                {props.trait.charAt(0).toUpperCase()}
                <br />
                {props.trait.charAt(1).toUpperCase()}
                <br />
                {props.trait.charAt(2).toUpperCase()}
            </Text>
            <HStack
                border='1px'
                borderColor={traitRarity}
                bg='mainUi.darker'
                direction='row'
                padding='3px'
                spacing='2px'
                align='center'
                justify='center'>
                <Image
                    paddingLeft='2px'
                    src={AssetLib.traitToImage(props.trait)}
                    h='20px' />
                <Text
                    h='fit-content'
                    align='center'
                    width='33px'
                    textColor={traitRarity}
                    fontWeight='medium'
                    fontSize='sm'>
                    {props.modifiedTrait}
                </Text>
            </HStack>
        </HStack>
    ) : (
        <HStack
            w='75px'
            spacing='2px'
            bg={traitRarity}
            align='center'
            justify='center'>
            <HStack
                border='1px'
                borderColor={traitRarity}
                bg='mainUi.darker'
                direction='row'
                padding='3px'
                spacing='2px'
                align='center'
                justify='center'>
                <Text
                    h='fit-content'
                    align='center'
                    width='33px'
                    textColor={traitRarity}
                    fontWeight='medium'
                    fontSize='sm'>
                    {props.modifiedTrait}
                </Text>
                <Image
                    paddingRight='2px'
                    src={AssetLib.traitToImage(props.trait)}
                    h='20px' />
            </HStack>
            <Text
                align='center'
                variant='identifier'
                textColor='darker'
                sx={{ lineHeight: '9px' }}
                w='10px'
                paddingRight='4px'>
                {props.trait.charAt(0).toUpperCase()}
                <br />
                {props.trait.charAt(1).toUpperCase()}
                <br />
                {props.trait.charAt(2).toUpperCase()}
            </Text>
        </HStack>)
}