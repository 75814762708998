import { extendTheme } from '@chakra-ui/react';

const GotchiSvg = {
  // The styles all Cards have in common
  baseStyle: (props: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .gotchi-bg': {
      display: 'none'
    },

    '& .wearable-bg': {
      display: 'none'
    },
    _groupHover: {
      '& .wearable-body,.wearable-face,.wearable-head,.wearable-hand,.wearable-hand-left,.wearable-eyes,.wearable-pet,.gotchi-sleeves': {
        display: 'none'
      },
    },

    '& .gotchi-cheek': {
      fill: '#F696C6'
    },
    '& .gotchi-handsDownClosed': {
      display: props.setHandsIn
    },
    '& .gotchi-handsOpen': {
      display: 'none'
    },
    '& .gotchi-handsUp': {
      display: 'none'
    },
    '& .gotchi-handsDownOpen': {
      display: props.setHandsOut
    },
    '& .gotchi-sleeves': {
      display: props.setHandsOut
    },
    '& .gotchi-sleeves-up': {
      display: 'none'
    },
    '&. hide-wearables': {
      display: 'none'
    }
  }),
  // Two variants: rounded and smooth
  variants: {
    'maDAI': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#FF7D00',
      },
      '& .gotchi-secondary': {
        fill: '#F9D792'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maWETH': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#64438E'
      },
      '& .gotchi-secondary': {
        fill: '#EDD3FD'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maAAVE': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#B6509E'
      },
      '& .gotchi-secondary': {
        fill: '#CFEEF4'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maLINK': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#0000B9'
      },
      '& .gotchi-secondary': {
        fill: '#D4DEF8'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maUSDT': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#26a17b'
      },
      '& .gotchi-secondary': {
        fill: '#aedcce'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maUSDC': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#2664BA'
      },
      '& .gotchi-secondary': {
        fill: '#D4E0F1'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maTUSD': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#2664BA'
      },
      '& .gotchi-secondary': {
        fill: '#D4E0F1'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maUNI': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#FF2A7A'
      },
      '& .gotchi-secondary': {
        fill: '#FFC3DF'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'maYFI': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#0074F9'
      },
      '& .gotchi-secondary': {
        fill: '#C8E1FD'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'amDAI': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#FF7D00'
      },
      '& .gotchi-secondary': {
        fill: '#F9D792'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      },
      '& .gotchi-cheek': {
        fill: '#F4AF24'
      }
    }),
    'amWETH': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#000000'
      },
      '& .gotchi-secondary': {
        fill: '#FBDFEB'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'amAAVE': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#B6509E'
      },
      '& .gotchi-secondary': {
        fill: '#CFEEF4'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'amUSDT': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#26a17b'
      },
      '& .gotchi-secondary': {
        fill: '#aedcce'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'amUSDC': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#2664BA'
      },
      '& .gotchi-secondary': {
        fill: '#D4E0F1'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'amWBTC': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#FF5E00'
      },
      '& .gotchi-secondary': {
        fill: '#FFCAA2'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
    'amWMATIC': (props: any) => ({
      '& .gotchi-primary,.gotchi-primary-mouth': {
        fill: '#824EE2'
      },
      '& .gotchi-secondary': {
        fill: '#E6DCF9'
      },
      '& .gotchi-eyeColor': {
        fill: props.eyeColour
      }
    }),
  },
  // The default variant value
  defaultProps: {
    variant: '',
    eyeColour: '',
    setHandsIn: '',
    setHandsOut: '',
  },
}

export const dashThemes = extendTheme({
  // fonts: {

  // },
  colors: {
    mainUi: {
      warm: "#D57A66",
      dark: "#262322",
      light: "#FCDDF2",
      warmDark: "#2e2726",
      darker: "#131515",
      ggMagenta: "#FA34F3",
      ggIndigo: "#6B25E7"
    },
    mainUiProgressBarLight: {
      '500': "#FA34F3",
    }
  },
  components: {
    GotchiSvg,
    Text: {
      variants: {
        identifier: {
          textColor: 'mainUi.ggMagenta',
          fontWeight: 'bold',
          fontSize: 'x-small',
          textAlign: 'center',
        },
        alternate: {
          textColor: 'mainUi.warm',
          fontWeight: 'semibold',
          fontSize: 'sm',
          textAlign: 'center'
        }
      }
    },
    Tooltip: {
      variants: {
        dash: {
          color: 'mainUi.warm',
          fontWeight: 'bold',
          fontSize: 'x-small',
          bg:'mainUi.warmDark',
          textAlign: 'center',
          rounded: 'none',
          border: '1px',
          borderColor: 'mainUi.ggMagenta',
          role: 'group'
        }
      }
    },
    Button: {
      variants: {
        dash: {
          color: 'mainUi.warm',
          bg: '',
          _hover: {
            color: 'mainUi.ggMagenta',
          },
          _focus: {
            borderColor: '',
            boxShadow: ''
          }
        },
        sort: {
          variant: 'dash',
          size:'xs',
          padding:'4px',
          h:'fit-content',
          rounded:'sm',
          border:'1px',
          borderColor:'mainUi.darker',
          _active: {
            color: 'mainUi.ggMagenta'
          },
          _focus: {
            borderColor: '',
            boxShadow: ''
          }
        }
      }
    }
  }
})