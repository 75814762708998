import { makeUniqueId } from '@apollo/client/utilities';
import { HStack, Icon, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import millify from 'millify';
import { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';

export default function ParcelAlch(props: any) {
    const { side, boost, ticker, colour, id, harvestData, lastClaimedAlchemica, alchStats, alchStatsLoading, index } = props;
    let fullPercent = ((harvestData[ticker].emptyFreq.totalMinutes - (harvestData[ticker].emptyFreq.totalMinutes - ((new Date().getTime() - (lastClaimedAlchemica * 1000)) / 60000))) / harvestData[ticker].emptyFreq.totalMinutes * 100)

    return (
        <VStack
        h='fit-content'
        w='full'
        spacing='2px'
        align='center'
        justify='center'
        bg='mainUi.darker'
        filter={boost > 0 || harvestData[ticker].dailyRateAfterSpill > 0 ? 'none' : 'auto'}
        brightness='60%'
        saturate='10%'>
        <VStack
            h='95px'
            w='full'
            spacing=''
            align='center'
            justify='center'
            border='1px'
            borderColor={colour}>
                <Tooltip variant='dash' label={
                    <VStack spacing='-2px'>
                    <Text variant='identifier'>claimed</Text>
                        <Text variant='identifier' color='mainUi.warm'>
                            {!alchStatsLoading ? millify(alchStats.alchemicaRemoved[index][ticker], {precision: 2}) : 'loading'}
                            </Text>
                </VStack>}>
            <VStack w='full' spacing='-4px' align='center' justify='center' filter={
                harvestData[ticker].dailyRateAfterSpill > 0 ? 'none' : 'auto'}
                brightness='60%'
                saturate='10%'>
                <Text w='full' h='fit-content' fontSize='8px' variant='identifier' color={colour} filter='auto' brightness='80%'
                    saturate='80%'>
                    LEFT
                </Text>
                <Text
                    w='fit-content'
                    variant='identifier'
                    color={colour}>
                    {!alchStatsLoading ? millify(alchStats.alchemicaLeft[index][ticker], {precision: 2}) : 'loading'}
                </Text>
            </VStack>
            </Tooltip>
            <VStack w='full' spacing='-4px' align='center' justify='center' filter={
                harvestData[ticker].dailyRateAfterSpill > 0 ? 'none' : 'auto'}
                brightness='60%'
                saturate='10%'>
                <Text w='full' h='fit-content' fontSize='8px' variant='identifier' color={colour} filter='auto' brightness='80%'
                    saturate='80%'>
                    FULL
                </Text>
                <Text
                    w='fit-content'
                    variant='identifier'
                    color={colour}>
                    {!alchStatsLoading ?
                            fullPercent > 100 ? '100%': `${fullPercent.toFixed(1)}%`
                             : 'loading'}
                </Text>
            </VStack>
            <Tooltip variant='dash' label='daily harvest rate after spillover'>
                <VStack w='full' spacing='-4px' align='center' justify='center' filter={harvestData[ticker].dailyRateAfterSpill > 0 ? 'none' : 'auto'}
                    brightness='60%'
                    saturate='10%'>
                    <Text w='full' h='fit-content' fontSize='8px' variant='identifier' color={colour} filter='auto' brightness='80%'
                        saturate='80%'>
                        D-RATE
                    </Text>
                    <Text
                        w='fit-content'
                        variant='identifier'
                        color={colour}>
                        {harvestData[ticker].dailyRateAfterSpill}
                    </Text>
                </VStack>
            </Tooltip>
            <Tooltip variant='dash' label='placeholder'>
                <VStack w='full' spacing='-4px' align='center' justify='center' filter={
                    harvestData[ticker].dailyRateAfterSpill > 0 ? 'none' : 'auto'}
                    brightness='60%'
                    saturate='10%'>
                    <Text w='full' h='fit-content' fontSize='8px' variant='identifier' color={colour} filter='auto' brightness='80%'
                        saturate='80%'>
                        E-FREQ
                    </Text>
                    <HStack spacing='1px' justify='center' align='center' h='fit-content'>
                        <Text variant='identifier' color={colour} fontSize='8px' h='fit-content'>
                            {harvestData[ticker].emptyFreq.hours}
                        </Text>
                        <Text variant='identifier' fontSize='7px' color={colour} h='fit-content'>
                            H
                        </Text>
                        <Text variant='identifier' color={colour} fontSize='8px' h='fit-content'>
                            {harvestData[ticker].emptyFreq.minutes}
                        </Text>
                        <Text variant='identifier' fontSize='7px' color={colour} h='fit-content'>
                            M
                        </Text>
                    </HStack>
                </VStack>
            </Tooltip>
        </VStack>
        </VStack>
    )
}