import { Button, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import { UserContext } from '../contexts/UserContext';
import { fadeIn } from '../styles/Animations';

export default function CurrencySelect() {
    const { tokenData, userCurrency, setCurrency, erc20Loading } = useContext(UserContext)

    const handleCurrencySelect = (currency: any) => {
        setCurrency(currency)
        window.localStorage.setItem('userCurrency', currency)
    }

    return (
        !erc20Loading ? (
            <Menu>
                <MenuButton animation={fadeIn} as={Button} variant='dash' size='x-small'
                    h='full'>
                    <HStack
                        w='fit-content'
                        align='center'
                        justify='center'>
                        <Icon h='10px' w='10px' as={IoChevronDown} color='mainUi.ggMagenta' />
                        <VStack spacing=''>
                            <Text
                                variant='identifier'
                                fontSize='10px'>
                                currency
                            </Text>
                            <Text
                                variant='identifier'
                                color='mainUi.warm'
                                fontSize='10px'>
                                {userCurrency}
                            </Text>
                        </VStack>
                    </HStack>
                </MenuButton>
                <MenuList
                    animation='none'
                    minWidth='inherit'
                    bg='mainUi.darker'
                    borderColor='mainUi.ggMagenta'
                    h='150px'
                    rounded=''
                    margin=''
                    overflowY='scroll'
                    sx={{
                        '&::-webkit-scrollbar': {
                            borderRadius: '1px',
                            width: '10px',
                            backgroundColor: 'mainUi.dark',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'mainUi.warmDark',
                        },
                    }}>
                    {Object.keys(tokenData[0]?.prices).map((currency: any) => {
                        return currency === userCurrency ? null : (
                            <MenuItem
                                textAlign='center'
                                h='fit-content'
                                w='53px'
                                justifyContent='center'
                                padding='1px'
                                margin=''
                                style={{ margin: 0 }}
                                _hover={{
                                    color: 'mainUi.ggMagenta',
                                    backgroundColor: 'mainUi.dark'
                                }}
                                _focus={{ bg: '' }}
                                onClick={() => { handleCurrencySelect(currency) }}
                                color='mainUi.warm'
                                key={currency}
                                defaultValue={currency}>
                                <Text
                                    variant='identifier'
                                    color='mainUi.warm'>
                                    {currency}
                                </Text>
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>
        ) : (
            null
        )
    )
};