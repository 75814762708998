export const items: any = {
    "1": {
      "name": "Camo Hat",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "2": {
      "name": "Camo Pants",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "3": {
      "name": "MK2 Grenade",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "4": {
      "name": "Snow Camo Hat",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        1,
        1,
        0,
        0,
        0
      ]
    },
    "5": {
      "name": "Snow Camo Pants",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        1,
        1,
        0,
        0,
        0
      ]
    },
    "6": {
      "name": "M67 Grenade",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "7": {
      "name": "Marine Cap",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        2,
        0,
        1,
        0,
        0
      ]
    },
    "8": {
      "name": "Marine Jacket",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        2,
        0,
        1,
        0,
        0
      ]
    },
    "9": {
      "name": "Walkie Talkie",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        0,
        1,
        1,
        0,
        0
      ]
    },
    "10": {
      "name": "Link White Hat",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        2,
        0,
        2,
        0,
        0
      ]
    },
    "11": {
      "name": "Link Mess Dress",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        2,
        0,
        2,
        0,
        0
      ]
    },
    "12": {
      "name": "Link Bubbly",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        0,
        0,
        -2,
        0,
        0
      ]
    },
    "13": {
      "name": "Sergey Beard",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -1,
        1,
        0,
        3,
        0,
        0
      ]
    },
    "14": {
      "name": "Sergey Eyes",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        1,
        4,
        0,
        0
      ]
    },
    "15": {
      "name": "Red Plaid",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        3,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "16": {
      "name": "Blue Plaid",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -4,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "17": {
      "name": "Link Cube",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        0,
        0,
        6,
        0,
        0
      ]
    },
    "18": {
      "name": "Aave Hero Mask",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "19": {
      "name": "Aave Hero Shirt",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "20": {
      "name": "Aave Plush",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "21": {
      "name": "Captain Aave Mask",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "22": {
      "name": "Captain Aave Suit",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "23": {
      "name": "Captain Aave Shield",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        2,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "24": {
      "name": "Thaave Helmet",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        2,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "25": {
      "name": "Thaave Suit",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        2,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "26": {
      "name": "Thaave Hammer",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        3,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "27": {
      "name": "Marc Hair",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "28": {
      "name": "Marc Outfit",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "29": {
      "name": "REKT Sign",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        0,
        0,
        -4,
        0,
        0
      ]
    },
    "30": {
      "name": "Jordan Hair",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -2,
        3,
        0,
        0,
        0
      ]
    },
    "31": {
      "name": "Jordan Suit",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -3,
        0,
        1,
        1,
        0,
        0
      ]
    },
    "32": {
      "name": "Aave Flag",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -2,
        3,
        0,
        0,
        0
      ]
    },
    "33": {
      "name": "Stani Hair",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        -3,
        0,
        3,
        0,
        0
      ]
    },
    "34": {
      "name": "Stani Vest",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        3,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "35": {
      "name": "Aave Boat",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        -6,
        0,
        0,
        0,
        0
      ]
    },
    "36": {
      "name": "ETH Logo Glasses",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "37": {
      "name": "ETH Tshirt",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "38": {
      "name": "32 ETH Coin",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "39": {
      "name": "Foxy Mask",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "40": {
      "name": "Foxy Tail",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -1,
        -1,
        0,
        0,
        0
      ]
    },
    "41": {
      "name": "Trezor Wallet",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -1,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "42": {
      "name": "Eagle Mask",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        0,
        2,
        0,
        0,
        0
      ]
    },
    "43": {
      "name": "Eagle Armor",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        2,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "44": {
      "name": "DAO Egg",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        0,
        0,
        2,
        0,
        0
      ]
    },
    "45": {
      "name": "Ape Mask",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        1,
        0,
        -3,
        0,
        0
      ]
    },
    "46": {
      "name": "Halfrekt Shirt",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        1,
        0,
        -2,
        0,
        0
      ]
    },
    "47": {
      "name": "Waifu Pillow",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        0,
        0,
        -4,
        0,
        0
      ]
    },
    "48": {
      "name": "Xibot Mohawk",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        5,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "49": {
      "name": "Coderdan Shades",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        5,
        0,
        0,
        0,
        0
      ]
    },
    "50": {
      "name": "GldnXross Robe",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        5,
        0,
        0,
        0
      ]
    },
    "51": {
      "name": "Mudgen Diamond",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        0,
        5,
        0,
        0
      ]
    },
    "52": {
      "name": "Galaxy Brain",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        0,
        0,
        6,
        0,
        0
      ]
    },
    "53": {
      "name": "All-Seeing Eyes",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -6,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "54": {
      "name": "Llamacorn Shirt",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -3,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "55": {
      "name": "Aagent Headset",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        1,
        1,
        1,
        0,
        0
      ]
    },
    "56": {
      "name": "Aagent Shirt",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -1,
        1,
        1,
        0,
        0,
        0
      ]
    },
    "57": {
      "name": "Aagent Shades",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -1,
        0,
        2,
        0,
        0,
        0
      ]
    },
    "58": {
      "name": "Aagent Pistol",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        3,
        0,
        0,
        0,
        0
      ]
    },
    "59": {
      "name": "Aagent Fedora Hat",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -2,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "60": {
      "name": "Common Wizard Hat",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "61": {
      "name": "Legendary Wizard Hat",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        0,
        2,
        2,
        0,
        0
      ]
    },
    "62": {
      "name": "Mythical Wizard Hat",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        2,
        3,
        0,
        0
      ]
    },
    "63": {
      "name": "Godlike Wizard Hat",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        0,
        2,
        4,
        0,
        0
      ]
    },
    "64": {
      "name": "Common Wizard Staff",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "65": {
      "name": "Legendary Wizard Staff",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        0,
        0,
        2,
        0,
        0
      ]
    },
    "66": {
      "name": "Wizard Visor",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "67": {
      "name": "Straw Hat",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        -1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "68": {
      "name": "Farmer Jeans",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "69": {
      "name": "Pitchfork",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "70": {
      "name": "Handsaw",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        3,
        0,
        -2,
        0,
        0
      ]
    },
    "71": {
      "name": "Red Santa Hat",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -1,
        -2,
        0,
        0,
        0
      ]
    },
    "72": {
      "name": "Jaay Hairpiece",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        0,
        -5,
        0,
        0
      ]
    },
    "73": {
      "name": "Jaay Glasses",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -2,
        -2,
        0,
        -1,
        0,
        0
      ]
    },
    "74": {
      "name": "Jaay Suit",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -2,
        0,
        0,
        -3,
        0,
        0
      ]
    },
    "75": {
      "name": "OKex Sign",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -5,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "76": {
      "name": "Big GHST Token",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "77": {
      "name": "Bitcoin Beanie",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "78": {
      "name": "Black Jeans",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        1,
        0,
        -1,
        0,
        0
      ]
    },
    "79": {
      "name": "Skateboard",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        0,
        0,
        -2,
        0,
        0
      ]
    },
    "80": {
      "name": "Sushi Bandana",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -1,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "81": {
      "name": "Sushi Coat",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -2,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "82": {
      "name": "Sushi Piece",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        0,
        -4,
        0,
        0,
        0
      ]
    },
    "83": {
      "name": "Sushi Knife",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        3,
        0,
        0,
        0,
        0
      ]
    },
    "84": {
      "name": "Gentleman Hat",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -1,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "85": {
      "name": "Gentleman Coat",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -1,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "86": {
      "name": "Monocle",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -2,
        0,
        3,
        0,
        0
      ]
    },
    "87": {
      "name": "Miner Helmet",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "88": {
      "name": "Miner Jeans",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "89": {
      "name": "Pickaxe",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "90": {
      "name": "Pajama Hat",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        -1,
        0,
        0,
        0
      ]
    },
    "91": {
      "name": "Pajama Shirt",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -1,
        -1,
        0,
        0,
        0
      ]
    },
    "92": {
      "name": "Bedtime Milk",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -2,
        -1,
        0,
        0,
        0
      ]
    },
    "93": {
      "name": "Fluffy Pillow",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        0,
        -4,
        0,
        0,
        0
      ]
    },
    "94": {
      "name": "Sweatband",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        2,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "95": {
      "name": "Track Shorts",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "96": {
      "name": "Water bottle",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        2,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "97": {
      "name": "Pillbox Hat",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        -1,
        -2,
        0,
        0,
        0
      ]
    },
    "98": {
      "name": "Day Dress",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        -1,
        -2,
        0,
        0,
        0
      ]
    },
    "99": {
      "name": "Parasol",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        1,
        -1,
        -3,
        0,
        0,
        0
      ]
    },
    "100": {
      "name": "Clutch",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        -2,
        -2,
        0,
        0,
        0
      ]
    },
    "101": {
      "name": "Witchy Hat",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        1,
        3,
        0,
        0,
        0
      ]
    },
    "102": {
      "name": "Witchy Cloak",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        1,
        3,
        0,
        0,
        0
      ]
    },
    "103": {
      "name": "Witchy Wand",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        2,
        2,
        1,
        0,
        0
      ]
    },
    "104": {
      "name": "Portal Mage Helmet",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        1,
        1,
        0,
        0,
        0
      ]
    },
    "105": {
      "name": "Portal Mage Armor",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        2,
        2,
        0,
        0,
        0
      ]
    },
    "106": {
      "name": "Portal Mage Axe",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        4,
        0,
        0,
        0,
        0
      ]
    },
    "107": {
      "name": "Portal Mage Black Axe",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        6,
        0,
        0,
        0,
        0
      ]
    },
    "108": {
      "name": "Rasta Hat",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -1,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "109": {
      "name": "Rasta Shirt",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -1,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "110": {
      "name": "Jamaican Flag",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -1,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "111": {
      "name": "Hazmat Hood",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        -1,
        2,
        0,
        0,
        0
      ]
    },
    "112": {
      "name": "Hazmat Suit",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        -1,
        1,
        0,
        0,
        0
      ]
    },
    "113": {
      "name": "Uranium Rod",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        6,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "114": {
      "name": "Red Hawaiian Shirt",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -3,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "115": {
      "name": "Blue Hawaiian Shirt",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "116": {
      "name": "Coconut",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -3,
        0,
        0,
        0
      ]
    },
    "117": {
      "name": "Cool shades",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "118": {
      "name": "Water Jug",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        0,
        0,
        2,
        0,
        0
      ]
    },
    "119": {
      "name": "Baby Bottle",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        -1,
        -3,
        0,
        0,
        0
      ]
    },
    "120": {
      "name": "Martini",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -1,
        0,
        0,
        -3,
        0,
        0
      ]
    },
    "121": {
      "name": "Wine",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        0,
        -3,
        0,
        0
      ]
    },
    "122": {
      "name": "Milkshake",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        -5,
        0,
        0,
        0
      ]
    },
    "123": {
      "name": "Apple Juice",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        -2,
        0,
        0,
        0
      ]
    },
    "124": {
      "name": "Beer Helmet",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        0,
        -5,
        0,
        0
      ]
    },
    "125": {
      "name": "Track Suit",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "126": {
      "name": "Kinship Potion",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "127": {
      "name": "Greater Kinship Potion",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "128": {
      "name": "XP Potion",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "129": {
      "name": "Greater XP Potion",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "130": {
      "name": "Fireball",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "131": {
      "name": "Dragon Horns",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        1,
        1,
        0,
        0,
        0
      ]
    },
    "132": {
      "name": "Dragon Wings",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        2,
        1,
        0,
        0,
        0
      ]
    },
    "133": {
      "name": "Pointy Horns",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        2,
        2,
        0,
        0,
        0
      ]
    },
    "134": {
      "name": "L2 Sign",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "135": {
      "name": "Polygon Shirt",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        0,
        2,
        0,
        0
      ]
    },
    "136": {
      "name": "Polygon Cap",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -1,
        0,
        2,
        0,
        0
      ]
    },
    "137": {
      "name": "Vote Sign",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "138": {
      "name": "Snapshot Shirt",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "139": {
      "name": "Snapshot Cap",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "140": {
      "name": "Elf Ears",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        -1,
        0,
        0,
        0
      ]
    },
    "141": {
      "name": "Gemstone Ring",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        -1,
        1,
        0,
        0
      ]
    },
    "142": {
      "name": "Princess Tiara",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -2,
        1,
        0,
        0
      ]
    },
    "143": {
      "name": "Gold Necklace",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "144": {
      "name": "Princess Hair",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        -3,
        2,
        0,
        0
      ]
    },
    "145": {
      "name": "Godli Locks",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        0,
        -4,
        2,
        0,
        0
      ]
    },
    "146": {
      "name": "Imperial Moustache",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        -1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "147": {
      "name": "Tiny Crown",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -1,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "148": {
      "name": "Royal Scepter",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -2,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "149": {
      "name": "Royal Crown",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        0,
        0,
        -2,
        0,
        0
      ]
    },
    "150": {
      "name": "Royal Robes",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -3,
        0,
        0,
        -2,
        0,
        0
      ]
    },
    "151": {
      "name": "Common Rofl",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "152": {
      "name": "Uncommon Rofl",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -1,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "153": {
      "name": "Rare Rofl",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -1,
        0,
        0,
        -2,
        0,
        0
      ]
    },
    "154": {
      "name": "Legendary Rofl",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        0,
        0,
        -2,
        0,
        0
      ]
    },
    "155": {
      "name": "Mythical Rofl",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -2,
        0,
        0,
        -3,
        0,
        0
      ]
    },
    "156": {
      "name": "Godlike Rofl",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -3,
        0,
        0,
        -3,
        0,
        0
      ]
    },
    "157": {
      "name": "Lil Pump Goatee",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "158": {
      "name": "Lil Pump Drank",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "159": {
      "name": "Lil Pump Shades",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        3,
        1,
        0,
        0,
        0
      ]
    },
    "160": {
      "name": "Lil Pump Threads",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        5,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "161": {
      "name": "Lil Pump Dreads",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        4,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "199": {
      "name": "Steampunk Goggles",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        3,
        0,
        0,
        0
      ]
    },
    "200": {
      "name": "Steampunk Trousers",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        2,
        0,
        0,
        0
      ]
    },
    "201": {
      "name": "Mechanical Claw",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        2,
        2,
        0,
        0,
        0
      ]
    },
    "202": {
      "name": "VR Headset",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        2,
        3,
        0,
        0,
        0,
        0
      ]
    },
    "203": {
      "name": "Gamer Jacket",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "204": {
      "name": "Game Controller",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "205": {
      "name": "Gotchi Mug",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "206": {
      "name": "Biker Helmet",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        2,
        0,
        1,
        0,
        0
      ]
    },
    "207": {
      "name": "Biker Jacket",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "208": {
      "name": "Aviators",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        2,
        0,
        0,
        0
      ]
    },
    "209": {
      "name": "Horseshoe Mustache",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        0,
        2,
        0,
        0,
        0
      ]
    },
    "211": {
      "name": "Guy Fawkes Mask",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "212": {
      "name": "1337 Laptop",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -2,
        0,
        0,
        3,
        0,
        0
      ]
    },
    "213": {
      "name": "H4xx0r Shirt",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -4,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "214": {
      "name": "Matrix Eyes",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -3,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "215": {
      "name": "Cyborg Eye",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "216": {
      "name": "Rainbow Vomit",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        -5,
        0,
        0,
        0
      ]
    },
    "217": {
      "name": "Energy Gun",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        2,
        3,
        0,
        0,
        0,
        0
      ]
    },
    "218": {
      "name": "Mohawk",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "219": {
      "name": "Mutton Chops",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        2,
        -1,
        0,
        0
      ]
    },
    "220": {
      "name": "Punk Shirt",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        1,
        3,
        0,
        0,
        0,
        0
      ]
    },
    "221": {
      "name": "Pirate Hat",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "222": {
      "name": "Pirate Coat",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        1,
        -1,
        0,
        0
      ]
    },
    "223": {
      "name": "Hook Hand",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        1,
        -1,
        0,
        0
      ]
    },
    "224": {
      "name": "Pirate Patch",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        2,
        -1,
        0,
        0
      ]
    },
    "225": {
      "name": "Basketball",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "226": {
      "name": "Red Headband",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        2,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "227": {
      "name": "23 Jersey",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        1,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "228": {
      "name": "10 Gallon Hat",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "229": {
      "name": "Lasso",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        1,
        0,
        1,
        0,
        0
      ]
    },
    "230": {
      "name": "Wraangler Jeans",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "231": {
      "name": "Comfy Poncho",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -2,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "232": {
      "name": "Poncho Hoodie",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        -1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "233": {
      "name": "Uncommon Cacti",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        0,
        -1,
        1,
        0,
        0
      ]
    },
    "234": {
      "name": "Shaaman Poncho",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        -5,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "235": {
      "name": "Shaaman Hoodie",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -4,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "236": {
      "name": "Rare Cacti",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -1,
        2,
        0,
        0
      ]
    },
    "237": {
      "name": "Mythical Cacti",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        0,
        -2,
        3,
        0,
        0
      ]
    },
    "238": {
      "name": "Godlike Cacti",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        0,
        -3,
        3,
        0,
        0
      ]
    },
    "239": {
      "name": "Wagie Cap",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "240": {
      "name": "Headphones",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "241": {
      "name": "WGMI Shirt",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "242": {
      "name": "Maan Bun",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "243": {
      "name": "Tinted Shades",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "244": {
      "name": "V-Neck Shirt",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "245": {
      "name": "Gecko Hat",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -3,
        0,
        0,
        0
      ]
    },
    "246": {
      "name": "APY Shades",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "247": {
      "name": "Up Arrow",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "248": {
      "name": "Up Only Shirt",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        1,
        0,
        0,
        1,
        0,
        0
      ]
    },
    "249": {
      "name": "Gecko Eyes",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -2,
        -1,
        0,
        0
      ]
    },
    "250": {
      "name": "CoinGecko Tee",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -2,
        -1,
        0,
        0
      ]
    },
    "251": {
      "name": "Candy Jaar",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        0,
        -1,
        -2,
        0,
        0
      ]
    },
    "252": {
      "name": "Aastronaut Helmet",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "253": {
      "name": "Aastronaut Suit",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "254": {
      "name": "uGOTCHI Token",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        1,
        0,
        0,
        0
      ]
    },
    "255": {
      "name": "Space Helmet",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "256": {
      "name": "Lil Bubble Space Suit",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        2,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "257": {
      "name": "Bitcoin Guitar",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        4,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "258": {
      "name": "Taoist Robe",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -3,
        0,
        0,
        3,
        0,
        0
      ]
    },
    "259": {
      "name": "Bushy Eyebrows",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -3,
        0,
        0,
        3,
        0,
        0
      ]
    },
    "260": {
      "name": "Beard of Wisdom",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        -2,
        0,
        0,
        4,
        0,
        0
      ]
    },
    "261": {
      "name": "Aantenna Bot",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -2,
        0,
        3,
        0,
        0
      ]
    },
    "262": {
      "name": "Radar Eyes",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -2,
        0,
        3,
        0,
        0
      ]
    },
    "263": {
      "name": "Signal Headset",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -2,
        0,
        3,
        0,
        0
      ]
    },
    "292": {
      "name": "Brunette Ponytail",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "293": {
      "name": "Leather Tunic",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        -1,
        0,
        0,
        0,
        0,
        0
      ]
    },
    "294": {
      "name": "Bow and Arrow",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "295": {
      "name": "Forked Beard",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        1,
        0,
        0,
        0,
        0
      ]
    },
    "296": {
      "name": "Doublesided Axe",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        1,
        0,
        -1,
        0,
        0
      ]
    },
    "297": {
      "name": "Animal Skins",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        2,
        0,
        0,
        0,
        0
      ]
    },
    "298": {
      "name": "Horned Helmet",
      "rarityScoreModifier": 1,
      "rarity": "Common",
      "stats": [
        0,
        0,
        0,
        -1,
        0,
        0
      ]
    },
    "299": {
      "name": "Longbow",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        0,
        -1,
        0,
        1,
        0,
        0
      ]
    },
    "300": {
      "name": "Feathered Cap",
      "rarityScoreModifier": 2,
      "rarity": "Uncommon",
      "stats": [
        -1,
        0,
        -1,
        0,
        0,
        0
      ]
    },
    "301": {
      "name": "Alluring Eyes",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -2,
        0,
        1,
        0,
        0
      ]
    },
    "302": {
      "name": "Geisha Headpiece",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -2,
        -1,
        0,
        0,
        0,
        0
      ]
    },
    "303": {
      "name": "Kimono",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        -1,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "304": {
      "name": "Paper Fan",
      "rarityScoreModifier": 5,
      "rarity": "Rare",
      "stats": [
        0,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "305": {
      "name": "Sus Butterfly",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        0,
        4,
        0,
        0,
        0,
        0
      ]
    },
    "306": {
      "name": "Flower Studs",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        0,
        -2,
        0,
        0,
        0
      ]
    },
    "307": {
      "name": "Fairy Wings",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        0,
        -2,
        0,
        0,
        0
      ]
    },
    "308": {
      "name": "Red Hair",
      "rarityScoreModifier": 10,
      "rarity": "Legendary",
      "stats": [
        -2,
        0,
        -2,
        0,
        0,
        0
      ]
    },
    "309": {
      "name": "Citaadel Helm",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        3,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "310": {
      "name": "Plate Armor",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        3,
        -2,
        0,
        0,
        0,
        0
      ]
    },
    "311": {
      "name": "Spirit Sword",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        2,
        -3,
        0,
        0,
        0,
        0
      ]
    },
    "312": {
      "name": "Plate Shield",
      "rarityScoreModifier": 20,
      "rarity": "Mythical",
      "stats": [
        0,
        -5,
        0,
        0,
        0,
        0
      ]
    },
    "313": {
      "name": "Kabuto Helmet",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        3,
        3,
        0,
        0,
        0
      ]
    },
    "314": {
      "name": "Yoroi Armor",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        3,
        3,
        0,
        0,
        0
      ]
    },
    "315": {
      "name": "Haanzo Katana",
      "rarityScoreModifier": 50,
      "rarity": "Godlike",
      "stats": [
        0,
        2,
        4,
        0,
        0,
        0
      ]
    }
  }