import { useContext, useState } from "react";
import { Flex, HStack, VStack, Spacer, Text, Progress, Spinner, Image, WrapItem, Icon, Tooltip } from "@chakra-ui/react";
import GotchiTrait from "./GotchiTrait";
import GotchiSvg from "./GotchiSvg";
import { UserContext } from "../../contexts/UserContext";
import { collateralObjects } from "../../utils/EcosystemHelpers";
import GotchiPocket from "./GotchiPocket";
import AssetLib from "../../assets/images/AssetLib";
import { useInView } from "react-intersection-observer";
import GotchiCollateral from "./GotchiCollateral";
import GotchiAge from "./GotchiAge";
import { fadeIn } from "../../styles/Animations";
import { FaTimes } from "react-icons/fa";

export default function GotchiCard(props: any) {
    const { name, id, lvl, kinship, traits, 
            modifiedTraits, xp, xpToLvl, baseBrs,
            hauntId, collateral, wearables,
            modifiedBrs, viewPortRef, claimedAt,
            naked, lent, svg, withSetsTraits, withSetsBrs,
            ...rest } = props;
    
    const { gotchiPocketValues } = useContext(UserContext)

    const options = { root: viewPortRef.current, rootMargin: '300px', threshold: 0 }
    const { ref, inView } = useInView(options);

    const [ gotchiUndressed, setGotchiUndressed ] = useState(false)

    const expFormula = (lvl: any) => {
        return lvl * lvl / 0.02;
    };

    const diff = expFormula(lvl) - expFormula(lvl - 1);
    const percentageFormula = 100 - Math.floor(xpToLvl * 100 / diff);
    
    return (
        <WrapItem
            ref={ref}
            key={'Gotchi:' + id}
            w='fit-content'
            h='fit-content'
            bg=''
            padding='5px'>
            {inView ? (
                <VStack
                    key='cardMaster'
                    spacing=''
                    w='220px'
                    h='fit-content'
                    border='1px'
                    bgColor={hauntId == 1 ? 'mainUi.ggIndigo' : collateralObjects[collateral].secondaryColor}
                    borderColor='mainUi.darker'
                    _hover={{ borderColor: 'mainUi.ggMagenta' }}>
                    <VStack key='TopHalf'
                        animation={fadeIn}
                        onMouseEnter={() => {setGotchiUndressed(true)}}
                        onMouseLeave={() => {setGotchiUndressed(false)}}
                        role='group'
                        w='full'
                        h='258px'
                        align='center'
                        justify='center'
                        position='relative'
                        spacing=''>
                        {hauntId == 1 ? (
                            <Image
                                zIndex='0'
                                w='full'
                                h='fit-content'
                                position='absolute'
                                src={AssetLib.customH1Background()} />
                        ) : (
                            null)}
                        <VStack
                            key='name/id'
                            zIndex='1'
                            spacing='0px'>
                            <Text
                                isTruncated={true}
                                maxW='200px'
                                variant='alternate'
                                fontWeight='bold'
                                textColor={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                sx={{ lineHeight: '16px' }}>
                                {name ? name : 'UNNAMED'}
                            </Text>
                            <Text
                                textColor={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                variant='identifier'
                                w='inherit'>
                                #{id}
                            </Text>
                        </VStack>
                        <VStack
                            animation={fadeIn}
                            w='full'
                            h='full'
                            paddingTop='40px'
                            flexDir='column'
                            align='center'
                            justify='flex-start'
                            position='absolute'
                            spacing='90px'>
                            <HStack key='brs/kin'
                                zIndex={1}
                                w='full'
                                spacing='92px'
                                justify='center'>
                                <HStack
                                    h='20px'
                                    w='fit-content'
                                    justify='center'
                                    align='center'
                                    spacing='2px'
                                    padding='2px'
                                    pr='5px'
                                    pl='5px'
                                    // bg={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                    rounded='full'>
                                    <Image
                                        h='full'
                                        src={AssetLib.traitToImage('diamond')} />
                                    <Text
                                        textColor={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                        fontWeight='bold'
                                        fontSize='sm'
                                        textAlign='center'
                                        w='inherit'>
                                        {gotchiUndressed ? baseBrs : withSetsBrs}
                                    </Text>
                                </HStack>
                                <HStack
                                    h='20px'
                                    w='fit-content'
                                    justify='center'
                                    align='center'
                                    spacing='2px'
                                    padding='2px'
                                    pr='5px'
                                    pl='5px'
                                    // bg={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                    rounded='full'>
                                    <Image
                                        h='full'
                                        src={AssetLib.traitToImage('kin')} />
                                    {/* {gotchiUndressed ? (
                                    <HStack spacing='' justify='center' align='center' h='20px'>
                                    <Icon
                                    w='10px'
                                    h='10px'
                                    color={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                    as={FaTimes} />
                                        <Text
                                        textColor={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                        fontWeight='bold'
                                        fontSize='sm'
                                        textAlign='center'
                                        w='inherit'>
                                        {Math.sqrt(kinship / 50).toFixed(2)}
                                        </Text>
                                        
                                        </HStack>
                                        ) : ( */}
                                        <Tooltip variant='dash' label={
                                        <HStack spacing='' justify='center' align='center' h='full' w='fit-content'>
                                        <Icon
                                        w='fit-content'
                                        h='full'
                                        color='mainUi.warm'
                                        as={FaTimes} />
                                            <Text
                                            textColor='mainUi.warm'
                                            fontWeight='bold'
                                            fontSize='sm'
                                            textAlign='center'
                                            w='inherit'>
                                            {Math.sqrt(kinship / 50).toFixed(2)}
                                            </Text>
                                            
                                            </HStack>
                                        }>
                                        <Text
                                        textColor={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                        fontWeight='bold'
                                        fontSize='sm'
                                        textAlign='center'
                                        w='inherit'>
                                            {kinship}
                                        </Text>
                                        </Tooltip>
                                </HStack>
                            </HStack>
                            <Spacer />
                            <Flex key='lvlText'
                                zIndex={1}
                                w='full'
                                h='full'
                                align='start'
                                justify='center'>
                                <Text
                                    textColor={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                                    variant='identifier'
                                    textAlign='left'>
                                    LVL {lvl} - {xpToLvl} REQ
                                </Text>
                            </Flex>
                        </VStack>
                        <GotchiSvg
                            key={id}
                            id={id}
                            traits={traits}
                            wearables={wearables}
                            collateral={collateral}
                            hauntId={hauntId}
                            svg={svg} />
                    </VStack>
                    <VStack spacing='' w='220px' animation={fadeIn}>
                    <Progress
                        key='lvlProgress'
                        h='4px'
                        w='218px'
                        value={percentageFormula}
                        bg={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor}
                        colorScheme='mainUiProgressBarLight' />
                        <HStack
                        animation={fadeIn}
                        key='traits'
                        w='218px'
                        align='center'
                        justify='center'
                        spacing='2px'
                        padding='4px'
                        pb='3px'
                        bg='mainUi.darker'>
                        <VStack
                            spacing='4px'>
                            <GotchiTrait
                                key='trait-energy'
                                trait='nrg'
                                side='left'
                                modifiedTrait={withSetsTraits[0]} />
                            <GotchiTrait
                                key='trait-spookiness'
                                trait='spk'
                                side='left'
                                modifiedTrait={withSetsTraits[2]} />
                            <GotchiTrait
                                key='trait-eyeShape'
                                trait='eys'
                                side='left'
                                modifiedTrait={withSetsTraits[4]} />
                        </VStack>
                        <VStack
                            spacing='4px'>
                            <GotchiCollateral
                                collateral={collateral} />
                            <GotchiAge
                                age={claimedAt} />
                            <GotchiPocket
                                pocketValue={gotchiPocketValues[id]} />
                        </VStack>
                        <VStack
                            spacing='4px'>
                            <GotchiTrait
                                key='trait-aggression'
                                trait='agg'
                                side='right'
                                modifiedTrait={withSetsTraits[1]} />
                            <GotchiTrait
                                key='trait-brain'
                                trait='brn'
                                side='right'
                                modifiedTrait={withSetsTraits[3]} />
                            <GotchiTrait
                                key='trait-eyeColour'
                                trait='eyc'
                                side='right'
                                modifiedTrait={withSetsTraits[5]} />
                        </VStack>
                    </HStack>
                    </VStack>
                    
                </VStack>
            ) : (
                <Flex
                    animation={fadeIn}
                    w='220px'
                    h='366px'
                    align='center'
                    justify='center'
                    rounded='sm'
                    border='1px'
                    bg={hauntId == 1 ? 'mainUi.ggIndigo' : collateralObjects[collateral].secondaryColor}
                    borderColor='mainUi.darker'>
                    <Spinner
                        h='50px'
                        w='50px'
                        thickness='3px'
                        color={hauntId == 1 ? 'mainUi.darker' : collateralObjects[collateral].primaryColor} />
                </Flex>
            )}
        </WrapItem>
    )
};