import { Flex, HStack, Icon, Image, Spinner, Text } from '@chakra-ui/react';
import millify from 'millify';
import { useContext } from 'react';
import { FaKeybase } from 'react-icons/fa';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';
import { AavegotchiHome, AavegotchiVerse } from '../../utils/EcosystemIcons';

function SectionHeader(props: any) {
  const { floorPrices, tokenData, userCurrency, itemsTVLoading, instsTVLoading } = useContext(UserContext);
  const { title } = props;

  const types: { [key: string]: { [key: string]: any } } = {
    'Gotchis': {
      icon: AavegotchiHome,
      place: 'none'
    },
    'Parcels': {
      icon: AavegotchiVerse,
      place: 'none'
    },
    'Gotchi Items': {
      icon: AavegotchiHome,
      place: 'items',
      loading: itemsTVLoading
    },
    'Parcel Items': {
      icon: AavegotchiVerse,
      place: 'installations',
      loading: instsTVLoading
    },
    'Harvest Rates': {
      place: 'none'
    },
    'Tokens': {
      place: 'tokens',
      loading: false
    },
    'Tickets': {
      place: 'none'
    },
    'Site Stuff': {
      place: 'none'
    }
  }
  return (
    <HStack spacing='2px' w='full' h='20px' padding='3px' align='center' justify='center' bg='mainUi.warm'>
        {types[title].icon ? (
        <Icon
        h='15px'
        as={types[title].icon}
        fill='mainUi.darker' />
        ) : (
          null
        )}
      <Text variant='identifier' color='mainUi.darker'>
        {title.length > 7 ? (
        title.includes("Gotchi") ? (
          title.replace("Gotchi ", "")
        ) : (
          title.replace("Parcel ", "")
        )
        ) : (title)} 
        {types[title].place == 'none' ? null : '  ≈'}
      </Text>
      {types[title].place == 'none' ? null :
        <HStack pr='2px' pl='2px' align='center' justify='center' spacing='2px'>
            <Image
              h='9px'
              w='9px'
              src={AssetLib.tickerToImage('GHST')} />
        {types[title].place == 'tokens' ? (
          !floorPrices[types[title].place] || !floorPrices[types[title].place]['total'] ? (
            <Spinner
                h='9px'
                w='9px'
                thickness='2px'
                color='mainUi.ggMagenta' />
            ) : (
              <Text variant='identifier' color='mainUi.darker'>
              {millify(floorPrices[types[title].place]['total'], { precision: 1, lowercase: true })} {!tokenData[4] ? (
                ''
              ) : (
                ' / ' + millify(floorPrices[types[title].place]['total'] * tokenData[4].prices[userCurrency], { precision: 1, lowercase: true }) + ' ' + userCurrency.toUpperCase())
                }
                </Text>)
        ) : (
            types[title].loading ? (
              <Spinner
                h='9px'
                w='9px'
                thickness='2px'
                color='mainUi.ggMagenta' />
            ) : (
              <Text variant='identifier' color='mainUi.darker'>
              {millify(floorPrices[types[title].place]['total'], { precision: 1, lowercase: true })} {!tokenData[4] ? (
                ''
              ) : (
                ' / ' + millify(floorPrices[types[title].place]['total'] * tokenData[4].prices[userCurrency], { precision: 1, lowercase: true }) + ' ' + userCurrency.toUpperCase())
                }
                </Text>)
            )}
            </HStack>
            }
    </HStack>
  );
}

export default SectionHeader;