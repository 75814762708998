import { HStack, Text, VStack, Image, Spinner } from '@chakra-ui/react';
import millify from 'millify';
import React, { useContext, useEffect } from 'react';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';
import { fadeIn } from '../../styles/Animations';

export default function GotchiPocket(props: any) {
    const { pocketValue } = props;


    return (
        <VStack
            align='center'
            justify='center'
            w='full'
            h='29px'
            bg='mainUi.darker'
            spacing='2px'
            border='1px'
            borderColor='mainUi.warmDark'>
            <Text
                variant='identifier'
                sx={{ lineHeight: '9px' }}>
                ITEMS
            </Text>
            <HStack
                align='center'
                justify='center'
                spacing='2px'>
                <Image
                    h='9px'
                    w='9px'
                    src={AssetLib.tickerToImage('GHST')} />
                {pocketValue != undefined ?
                    <Text
                        animation={fadeIn}
                        variant='identifier'
                        textColor='mainUi.warm'
                        sx={{ lineHeight: '9px' }}>
                        {parseFloat(pocketValue) ? millify(parseFloat(pocketValue)) : pocketValue.toString()}
                    </Text>
                    :
                    <Spinner
                        h='9px'
                        w='9px'
                        thickness='2px'
                        color='mainUi.warm' />
                }

            </HStack>
        </VStack>
    );
}