import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { dashThemes } from "./styles/Themes";
import Layout from "./metaComponents/Layout";
import UserContextProvider from "./contexts/UserContext";

ReactDOM.render(
  <React.StrictMode>
      <ChakraProvider theme={dashThemes}>
          <UserContextProvider>
            <Layout />
          </UserContextProvider>
      </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);