import { Flex, VStack, Text, Grid, WrapItem, Spinner, HStack, Image, Spacer, Box, AspectRatio, Icon, Tooltip } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import { alchemicaToColour, altarLvlToCooldown, formatInstallationAlchemicaCost, parcelToColour, rarityToColour } from '../../utils/EcosystemHelpers';
import { fadeIn } from '../../styles/Animations';
import ParcelMap from './ParcelMap';
import ParcelBoost from './ParcelAlch';
import { UserContext } from '../../contexts/UserContext';
import InstImage from '../InstallationCards.tsx/InstImage';
import { useContext, useEffect, useRef, useState } from 'react';
import millify from 'millify';
import { utils } from 'ethers';
import ParcelChannel from './ParcelChannel';
import ParcelAlch from './ParcelAlch';
import callContract from '../../api/CallContracts';
import AssetLib from '../../assets/images/AssetLib';

import { TbArrowBigUpLines } from 'react-icons/tb';
import { FaTimes } from 'react-icons/fa';

interface Inst {
    [key: string]: any
}

export default function ParcelCard(props: any) {
    const {
        id,
        size,
        parcelHash,
        district,
        fudBoost,
        fomoBoost,
        alphaBoost,
        kekBoost,
        hasBoost,
        boostValueInFud,
        viewPortRef,
        harvestData,
        lastChanneled,
        canChannel,
        equippedInstallations,
        channelStatus,
        lastClaimedAlchemica,
        overallTotalAlchemica,
        alchemicaRemoved,
        alchemicaLeft,
        alchStats,
        // harvestTotalInGHST
        ...rest
    } = props

    const options = {
        root: viewPortRef.current,
        rootMargin: '300px',
        threshold: 0,
    }
    const { parcelsOwned, userAddress, installationsOwned, parcelStats, parcelData } = useContext(UserContext);
    const { ref, inView } = useInView(options);
    let componentMounted = useRef(true)
    const [instHover, setInstHover] = useState<Inst>()
    const scrollRef = useRef(null);
    const [channelLoading, setChannelLoading] = useState(false);
    // const [alchStatsLoading, setAlchStatsLLoading] = useState(true);
    // const alchStats = useRef({})

    const onWheel = (e: any) => {
        e.preventDefault();
        const container: any = scrollRef.current;
        const containerScrollPosition = container.scrollLeft;
        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
        });
    };
    const handleInstMouseEnter = (inst: any) => {
        setInstHover(inst);
    }

    const handleChannel = () => {
        setChannelLoading(true);
        let idToParcelOwned = parcelsOwned[parcelsOwned.findIndex((parcel: any) => parcel.id === id)]
        let lastChanneledDate = lastChanneled * 1000;
        let aaltarIndex = equippedInstallations.findIndex((inst: any) => inst.id <= 19 && inst.type != 'tile')
        let now = new Date().getTime();
        if (aaltarIndex >= 0) {
            let aaltarIndex2 = installationsOwned.findIndex((inst: any) => inst.id == equippedInstallations[aaltarIndex].id && inst.type != 'tile')
            let inst: any = installationsOwned[aaltarIndex2];

            if ((now - lastChanneledDate) / (1000 * 60) < altarLvlToCooldown[inst.level]) {
                let totalMinutesLeft = altarLvlToCooldown[inst.level] - ((now - lastChanneledDate) / 1000 / 60);
                let minuteRemainder = totalMinutesLeft % 60;
                let hours = Math.floor(totalMinutesLeft / 60);

                if (canChannel) {
                    parcelStats.channel -= 1;
                }

                idToParcelOwned.canChannel = false;
                idToParcelOwned.channelStatus = { hours: hours, minuteRemainder: minuteRemainder, totalMinutesLeft: totalMinutesLeft, }
            } else {
                if (!canChannel) {
                    parcelStats.channel += 1;
                }
                idToParcelOwned.canChannel = true;
                idToParcelOwned.channelStatus = { totalMinutesLeft: 0 }
            }
        } else {
            if (canChannel) {
                parcelStats.channel -= 1;
            }
            idToParcelOwned.canChannel = false;
            idToParcelOwned.channelStatus = { aaltarless: true }

        }
        setChannelLoading(false)
    }

    const instStats = (inst: any) => {
        const stats: any = [
            { property: 'spillRate', header: 'rate' },
            { property: 'spillRadius', header: 'radius' },
            { property: 'harvestRate', header: 'rate' },
            { property: 'capacity', header: 'capacity' }
            // ,
            // {property: 'upgradeQueueBoost', header: 'boost'}
        ]

        return (
            stats.map((stat: any) => {
                return (
                    inst[stat.property] != 0 ?
                        <HStack key={stat.header} h='fit-content' w='full'>
                            <Text w='full' variant='identifier' color={alchemicaToColour[inst.alchemicaType]}
                                filter='auto'
                                brightness='0.6'>
                                {stat.header}
                            </Text>
                            <Text w='full' variant='identifier' color={alchemicaToColour[inst.alchemicaType]}
                                filter='auto'
                                brightness='0.8'>
                                {stat.property === 'spillRate' ? (
                                    `${millify(inst[stat.property] / 100, { precision: 1 })}%`
                                ) : (
                                    stat.property === 'spillRadius' ? (
                                        inst[stat.property]
                                    ) : (
                                        millify(inst[stat.property], { precision: 1 })
                                    )
                                )}
                            </Text>
                            <Text w='full' variant='identifier' color={alchemicaToColour[inst.alchemicaType]}>
                                {inst.level != 9 && inst.nextLevelId != 0 ? (
                                    stat.property === 'spillRate' ? (
                                        `${millify(inst.nextLvlStats[stat.property] / 100, { precision: 1 })}%`
                                    ) : (
                                        stat.property === 'spillRadius' ? (
                                            inst.nextLvlStats[stat.property]
                                        ) : (
                                            millify(inst.nextLvlStats[stat.property], { precision: 1 })
                                        )
                                    )
                                ) : (
                                    ''
                                )}
                            </Text>
                        </HStack> : null
                )
            })
        )

    }

    useEffect(() => {
        let i: NodeJS.Timer;

        if (userAddress !== '0xDd564df884Fd4e217c9ee6F65B4BA6e5641eAC63'.toLowerCase()) {
            i = setInterval(() => handleChannel(), 60000);
        }

        return () => {
            clearInterval(i)
            componentMounted.current = false;
        }
    }, [])

    const alchemica = [
        {
            ticker: 'FUD',
            colour: '#298356',
            boost: fudBoost,
            side: 'left'
        },
        {
            ticker: 'FOMO',
            colour: '#c1421c',
            boost: fomoBoost,
            side: 'right'
        },
        {
            ticker: 'ALPHA',
            colour: '#3cccff',
            boost: alphaBoost,
            side: 'left'
        },
        {
            ticker: 'KEK',
            colour: '#8f17f9',
            boost: kekBoost,
            side: 'right'
        }
    ];

    const alchemicaBoostValues: { [key: string]: number } = {
        "FUD": 1000,
        "FOMO": 500,
        "ALPHA": 250,
        "KEK": 100
    }

    const TileDetails = () => {
        return <VStack>

        </VStack>
    }

    // const parcelAlchemicaStats = async () => {
    //     console.log('doingthis')
    //     let parcelAlchemicaLeft = await callContract({ desired: 'parcelAlchemicaLeft', id: id }).then((res: any) => {
    //         if (res.status === 'success') {
    //             return formatInstallationAlchemicaCost(null, { level: 0 }, 0, res.data)
    //         } else {
    //             return []
    //         }
    //     }).catch((err: any) => {
    //         console.log(err + `can't get parcel(id: ${id}) alchemicaLeft`)
    //         return []
    //     })

    //     let parcelAlchemicaRemoved = await callContract({ desired: 'parcelAlchemicaRemoved', id: id }).then((res: any) => {
    //         if (res.status === 'success') {
    //             return formatInstallationAlchemicaCost(null, { level: 0 }, 0, res.data)
    //         } else {
    //             return []
    //         }
    //     }).catch((err: any) => {
    //         console.log(err + `can't get parcel(id: ${id}) alchemicaRemoved`)
    //         return []
    //     })

    //     let totalCache: any;

    //     if(parcelAlchemicaRemoved.length === 0) {
    //         parcelAlchemicaRemoved = [
    //             {'FUD': 0},
    //             {'FOMO': 0},
    //             {'ALPHA': 0},
    //             {'KEK': 0}
    //         ]
    //     }

    //     if (parcelAlchemicaLeft.length > 0) {
    //         totalCache = [
    //             { 'FUD': parcelAlchemicaLeft[0]['FUD'] + parcelAlchemicaRemoved[0]['FUD']},
    //             { 'FOMO': parcelAlchemicaLeft[1]['FOMO'] + parcelAlchemicaRemoved[0]['FOMO']},
    //             { 'ALPHA': parcelAlchemicaLeft[2]['ALPHA'] + parcelAlchemicaRemoved[0]['ALPHA']},
    //             { 'KEK': parcelAlchemicaLeft[3]['KEK'] + parcelAlchemicaRemoved[0]['KEK']}
    //         ]
    //     } else {
    //         parcelAlchemicaLeft = [
    //             {'FUD': 0},
    //             {'FOMO': 0},
    //             {'ALPHA': 0},
    //             {'KEK': 0}
    //         ]
    //         totalCache = [
    //             {'FUD': 0},
    //             {'FOMO': 0},
    //             {'ALPHA': 0},
    //             {'KEK': 0}
    //         ]
    //     }

    //     let parcelAlchStats: any = {
    //         alchemicaLeft: parcelAlchemicaLeft,
    //         alchemicaRemoved: parcelAlchemicaRemoved,
    //         overallTotal: totalCache
    //     }
    //     parcelData[id] = parcelAlchStats
    //     if (componentMounted.current) {
    //         setAlchStatsLLoading(false)
    //     }
    // }



    // const parcelPocketValueCalc = () => {
    //     console.log(parcelData[id].equippedInstallations)
    //     parcelData[id].equippedInstallations.forEach((eInst: any) => {
    //         installationsOwned.findIndex((inst: any) => { inst.id == eInst.id && inst.type != 'tile' })
    //         (
    //             floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] == 0 && craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`] ? (
    //               craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`] * (floor ? 1 : inst.stored + inst.equipped)
    //             ) : (
    //               floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] * (floor ? 1 : inst.stored + inst.equipped))
    //           )
    //     })
    // }

    return (
        <WrapItem
            key={'Parcel:' + id}
            ref={ref}
            w='fit-content'
            h='fit-content'
            bg=''
            padding='5px'>
            {inView ? (
                <VStack
                    role='group'
                    bg={parcelToColour[size].bright}
                    border='1px'
                    rounded='sm'
                    justify='flex-start'
                    flexDir='column'
                    borderColor='mainUi.darker'
                    _hover={{ borderColor: 'mainUi.ggMagenta' }}
                    h='fit-content'
                    w='200px'
                    spacing=''>
                    <VStack
                        paddingTop='8px'
                        paddingBottom='8px'
                        spacing=''>
                        <Text
                            isTruncated={true}
                            maxW='160px'
                            variant='alternate'
                            fontWeight='bold'
                            textColor={parcelToColour[size].dark}
                            sx={{ lineHeight: '16px' }}>
                            {parcelHash}
                        </Text>
                        <Text
                            textColor={parcelToColour[size].dark}
                            variant='identifier'
                            w='inherit'>
                            #{id} - D{district}
                        </Text>
                    </VStack>
                    <VStack
                        spacing=''
                        h='fit-content'
                        w='full'
                        flexDir='column'
                        align='flex-start'>
                        <ParcelMap id={id} size={size} />
                        <Flex
                            zIndex='2'
                            padding='2px'
                            w='full'
                            h='full'
                            bg={parcelToColour[size].dark}
                            // bgGradient='linear(to-t, mainUi.darker 25%, rgba(255, 255, 255, 0) 100%)'
                            justify='center'
                            align='center'>
                            <Flex filter={channelLoading ? 'auto' : 'none'} blur='sm'>
                                <ParcelChannel channelStatus={channelStatus} size={size} />
                            </Flex>
                        </Flex>
                    </VStack>
                    <VStack
                        animation={fadeIn}
                        spacing='4px'
                        w='full'
                        bg='mainUi.darker'
                        padding='4px'>
                        <HStack
                            spacing=''
                            h='44px'
                            flexDir='row'
                            w='full'
                            overflow='auto'
                            bg=''
                            ref={scrollRef}
                            onWheel={onWheel}
                            onMouseEnter={() => {
                                if (equippedInstallations.length > 4) {
                                    viewPortRef.current.style.paddingRight = '10px';
                                    viewPortRef.current.style.overflowY = 'hidden';
                                    viewPortRef.current.style.overflowX = 'hidden'
                                }
                            }}
                            onMouseLeave={() => {
                                if (equippedInstallations.length > 4) {
                                    viewPortRef.current.style.paddingRight = '';
                                    viewPortRef.current.style.overflowY = 'scroll';
                                    viewPortRef.current.style.overflowX = 'hidden'
                                }
                            }}
                            sx={{
                                '&::-webkit-scrollbar': {
                                    width: '3px',
                                    height: '3px',
                                    backgroundColor: 'mainUi.darker',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'mainUi.warmDark',
                                }
                            }}>
                            {!installationsOwned ? (
                                <Flex justify='center' align='center' w='full' h='full'>
                                    <Text variant='identifier' color='mainUi.ggMagenta'>
                                        Installations loading...
                                    </Text>
                                </Flex>
                            ) : (
                                equippedInstallations.length > 0 ? (
                                    equippedInstallations.sort((a: any, b: any) => a.id - b.id).map((i: any, index: any) => {
                                        let iO: any = installationsOwned.find((inst: any) => inst.typeId == i.typeId)
                                        if (iO) {
                                            return (
                                                <InstImage
                                                    key={i.typeId + iO.name + index}
                                                    onMouseEnter={() => {
                                                        handleInstMouseEnter({
                                                            hovering: true,
                                                            id: iO.id,
                                                            name: iO.name,
                                                            rarity: iO.rarity,
                                                            type: iO.type,
                                                            alchemicaCost: iO.alchemicaCost,
                                                            level: iO.level,
                                                            nextLvl: iO.nextLvlId,
                                                            disc: iO.disc,
                                                            harvestRate: iO.harvestRate,
                                                            spillRate: iO.spillRate,
                                                            spillRadius: iO.spillRadius,
                                                            quantity: i.quantity,
                                                            capacity: iO.capacity,
                                                            nextLvlStats: iO.nextLvlStats,
                                                            alchemicaType: iO.alchemicaType
                                                        });
                                                    }}
                                                    onMouseLeave={() => {
                                                        setInstHover({ hovering: false })
                                                    }}
                                                    colour={parcelToColour[size].dark}
                                                    animation='none'
                                                    h='35'
                                                    w='35'
                                                    id={iO.id}
                                                    name={iO.name}
                                                    rarity={iO.rarity}
                                                    type={iO.type}
                                                    placeholder={{ level: iO.level, name: iO.name }} />
                                            )
                                        }
                                    })
                                ) : (
                                    <Flex justify='center' align='center' w='full' h='full'>
                                        <Text variant='identifier' color='mainUi.warmDark'>
                                            no installations equipped
                                        </Text>
                                    </Flex>
                                )
                            )
                            }
                        </HStack>
                        {instHover?.hovering ? (
                            instHover.type === 'tile' ? (
                                <VStack
                                    w='full'
                                    h='95px'
                                    bg='mainUi.darker'
                                    border='1px'
                                    borderColor={
                                        instHover.alchemicaType ? (
                                            alchemicaToColour[instHover.alchemicaType]
                                        ) : (
                                            'mainUi.ggMagenta'
                                        )}
                                    justify='center'
                                    align='center'>
                                    <Text
                                        w='fit-content'
                                        variant='identifier'
                                        color='mainUi.darker'
                                        bg={
                                            instHover.alchemicaType ? (
                                                alchemicaToColour[instHover.alchemicaType]
                                            ) : (
                                                'mainUi.ggMagenta'
                                            )}>
                                        {instHover?.name.replace('FUD', '').replace('FOMO', '').replace('ALPHA', '').replace('KEK', '').replace('Alchemical', '').toUpperCase()}
                                    </Text>
                                    <HStack>
                                        <HStack spacing='2px'>
                                            <Text variant='identifier' color='mainUi.ggMagenta'>
                                                QTY
                                            </Text>
                                            <Text variant='identifier' color='mainUi.warm'>
                                                {instHover.quantity}
                                            </Text>
                                        </HStack>
                                    </HStack>
                                </VStack>
                            ) : (
                                instHover.type === 'functional' ? (
                                    <VStack
                                        h='fit-content'
                                        w='full'
                                        spacing='2px'
                                        align='center'
                                        justify='center'
                                        bg='mainUi.darker'>
                                        <VStack
                                            w='full'
                                            h='95px'
                                            bg='mainUi.darker'
                                            border='1px'
                                            spacing='2px'
                                            borderColor={
                                                instHover.alchemicaType ? (
                                                    alchemicaToColour[instHover.alchemicaType]
                                                ) : (
                                                    'mainUi.ggMagenta'
                                                )}
                                            justify='center'>
                                            <HStack h='fit-content' w='full' align='start' spacing=''>
                                                <Flex w='full' h='fit-content' bg={instHover.alchemicaType ? alchemicaToColour[instHover.alchemicaType] : 'mainUi.ggMagenta'}
                                                    filter='auto'
                                                    brightness='0.6'>
                                                    <Text w='full' variant='identifier' color='mainUi.darker' >
                                                        {instHover?.name.replace('FUD', '').replace('FOMO', '').replace('ALPHA', '').replace('KEK', '').replace('Alchemical', '').replace('LE Golden ', '').toUpperCase()}
                                                    </Text>
                                                </Flex>
                                                <Flex w='full' h='fit-content' bg={instHover.alchemicaType ? alchemicaToColour[instHover.alchemicaType] : 'mainUi.ggMagenta'}
                                                    filter='auto'
                                                    brightness='0.8'>
                                                    <Text w='full' variant='identifier' color='mainUi.darker'>
                                                        LEVEL {instHover.level}
                                                    </Text>
                                                </Flex>
                                                <Flex w='full' h='fit-content' bg={instHover.alchemicaType ? alchemicaToColour[instHover.alchemicaType] : 'mainUi.ggMagenta'}
                                                    filter='auto'>
                                                    <Text w='full' variant='identifier' color='mainUi.darker'>
                                                        {instHover.level != 9 && instHover.nextLevelId != 0 ? `LEVEL ${instHover.level + 1}` : 'COMPLETE'}
                                                    </Text>
                                                </Flex>
                                            </HStack>
                                            <VStack
                                                padding='2px'
                                                spacing='3px'
                                                w='full'
                                                h='full'
                                                align='center'>
                                                {instStats(instHover)}
                                            </VStack>
                                            <HStack h='15px' w='full' align='center' justify='center' bg={instHover.alchemicaType ? alchemicaToColour[instHover.alchemicaType] : 'mainUi.ggMagenta'}>
                                                <HStack spacing=''>
                                                    <Icon h='12px' w='12px' color='mainUi.darker' as={TbArrowBigUpLines} />
                                                    <Text variant='identifier' color='mainUi.darker'>
                                                        COST
                                                    </Text>
                                                </HStack>
                                                <HStack w='fit-content' pl='2px' pr='2px' align='center' spacing='4px' justify='center'>
                                                    {instHover.nextLevelId != 0 ? instHover.nextLvlStats.alchemicaCost.map((alch: any) => {
                                                        return (
                                                            <HStack justify='center' align='center' spacing='1px' key={Object.keys(alch)[0]}>
                                                                <Image
                                                                    h='10px'
                                                                    w='10px'
                                                                    src={AssetLib.tickerToImage(Object.keys(alch)[0])} />
                                                                <Text variant='identifier' color='mainUi.darker'>
                                                                    {millify(alch[Object.keys(alch)[0]], { precision: 2 })}
                                                                </Text>
                                                            </HStack>
                                                        )
                                                    }) : null}
                                                </HStack>
                                            </HStack>
                                        </VStack>
                                    </VStack>
                                ) : (
                                    <VStack
                                        w='full'
                                        h='95px'
                                        bg='mainUi.darker'
                                        border='1px'
                                        spacing='2px'
                                        borderColor={
                                            instHover.rarity != 'none' ? (
                                                rarityToColour[instHover.rarity].bright
                                            ) : (
                                                'mainUi.ggMagenta'
                                            )}
                                        justify='center'>
                                        <Text w='full' variant='identifier' color='mainUi.ggMagenta' >
                                            {instHover?.name.replace('FUD', '').replace('FOMO', '').replace('ALPHA', '').replace('KEK', '').replace('Alchemical', '').replace('LE Golden ', '').toUpperCase()}
                                        </Text>
                                        <HStack>
                                            <HStack spacing='2px'>
                                                <Text variant='identifier' color='mainUi.ggMagenta'>
                                                    QTY
                                                </Text>
                                                <Text variant='identifier' color='mainUi.warm'>
                                                    {instHover.quantity}
                                                </Text>
                                            </HStack>
                                        </HStack>
                                    </VStack>
                                ))
                        ) : (
                            <HStack
                                justify='center'
                                align='center'
                                h='fit-content'
                                w='full'
                                spacing='2px'>
                                {alchemica.map((alch: any, index: any) => {
                                    return (
                                        <ParcelAlch
                                            lastClaimedAlchemica={lastClaimedAlchemica}
                                            key={alch.ticker + 'boost'}
                                            ticker={alch.ticker}
                                            colour={alch.colour}
                                            side={alch.side}
                                            harvestData={harvestData}
                                            alchStats={alchStats}
                                            index={index} />
                                    )
                                })}
                            </HStack>
                        )}
                        <HStack
                            justify='center'
                            align='center'
                            h='fit-content'
                            w='full'
                            spacing='15px'>
                            {alchemica.map((alch: any, index: any) => {
                                return (
                                    <Tooltip key={'alchBoost: ' + index} w='full' variant='dash' label={
                                        <VStack spacing='-2px'>
                                            <Text variant='identifier'>parcel boost</Text>
                                            <HStack w='fit-content' spacing='2px' align='center' justify='center'>
                                                <Text variant='identifier' color='mainUi.warm'>{alch.boost}</Text>
                                                <Icon
                                                    w='8px'
                                                    h='8px'
                                                    color='mainUi.ggMagenta'
                                                    as={FaTimes} />
                                                <Text variant='identifier' color='mainUi.warm'>{alchemicaBoostValues[alch.ticker]}</Text>d
                                            </HStack>
                                        </VStack>}>
                                        <HStack w='fit-content' spacing='2px' align='center' justify='center' filter={alch.boost > 0 ? 'none' : 'auto'}
                                            brightness='60%'
                                            saturate='10%'>
                                            <Text w='full' h='fit-content' fontSize='8px' variant='identifier' color={alch.colour} filter='auto' brightness='80%'
                                                saturate='80%'>
                                                BOOST
                                            </Text>
                                            <Text w='full' h='fit-content' fontSize='8px' variant='identifier' color={alch.colour}>
                                                {alch.boost}
                                            </Text>
                                        </HStack>
                                    </Tooltip>
                                )

                            })}
                        </HStack>
                    </VStack>
                </VStack>
            ) : (
                <Flex
                    animation={fadeIn}
                    role='group'
                    bg={parcelToColour[size].bright}
                    border='1px'
                    rounded='sm'
                    justify='center'
                    align='center'
                    borderColor='mainUi.darker'
                    _hover={{ borderColor: 'mainUi.ggMagenta' }}
                    h='425px'
                    w='200px'
                    padding=''>
                    <Spinner
                        h='50px'
                        w='50px'
                        thickness='3px'
                        color={parcelToColour[size].dark} />
                </Flex>
            )
            }
        </WrapItem>
    );
}