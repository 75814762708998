import { Flex, HStack, VStack, Text, Spacer, Spinner, WrapItem, Icon, Tooltip } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { utils } from 'ethers';
import millify from 'millify';
import { useContext, useEffect, useRef, useState } from 'react';
import { FaTimes, FaEquals } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import QueryGraph from '../../api/QueryGraph';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';
import { fadeIn } from '../../styles/Animations';
import { rarityToColour } from '../../utils/EcosystemHelpers';
import InstImage from './InstImage';

export default function InstCard(props: any) {
    const { floorPrices, floorPriceAppend, floorPriceTotalValue, tokenData, craftCosts, installationsOwned, setInstsTVLoading } = useContext(UserContext)
    const [priceLoading, setPriceLoading] = useState(true)
    const { viewPortRef, inst } = props
    const options = {
        root: viewPortRef.current,
        rootMargin: '100px',
        threshold: 0,
        pollInterval: 7200000
    }
    const { ref, inView } = useInView(options);
    const componentMounted = useRef(true)

    const getFloorPrice = async (id: number) => {
        let idCache = id;
        let categoryCache = 4;
        let desired = inst.rarity == 'Godlike' ? 'lastSold' : 'floorPrice'

        if (inst.type == 'tile') {
            categoryCache = 5
        }

        if (inst.type == 'functional' && inst.level > 1) {
            idCache -= inst.level - 1;
        }

        const result = await QueryGraph({ desired: desired, variables: { id: idCache, category: categoryCache } })
        let listing = result.data.data.erc1155Listings[0]
        if (result.status == 'success' && listing != undefined) {
            if (inst.type != 'tile' && inst.id < 9) {
                const costCheck = inst.alchemicaCost.length > 0 ? inst.alchemicaCost.findIndex((alch: any) => alch != 0) : -1
                if (costCheck >= 0 && tokenData[4]) {
                let currentCostGbp = 0;
                tokenData.slice(0, 4).forEach((token: any, index: any) => {

                    let minusLvl1CraftCost = inst.alchemicaCost[index][token.ticker]
                    switch (token.ticker) {
                        case 'FUD':
                            minusLvl1CraftCost -= 3000;
                            break;
                        case 'FOMO':
                            minusLvl1CraftCost -= 1500;
                            break;
                        case 'ALPHA':
                            minusLvl1CraftCost -= 2000;
                            break;
                        case 'KEK':
                            minusLvl1CraftCost -= 750;
                            break;
                    }
                    currentCostGbp += token.prices.gbp * minusLvl1CraftCost
                })

                let craftCostInGHST = currentCostGbp / tokenData[4].prices.gbp
                craftCosts[inst.typeId] = craftCostInGHST
                let totalValue = craftCostInGHST + parseFloat(utils.formatEther(listing.priceInWei));
                floorPriceAppend('installations', inst.id, totalValue)
                floorPriceTotalValue('installations', totalValue * (inst.equipped + inst.stored), setInstsTVLoading, installationsOwned)
            }
            } else {
                floorPriceAppend('installations', inst.type == 'tile' ? `t${inst.id}` : inst.id, parseFloat(utils.formatEther(listing.priceInWei)))
                floorPriceTotalValue('installations', parseFloat(utils.formatEther(listing.priceInWei)) * (inst.equipped + inst.stored), setInstsTVLoading, installationsOwned)
            }
            if (componentMounted.current) {
                setPriceLoading(false)
            }
        } else {
            const costCheck = inst.alchemicaCost.length > 0 ? inst.alchemicaCost.findIndex((alch: any) => alch != 0) : -1
            if (costCheck >= 0 && tokenData[4]) {
                let currentCostGbp = 0;
                tokenData.slice(0, 4).forEach((token: any, index: any) => {
                    currentCostGbp += token.prices.gbp * inst.alchemicaCost[index][token.ticker]
                })

                craftCosts[inst.type == 'tile' ? `t${id}` : `i${id}`] = currentCostGbp / tokenData[4].prices.gbp
                floorPriceTotalValue('installations', craftCosts[inst.type == 'tile' ? `t${id}` : `i${id}`] * (inst.equipped + inst.stored), setInstsTVLoading, installationsOwned)
            } else {
                floorPriceAppend(inst.type == 'tile' ? 'tiles' : 'installations', inst.type == 'tile' ? `t${inst.id}` : inst.id, 0)
                floorPriceTotalValue('installations', 0 * (inst.equipped + inst.stored), setInstsTVLoading, installationsOwned)
            }

            if (componentMounted.current) {
                setPriceLoading(false)
            }
        }
    }
    const instValue = (floor: boolean) => {
        if ((inst.id > 9 || inst.type == 'tile') && craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`]) {
            return (
                <Tooltip label={<HStack h='15px' w='fit-content' justify='center' align='center' spacing='5px'>
                    {floor ? (
                        inst.alchemicaCost.map((alch: any) => {
                            return (
                                <HStack justify='center' align='center' spacing='2px' key={Object.keys(alch)[0]}>
                                <Image
                                h='10px'
                                w='10px'
                                src={AssetLib.tickerToImage(Object.keys(alch)[0])} />
                                <Text variant='identifier' color='mainUi.warm'>
                                {millify(alch[Object.keys(alch)[0]])}
                                </Text>
                                </HStack>
                            )
                        })
                    ) : (
                        <Text variant='identifier' color='mainUi.warm'>
                            total
                        </Text>
                    )}

                </HStack>} variant='dash'>
                    <HStack
                        spacing='1px'
                        align='center'
                        justify='center'>
                        <Image
                            h='9px'
                            w='9px'
                            src={AssetLib.tickerToImage('GHST')} />
                        <Text
                            animation={fadeIn}
                            zIndex='2'
                            variant='identifier'
                            textAlign='center'
                            color='mainUi.warm'>
                            {millify(craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`] * (floor ? 1 : (inst.stored + inst.equipped)))}
                        </Text>
                    </HStack>
                </Tooltip>
            )
        } else if (floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] > 0) {
            return (
                <Tooltip label={floor ? 'floor' : 'total'} variant='dash'>
                    <HStack
                        spacing='1px'
                        align='center'
                        justify='center'>
                        <Image
                            h='9px'
                            w='9px'
                            src={AssetLib.tickerToImage('GHST')} />
                        <Text
                            animation={fadeIn}
                            zIndex='2'
                            variant='identifier'
                            textAlign='center'
                            color='mainUi.warm'>
                            {millify(floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] * (floor ? 1 : (inst.stored + inst.equipped)))}
                        </Text>
                    </HStack>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip label='no craft cost or market value' variant='dash'>
                    <Text
                        animation={fadeIn}
                        zIndex='2'
                        variant='identifier'
                        textAlign='center'
                        color='mainUi.warm'>
                        0
                    </Text>
                </Tooltip>
            )
        }
    }

    useEffect(() => {
        if (!floorPrices['installations'] || !floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] && !craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`]) {
            getFloorPrice(inst.id);
        } else {
            setPriceLoading(false)
        }

        return () => {
            componentMounted.current = false;
        }
    }, [])

    if (inst.type == 'tile') {
        return (
            <WrapItem
                key={'Installation:' + inst.id}
                ref={ref}
                w='fit-content'
                h='fit-content'
                padding='5px'>
                {inView ? (
                    <VStack
                        bg='mainUi.darker'
                        border='1px'
                        rounded='sm'
                        borderColor='mainUi.darker'
                        _hover={{
                            borderColor: 'mainUi.ggMagenta'
                        }}
                        h='fit-content'
                        w='110px'
                        spacing=''>
                        <VStack
                            animation={fadeIn}
                            w='100px'
                            h='129px'
                            spacing=''
                            align='center'>
                            <Text
                                variant='identifier'>
                                {inst.name.replace("Tile", "").replace('LE ', '')}
                            </Text>
                            <Spacer/>
                                <InstImage
                                    id={inst.id}
                                    name={inst.name}
                                    rarity={inst.rarity}
                                    type={inst.type}/>
                            <Spacer/>
                        </VStack>
                        <HStack
                            animation={fadeIn}
                            w='full'
                            spacing='3px'
                            bg='mainUi.darker'
                            paddingTop='4px'
                            paddingBottom='4px'
                            align='center'
                            justify='center'>
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                instValue(true)
                            )}
                            <Icon
                                w='9px'
                                h='9px'
                                color='mainUi.ggMagenta'
                                as={FaTimes} />
                            <Tooltip label='quantity' variant='dash'>
                                <Text
                                    align='center'
                                    variant='identifier'
                                    color='mainUi.warm'>
                                    {inst.equipped + inst.stored}
                                </Text>
                            </Tooltip>
                            <Icon
                                w='9px'
                                h='9px'
                                color='mainUi.ggMagenta'
                                as={FaEquals} />
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                instValue(false)
                            )}
                        </HStack>
                    </VStack>
                ) : (
                    <Flex
                        animation={fadeIn}
                        role='group'
                        border='1px'
                        rounded='sm'
                        bg='mainUi.darker'
                        align='center'
                        borderColor='mainUi.darker'
                        _hover={{
                            borderColor: 'mainUi.ggMagenta'
                        }}
                        h='154px'
                        w='110px'
                        justify='center'>
                        <Spinner
                            h='20px'
                            w='20px'
                            color={'mainUi.ggMagenta'}
                            thickness='3px' />
                    </Flex>
                )}
            </WrapItem>
        )
    } else if (inst.type == 'cosmetic') {
        return (
            <WrapItem
                key={'Installation:' + inst.id}
                ref={ref}
                w='fit-content'
                h='fit-content'
                padding='5px'>
                {inView ? (
                    <VStack
                        role='group'
                        bg={inst.rarity == 'None' ? 'mainUi.darker' : rarityToColour[inst.rarity].bright}
                        border='1px'
                        rounded='sm'
                        align='center'
                        borderColor='mainUi.darker'
                        _hover={{
                            borderColor: 'mainUi.ggMagenta'
                        }}
                        h='fit-content'
                        w='110px'
                        spacing=''>
                        <VStack
                            animation={fadeIn}
                            position='relative'
                            w='100px'
                            h='129px'
                            spacing=''
                            align='center'
                            justify='center'>
                            <Flex position='absolute' h='100px'>
                                <InstImage id={inst.id} name={inst.name} rarity={inst.rarity} type={inst.type} />
                            </Flex>
                            <Text
                                pt='4px'
                                zIndex='1'
                                h='fit-content'
                                w='full'
                                align='center'
                                fontWeight='black'
                                fontSize='x-small'
                                textColor={inst.rarity == 'None' ? 'mainUi.ggMagenta' : rarityToColour[inst.rarity].dark}
                                _groupHover={{
                                    textColor: 'mainUi.ggMagenta',
                                }}
                                sx={{
                                    lineHeight: '9px'
                                }}>
                                {inst.rarity == 'None' ? inst.name.replace(` Level ${inst.level}`, '').replace('LE ', '') : inst.name.replace(`${inst.rarity} `, '').replace('LE ', '')}
                            </Text>
                            <Spacer />
                            <Text
                                zIndex='1'
                                variant='identifier'
                                textColor={inst.rarity == 'None' ? 'mainUi.ggMagenta' : rarityToColour[inst.rarity].dark}
                                textAlign='center'>
                                {inst.rarity == 'None' ? `LVL ${inst.level}` : `#${inst.id}`}
                            </Text>
                        </VStack>
                        <HStack
                            animation={fadeIn}
                            w='full'
                            spacing='3px'
                            bg='mainUi.darker'
                            paddingTop='4px'
                            paddingBottom='4px'
                            align='center'
                            justify='center'>
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                instValue(true)
                            )}
                            <Icon
                                w='9px'
                                h='9px'
                                color='mainUi.ggMagenta'
                                as={FaTimes} />
                            <Tooltip label='quantity' variant='dash'>
                                <Text
                                    align='center'
                                    variant='identifier'
                                    color='mainUi.warm'>
                                    {inst.equipped + inst.stored}
                                </Text>
                            </Tooltip>
                            <Icon
                                w='9px'
                                h='9px'
                                color='mainUi.ggMagenta'
                                as={FaEquals} />
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                instValue(false)
                            )}
                        </HStack>
                    </VStack>
                ) : (
                    <Flex
                        animation={fadeIn}
                        role='group'
                        border='1px'
                        rounded='sm'
                        bg={inst.rarity == 'None' ? 'mainUi.darker' : rarityToColour[inst.rarity].bright}
                        align='center'
                        borderColor='mainUi.darker'
                        _hover={{
                            borderColor: 'mainUi.ggMagenta'
                        }}
                        h='154px'
                        w='110px'
                        justify='center'>
                        <Spinner
                            h='20px'
                            w='20px'
                            color={inst.rarity == 'None' ? 'mainUi.ggMagenta' : rarityToColour[inst.rarity].dark}
                            thickness='3px' />
                    </Flex>
                )}
            </WrapItem>
        )
    } else {
        return (
            <WrapItem
                key={'Installation:' + inst.id}
                ref={ref}
                w='fit-content'
                h='fit-content'
                padding='5px'>
                {inView ? (
                    <VStack
                        role='group'
                        bg={inst.rarity == 'None' ? 'mainUi.ggIndigo' : rarityToColour[inst.rarity].bright}
                        border='1px'
                        rounded='sm'
                        align='center'
                        borderColor='mainUi.darker'
                        _hover={{
                            borderColor: 'mainUi.ggMagenta'
                        }}
                        h='fit-content'
                        w='110px'
                        spacing=''>
                        <VStack
                            position='relative'
                            animation={fadeIn}
                            w='100px'
                            h='129px'
                            spacing=''
                            align='center'
                            justify='center'>
                            <Flex position='absolute' h='100px'>
                                <InstImage id={inst.id} name={inst.name} h='100' w='100' rarity={inst.rarity} />
                            </Flex>
                            <Text
                                pt='4px'
                                zIndex='1'
                                h='fit-content'
                                w='full'
                                align='center'
                                fontWeight='black'
                                fontSize='x-small'
                                textColor={inst.rarity == 'None' ? 'mainUi.darker' : rarityToColour[inst.rarity].dark}
                                _groupHover={{
                                    textColor: 'mainUi.ggMagenta',
                                }}
                                sx={{
                                    lineHeight: '9px'
                                }}>
                                {inst.rarity == 'None' ? inst.name.replace(` Level ${inst.level}`, '').replace('LE ', '') : inst.name.replace(`${inst.rarity} `, '').replace('LE ', '')}
                            </Text>
                            <Spacer />
                            <Text
                                zIndex='1'
                                variant='identifier'
                                textColor={inst.rarity == 'None' ? 'mainUi.darker' : rarityToColour[inst.rarity].dark}
                                textAlign='center'>
                                {inst.rarity == 'None' ? `LVL ${inst.level}` : `#${inst.id}`}
                            </Text>
                        </VStack>
                        <HStack
                            animation={fadeIn}
                            w='full'
                            spacing='3px'
                            bg='mainUi.darker'
                            paddingTop='4px'
                            paddingBottom='4px'
                            align='center'
                            justify='center'>
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                instValue(true)
                            )}
                            <Icon
                                w='9px'
                                h='9px'
                                color='mainUi.ggMagenta'
                                as={FaTimes} />
                            <Tooltip label='quantity' variant='dash'>
                                <Text
                                    align='center'
                                    variant='identifier'
                                    color='mainUi.warm'>
                                    {inst.equipped + inst.stored}
                                </Text>
                            </Tooltip>
                            <Icon
                                w='9px'
                                h='9px'
                                color='mainUi.ggMagenta'
                                as={FaEquals} />
                            {priceLoading ? (
                                <Spinner
                                    h='9px'
                                    w='9px'
                                    thickness='2px'
                                    color='mainUi.warm' />
                            ) : (
                                instValue(false)
                            )}
                        </HStack>
                    </VStack>
                ) : (
                    <Flex
                        animation={fadeIn}
                        role='group'
                        border='1px'
                        rounded='sm'
                        bg={inst.rarity == 'None' ? 'mainUi.darker' : rarityToColour[inst.rarity].bright}
                        align='center'
                        borderColor='mainUi.darker'
                        _hover={{
                            borderColor: 'mainUi.ggMagenta'
                        }}
                        h='154px'
                        w='110px'
                        justify='center'>
                        <Spinner
                            h='20px'
                            w='20px'
                            color={inst.rarity == 'None' ? 'mainUi.ggMagenta' : rarityToColour[inst.rarity].dark}
                            thickness='3px' />
                    </Flex>
                )}
            </WrapItem>
        );
    }
}