import { HStack, Text } from '@chakra-ui/react';
import { parcelToColour } from '../../utils/EcosystemHelpers';

export default function PaarcelChannel(props: any) {
    const { channelStatus, size } = props;
    
    return (
        channelStatus && !channelStatus.aaltarless ? (
            channelStatus.totalMinutesLeft == 0 ? (
                <Text variant='identifier' color={parcelToColour[size].bright}>
                    Channel available
                </Text>
            ) : (
                <HStack spacing='2px' justify='center' align='center' h='fit-content'>
                <Text variant='identifier' color={parcelToColour[size].bright} h='fit-content'>
                    Channel in
                </Text>
                <Text variant='identifier' color={parcelToColour[size].bright} h='fit-content'>
                    {`${('0' + channelStatus.hours).slice(-2)}`}
                </Text>
                <Text variant='identifier' fontSize='7px' color={parcelToColour[size].bright} h='fit-content'>
                    H
                </Text>
                <Text variant='identifier' color={parcelToColour[size].bright} h='fit-content'>
                    {('0' + channelStatus.minuteRemainder.toFixed(0)).slice(-2)}
                </Text>
                <Text variant='identifier' fontSize='7px' color={parcelToColour[size].bright} h='fit-content'>
                    M
                </Text>
                </HStack>
                    
            )
        ) : (
            <Text variant='identifier' color={parcelToColour[size].bright} >
            No aaltar to channel
        </Text>
        )
    )
}