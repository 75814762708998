import { Box, Button, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { parcelSizeToNumber, parcelToColour, rarityToColour } from '../../utils/EcosystemHelpers';

export default function FilterByStat(props: any) {
    const { filterList, setFilter, isPressed, filteringFor } = props
    const { parcelStats, gotchiStats, gotchiItemStats, parcelItemStats, handleParcelChannelLoading } = useContext(UserContext)

    const redundantChecks = () => {
        let gotOne: boolean = false;
        filterList.forEach((filter: any) => {
                if (filter != 't' && filteringForToStats[filteringFor][filter] == filteringForToStats[filteringFor]['total']) {
                    gotOne = true;
                }
        })
        return gotOne;
    }

    const filteringForToStats: any = {
        parcels: parcelStats,
        items: gotchiItemStats,
        gotchis: gotchiStats,
        installations: parcelItemStats
    }

    const stat = (filter: any) => {
        let rarities = ['common', 'uncommon', 'rare', 'legendary', 'mythical', 'godlike']
        let isRarity = rarities.findIndex((rarity: string) => rarity == filter)

        let parcelSizes: any[] = ['Humble', 'Reasonable', 'Spacious']
        let isParcelSize = parcelSizes.findIndex((size: string) => size == filter)

        if (isRarity >= 0) {
            return (
                <HStack spacing='4px'>
                    <Box
                        border='1px'
                        borderColor='mainUi.darker'
                        h='10px'
                        w='10px'
                        bg={filter == 'godlike' ? (
                            rarityToColour[(filter.charAt(0).toUpperCase() + filter.slice(1))].bright
                        ) : (
                            rarityToColour[(filter.charAt(0).toUpperCase() + filter.slice(1))].dark
                        )} />
                    <Text variant='identifier' color='mainUi.warm'>
                        {filteringForToStats[filteringFor][filter]}
                    </Text>
                </HStack>
            )
        } else if (isParcelSize >= 0) {
            return (
                <HStack spacing='4px'>
                    <Box
                        border='1px'
                        borderColor='mainUi.darker'
                        h='10px'
                        w='10px'
                        bg={parcelToColour[parcelSizeToNumber[filter]].bright} />
                    <Text variant='identifier' color='mainUi.warm'>
                        {filteringForToStats[filteringFor][filter.toLowerCase()]}
                    </Text>
                </HStack>
            )
        } else {
            return (
                <HStack spacing='4px'>
                    <Text variant='identifier'>
                        {filter.toUpperCase()}
                    </Text>
                    <Text variant='identifier' color='mainUi.warm'>
                        {filteringForToStats[filteringFor][filter == 't' ? 'total' : filter]}
                    </Text>
                </HStack>
            )
        }
    }

    return (
        <HStack w='full' h='full' spacing='' align='center' justify='center'>
            <Wrap pr='5px' pl='5px' pb='8px' pt='8px' w='full' h='full' justify='end' align='start' spacing='4px'>
                {filterList.map((filter: any) => {
                    if (filter === 't' && redundantChecks() == true) {
                        return (
                            null
                        )
                    } else if(filteringForToStats[filteringFor][filter == 't' ? 'total' : filter.toLowerCase()] > 0) {
                        return (
                                <WrapItem key={filter} onClick={() => setFilter(filter)}>
                                    <Button
                                        size='xs'
                                        role='group'
                                        variant='sort'
                                        isActive={filter === isPressed}
                                        _active={{
                                            borderColor: 'mainUi.ggMagenta'
                                        }}>
                                        {stat(filter)}
                                    </Button>
                                </WrapItem>
                        )
                    } else {
                        return (
                            null
                        )
                    }
                })}
            </Wrap>
        </HStack>
    )
}