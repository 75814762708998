import { Box, Flex, Spinner, useStyleConfig } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { collateralObjects } from '../../utils/EcosystemHelpers';
import { fadeIn } from '../../styles/Animations';

interface details {
    [key: string]: string
}

export default function GotchiSvg(props: any) {
    const {
        id,
        traits,
        collateral,
        wearables,
        hauntId,
        onMouseEnter,
        onMouseLeave,
        ...rest } = props

    const componentMounted = useRef(true);
    const errorMessage = useRef("");
    const { userMedia } = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    let styles: any;
    let svgDetails = useRef<details>({})

    const getGotchiSvg = async () => {
    //     setLoading(true);
    //     setError(false)

        let wearableCache: any[] = [];
        props.wearables.forEach((wearable: any) => {
            if (wearable == 210) {
                wearableCache.push(0)
            } else (
                wearableCache.push(wearable)
            )
        })

        svgDetails.current.variant = collateralObjects[collateral].name;
        svgDetails.current.colPrimary = collateralObjects[collateral].primaryColor

            if (traits[5] <= 1) {
                svgDetails.current.eyeColour = '#FF00FF'
            } else if (traits[5] <= 9) {
                svgDetails.current.eyeColour = '#0165FF'
            } else if (traits[5] <= 24) {
                svgDetails.current.eyeColour = '#5D25BE'
            } else if (traits[5] <= 74) {
                svgDetails.current.eyeColour = svgDetails.current.colPrimary
            } else if (traits[5] <= 90) {
                svgDetails.current.eyeColour = '#37818F'
            } else if (traits[5] <= 97) {
                svgDetails.current.eyeColour = '#EA8D26'
            } else (
                svgDetails.current.eyeColour = '#51FEA9'
            )

            if (wearables[0] === 0 && wearables[4] === 0 && wearables[5] === 0) {
                svgDetails.current.setHandsOut = 'none'
                svgDetails.current.setHandsIn = 'block'
            } else {
                svgDetails.current.setHandsOut = 'block'
                svgDetails.current.setHandsIn = 'none'
            }  

            if(componentMounted.current) {
                setLoading(false)
            }
        }
        // } 
        // else {
        //     setError(true)
        //     throw new Error(result.data)
        // }

    useEffect(() => {
        getGotchiSvg()
        return (() => {
            componentMounted.current = false
        })
    }, [userMedia['gotchis'][id]])

    let v = svgDetails.current.variant
    let eC = svgDetails.current.eyeColour
    let hO = svgDetails.current.setHandsOut
    let hI = svgDetails.current.setHandsIn
    styles = useStyleConfig('GotchiSvg', { v, eC, hO, hI })

    return (
        loading ? (
            <Flex
                height='200px'
                width='full'
                align='center'
                justify='center'>
                    <Spinner
                        h='30px'
                        w='30px'
                        color={hauntId != 1 ? collateralObjects[collateral].primaryColor : 'mainUi.darker'}
                        thickness='3px' />
            </Flex>
        ) : (
            <Box
                className={`gotchi${id}`}
                animation={fadeIn}
                // sx={{ filter: 'drop-shadow(0px 0px 20px rgb(0 0 0 / 0.3))' }}
                height='210px'
                width='full'
                zIndex='0'
                __css={styles}
                dangerouslySetInnerHTML={{ __html: userMedia['gotchis'][id].svg }} />
        )
    );
}