import { Button, HStack, Spinner, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { UserContext } from '../../contexts/UserContext';

export default function Refresh(props: any) {
    const { stuff, iconSide } = props;
    const { getUserGotchis, getUserParcels } = useContext(UserContext)
    const [refreshing, setRefreshing] = useState(false)

    const typeToFunction: any = {
        'Gotchi Stuff': getUserGotchis,
        'Parcel Stuff': getUserParcels
    }

    const handleRefresh = () => {
        setRefreshing(true)
        typeToFunction[stuff](setRefreshing)
    }

    return (
        <Button
            spinnerPlacement='start'
            size='x-sm'
            color='mainUi.ggMagenta'
            variant='dash'
            onClick={() => { handleRefresh() }}
            role='group'>
                {iconSide === 'left' ? (
                <HStack h='17px' spacing='3px'>
                {refreshing ? (
                    <Spinner h='12px' w='12px' thickness='1px' />
                ) : (
                    <IoRefresh />
                )}
                <Text
                    variant='identifier'
                    color='mainUi.warm'
                    _groupHover={{ color: 'mainUi.ggMagenta' }}>
                    {stuff}
                </Text>
                </HStack>
                ) : (
                    <HStack h='17px' spacing='3px'>
                    <Text
                        variant='identifier'
                        color='mainUi.warm'
                        _groupHover={{ color: 'mainUi.ggMagenta' }}>
                        {stuff}
                    </Text>
                    {refreshing ? (
                        <Spinner h='12px' w='12px' thickness='1px' />
                    ) : (
                        <IoRefresh />
                    )}
                    </HStack>
                )}
        </Button>
    );
}