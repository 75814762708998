const mapSkip: any[] = [
  {skip: 0},
  {skip: 1000},
  {skip: 2000},
  {skip: 3000},
  {skip: 4000},
  {skip: 5000}
]

const requests: any = {
  'userGotchis': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-core-matic',
    query: `query($userAddress: String!) {
        ${mapSkip.map((skip: any, index: any) => {
            return (
              `gotchis${index}: user(id: $userAddress) {
                id
                gotchisOwned(first: 1000, skip: ${skip.skip} where: {status: 3}) {
                  id
                  name
                  numericTraits
                  modifiedNumericTraits
                  withSetsNumericTraits
                  baseRarityScore
                  modifiedRarityScore
                  withSetsRarityScore
                  kinship
                  equippedWearables
                  experience
                  level
                  toNextLevel
                  collateral
                  hauntId
                  createdAt
                  possibleSets
                  equippedSetID
                  equippedSetName
                  usedSkillPoints
                  claimedAt
                }
              }`
            )
          })}
    }`
  },
  'lentGotchis': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-core-matic',
    query: `query($userAddress: String!) {
        ${mapSkip.map((skip: any, index: any) => {
            return (
              `lentGotchis${index}: gotchiLendings(first: 1000, skip: ${skip.skip}, where: {originalOwner: $userAddress, cancelled: false, completed: false}) {
                  id
                  upfrontCost
                  gotchi {
                    id
                    name
                    numericTraits
                    modifiedNumericTraits
                    withSetsNumericTraits
                    baseRarityScore
                    modifiedRarityScore
                    withSetsRarityScore
                    kinship
                    equippedWearables
                    experience
                    level
                    toNextLevel
                    collateral
                    hauntId
                    createdAt
                    possibleSets
                    equippedSetID
                    equippedSetName
                    usedSkillPoints
                    claimedAt
                  }
                }`
            )
          })}
    }`
  },
  'borrowedGotchis': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-core-matic',
    query: `query($userAddress: String!){
      user(id: $userAddress) {
        gotchisBorrowed
      }
    }`
  },
  'gotchiSvgs': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-svg',
    query: `query($gotchiIds: [String!]){
      ${mapSkip.map((skip: any, index: any) => {
        return (
      `gotchiSvgs${index}: aavegotchis(first: 1000, skip: ${skip.skip} where: {id_in: $gotchiIds }) {
        id
        svg
        left
        right
      }`
      )
  })}
  }`
  },
  'userParcels': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-core-matic',
    query: `query($userAddress: String!) {
      ${mapSkip.map((skip: any, index: any) => {
        return (
          `parcels${index}: user(id: $userAddress) {
            id
            parcelsOwned(first:1000, skip: ${skip.skip}) {
              id
              tokenId
              parcelId
              district
              parcelHash
              fudBoost
              fomoBoost
              alphaBoost
              kekBoost
              size
              historicalPrices
            }
          }`
        )
    })}}`
  },
  'equippedInsts': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/gotchiverse-matic',
    query: `query($userAddress: String!, $parcelIds: [String!]){
      ${mapSkip.map((skip: any, index: any) => {
        return (
          `equippedInsts${index}: installations(first: 1000, skip: ${skip.skip}, where:{equipped: true, parcel_in: $parcelIds}) {
            x
            y
            type {
              id
              width
              height
              installationType
              level
              alchemicaType
              spillRadius
              spillRate
              upgradeQueueBoost
              nextLevelId
              harvestRate
              capacity
              prerequisites
              amountPrerequisites
              craftTime
              deprecated
              alchemicaCost
              name
              amount
            }
            equipped
            parcel {
                id
              lastChanneledAlchemica
              lastClaimedAlchemica
            }
        }`
  )})}}`
  },
  'instTypes': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/gotchiverse-matic',
    query: `query($instIds: [Int!]){
          installationTypes(first: 1000 where: {id_in: $instIds}) {
            id
            width
            height
            installationType
            level
            alchemicaType
            spillRadius
            spillRate
            upgradeQueueBoost
            craftTime
            nextLevelId
            deprecated
            alchemicaCost
            harvestRate
            capacity
            prerequisites
            amountPrerequisites
            name
            amount
          }
        }`
  },
  'equippedTiles': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/gotchiverse-matic',
    query: `query($userAddress: String!, $parcelIds: [String!]){
      ${mapSkip.map((skip: any, index: any) => {
        return (
          `equippedTiles${index}: tiles(first: 1000, skip: ${skip.skip}, where:{equipped: true, parcel_in: $parcelIds}) {
            x
            y
            type {
              id
              width
              height
              craftTime
              deprecated
              alchemicaCost
              name
              amount
            }
            equipped
            parcel {
                id
              lastChanneledAlchemica
              lastClaimedAlchemica
            }
        }`
  )})}}`
  },
  'gotchiData': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/gotchiverse-matic',
    query: `query($id: Int!){
          gotchi(
              id: $id
          ) {
            id
            lastChanneledAlchemica
          }
        }`
  },
  'floorPrice': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-core-matic',
    query: `query($id: Int!, $category: Int!) {
      erc1155Listings(first: 1, orderBy: priceInWei, orderDirection: asc, where: {erc1155TypeId: $id, category: $category, cancelled: false, sold: false}) {
          priceInWei
      }
  }`
  },
  'lastSold': {
    graph: 'https://api.thegraph.com/subgraphs/name/aavegotchi/aavegotchi-core-matic',
    query: `query($id: Int!, $category: Int!) {
      erc1155Listings(first: 1, orderBy: timeLastPurchased, orderDirection: desc, where:{erc1155TypeId: $id, category: $category, cancelled: false, sold: true}) {
        priceInWei,
      }
    }`
  },
  'tickets': {
    address: '0x86935F11C86623deC8a25696E1C19a8659CbF95d'
  },
  'gotchisvgs': {

    address: '0x86935F11C86623deC8a25696E1C19a8659CbF95d'
  }

  // 'tokens': {
  //     abi: erc20ABI,
  // }
}

export default async function QueryGraph(props: any) {
  try {
    let result = await fetch(requests[props.desired].graph, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: requests[props.desired].query,
        variables: props.variables
      })}).then((res: any) => { 
        return res.json() 
      }).then((jsonRes: any) => {
        return { status: 'success', data: jsonRes }
      }).catch((err: any) => {
        return { status: 'failure', data: err}
      })
      return result
  } catch (err) {
    return { status: 'failure', data: err }
  }
}
