import { Flex, HStack, Image, Spinner, VStack, Text, AspectRatio, Box } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import AssetLib from '../../assets/images/AssetLib';
import { UserContext } from '../../contexts/UserContext';
import { fadeIn } from '../../styles/Animations';

export default function InstImage(props: any) {
    const { id, rarity, name, type, h, w, onMouseEnter, onMouseLeave, colour, tileImageUrl } = props;
    const componentMounted = useRef(true)
    const { userMedia } = useContext(UserContext)
    const [ loading, setLoading ] = useState(true)

    const alchemica = [
        'FUD',
        'FOMO',
        'ALPHA',
        'KEK'
    ]

    const getInstallationMedia = async () => {
        let dataType = 'png'
        let condition = ['Rofl', 'Flower'].some((el: string) => name.includes(el))

        if (type == 'cosmetic' && !condition) {
            dataType = 'gif'
        }

        let tempNewTileUrl: any = {
            8: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_8.cbb183e8.png&w=640&q=75',
            9: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_9.81bd3886.png&w=640&q=75',
            10: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_10.08468f94.png&w=640&q=75',
            11: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_11.0cde9a7c.png&w=640&q=75',
            12: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_12.3a4461b8.png&w=640&q=75',
            13: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_13.f5adfb3c.png&w=640&q=75',
            14: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_14.0ef17d04.png&w=640&q=75',
            15: 'https://verse.aavegotchi.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FTile_LE_15.ea1ca68b.png&w=640&q=75'
        }

        const automata: any = {
            tile: `https://app.aavegotchi.com/_next/image?url=%2Fimages%2Ftiles%2Ftile${id}.png&w=256&q=75`,
            cosmetic: `https://app.aavegotchi.com/_next/image?url=%2Fimages%2Finstallations%2F${id}.${dataType}&w=256&q=75`
        }
        let toFetch = automata[type]

        if(type === 'tile' && tempNewTileUrl[id]) {
            toFetch = tempNewTileUrl[id]
        }

        try {
            const response = await fetch(toFetch)
                .then((res: any) => { return res.blob(); })
                .then((blob: any) => { return URL.createObjectURL(blob); })

            if (type == 'tile') {
                userMedia['tiles'][id] = response
            } else {
                userMedia['installations'][id] = response
            }

            if(componentMounted.current) {
                setLoading(false);
            }

        } catch (error: any) {
            setTimeout(getInstallationMedia, 5000)
            throw new Error(error)
        }
    }

    useEffect(() => {
        if (type == 'tile' && !userMedia['tiles'][id]) {
            getInstallationMedia();
        } else if (type == 'cosmetic' && !userMedia['installations'][id]) {
            getInstallationMedia();
        } else {
            if(componentMounted.current) {
                setLoading(false);
            }
        }

        return () => {
            componentMounted.current = false;
        }
    }, [])

    return (
        loading ? (
            <Flex h={`${h}px`} w={`${w}px`} align='center' justify='center' >
                <Spinner w={`${w / 3}px`} h={`${h / 3}px`} color={colour}/>
            </Flex>
        ) : (
            type == 'tile' ? (
                <Image
                padding='1px'
                animation={fadeIn}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                h={`${h}px`}
                w={`${w}px`}
                src={userMedia['tiles'][id]}/>
            ) : (
                type == 'cosmetic' ? (
                    <Image
                    padding='1px'
                    animation={fadeIn}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    h={`${h}px`}
                    w={`${w}px`}
                    src={userMedia['installations'][id]}/>
                ) : (
                    <Image
                    padding='1px'
                    maxH={`${h}px`}
                    maxW={`${w}px`}
                    align='center'
                    alignContent='center'
                    alignSelf='center'
                    alignItems='center'
                    animation={fadeIn}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    src={AssetLib.installationIdToImage(id)}/>
                )
            )
 
        )
    )
}