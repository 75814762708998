import { VStack, Wrap, useMediaQuery, Flex, Spinner, Icon, HStack, Text, Image } from "@chakra-ui/react";
import { UserContext } from "../../contexts/UserContext";
import { useContext, useRef, useState } from "react";
import FilterByStat from "../Common/FilterByStat";
import SortBar from "../Common/SortBar";
import { AavegotchiItems, AavegotchiVerse } from "../../utils/EcosystemIcons";
import { fadeIn } from "../../styles/Animations";
import InstCard from "./InstCard";
import AssetLib from "../../assets/images/AssetLib";
import millify from "millify";
import SectionHeader from "../Common/SectionHeader";
import InstImage from "./InstImage";

const styles = {
  a: {
    background: 'none',
    color: '#FA34F3'
  }
};

const INSTALLATION_FILTERS: { [key: string]: any } = {
  't': (inst: any) => inst.id,
  'cos': (inst: any) => inst.rarity != 'None',
  'func': (inst: any) => inst.rarity == 'None',
  'disc': (inst: any) => inst.disc == true,
  'tiles': (inst: any) => inst.type == 'tile',
  'common': (inst: any) => inst.rarity === 'Common',
  'uncommon': (inst: any) => inst.rarity === 'Uncommon',
  'rare': (inst: any) => inst.rarity == 'Rare',
  'legendary': (inst: any) => inst.rarity === 'Legendary',
  'mythical': (inst: any) => inst.rarity === 'Mythical',
  'godlike': (inst: any) => inst.rarity === 'Godlike'
}

const FILTER_NAMES = Object.keys(INSTALLATION_FILTERS)

const INSTALLATION_SORT_OPTIONS = [
  { name: 'QTY', sort: 'quantity' },
  { name: 'ID', sort: 'id' },
  { name: 'TOTAL', sort: 'total' },
  { name: 'FLOOR', sort: 'floor' }
]

export default function InstCards() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { installationsOwned, floorPrices, installationsLoading, craftCosts } = useContext(UserContext);
  const [filterState, setItemFilter] = useState('t');
  const [sortItemsBy, setSortItemsBy] = useState('id');
  const [itemSortDir, setItemSortDir] = useState('asc');
  const viewPortRef = useRef(null)

  const propComparator = (toSort: any, sortDir: any) => {
    let valueComp = (inst: any, floor: boolean) => {
      return (
        !floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] && craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`] ? (
          craftCosts[inst.type == 'tile' ? `t${inst.id}` : `i${inst.id}`] * (floor ? 1 : inst.stored + inst.equipped)
        ) : (
          floorPrices['installations'][inst.type == 'tile' ? `t${inst.id}` : inst.id] * (floor ? 1 : inst.stored + inst.equipped))
      )
    }
    switch (toSort) {
      case 'floor':
        return (a: any, b: any) => sortDir === 'asc' ? (
          valueComp(a, true) - valueComp(b, true)
        ) : (
          valueComp(b, true) - valueComp(a, true)
        )
      case 't':
        return (a: any, b: any) => sortDir === 'asc' ? (
          valueComp(a, false) - valueComp(b, false)
        ) : (
          valueComp(b, false) - valueComp(a, false)
        )
      case 'quantity':
        return (a: any, b: any) => sortDir === 'asc' ?
          (a.stored + a.equipped) - (b.stored + b.equipped) :
          (b.stored + b.equipped) - (a.stored + a.equipped);
      case 'id':
        return (a: any, b: any) => sortDir === 'asc' ?
          a[toSort] - b[toSort] :
          b[toSort] - a[toSort];
    }
  }

  return (
    installationsLoading ? (
      <Flex
        animation={fadeIn}
        w='full'
        h='full'
        justify='center'
        align='center'
        position='relative'>
        <Spinner
          h='50px'
          w='50px'
          thickness='3px'
          color='mainUi.warmDark'
          position='absolute' />
        <Icon
          as={AavegotchiItems}
          h='24px'
          w='24px'
          fill='mainUi.warm' />
      </Flex>
    ) : (
      installationsOwned.length > 0 ? (
        <VStack
        animation={fadeIn}
        spacing=''
        w={isMobile ? '98vw' : 'full'}
        h='full'
        bg=''>
        <SectionHeader title='Parcel Items' />
          <HStack
            spacing=''
            justify='center'
            align='center'
            w='full'
            h='fit-content'
            borderBottom='1px'
            borderColor='mainUi.darker'
            bgGradient='linear(to-r, mainUi.warmDark 4%, mainUi.dark 80%, mainUi.darker 50%, mainUi.dark 80%, mainUi.warmDark 96%)'>
        <SortBar
          options={INSTALLATION_SORT_OPTIONS}
          setSortBy={setSortItemsBy}
          setSortDir={setItemSortDir}
          sortByPressed={sortItemsBy}
          sortDirPressed={itemSortDir} />
        <FilterByStat
          filteringFor='installations'
          filterList={FILTER_NAMES}
          isPressed={filterState}
          setFilter={setItemFilter} />
          </HStack>
        <Wrap
          ref={viewPortRef}
          justify='center'
          overflowY='scroll'
          paddingTop='10px'
          h='full'
          w='full'
          overflowX='hidden'
          bg='mainUi.dark'
          sx={{
            '&::-webkit-scrollbar': {
              borderRadius: '1px',
              width: '10px',
              backgroundColor: 'mainUi.dark',
            },
            '&::-webkit-scrollbar-track': {
              marginRight: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'mainUi.warmDark',
            },
          }}>
            {/* <InstImage
              id={55}
              name={'Graand Fountain'}
              rarity={'Uncommon'}
              type={'cosmetic'} /> */}
          {installationsOwned?.filter(INSTALLATION_FILTERS[filterState])
            .sort(propComparator(sortItemsBy, itemSortDir)).map((inst: any) => {
              return (
                <InstCard
                  viewPortRef={viewPortRef}
                  key={inst.typeId}
                  inst={inst} />
              )
            }
            )
          }
        </Wrap>
      </VStack>
      ) : (
        <VStack animation={fadeIn} w='full' bg='' h='full' align='center' justify='center' spacing='20px'>
        <Icon h='100px' w='100px' fill='mainUi.ggMagenta' as={AavegotchiVerse} />
        <Text
          maxW='200px'
          fontWeight='bold'
          fontSize='sm'
          align='center'
          textColor='mainUi.warm'
          sx={{ lineHeight: '16px' }}>
          you don't own any parcel items...
          <a style={styles.a} href="https://app.aavegotchi.com/baazaar/installations?sort=latest&filter=all" target="_blank"> visit the baazaar </a>
          to purchase some and
          <a style={styles.a} href="https://app.aavegotchi.com/baazaar/installations?sort=latest&filter=all" target="_blank"> equip your parcels!</a>
        </Text>
      </VStack>
      )
     )
  )
};