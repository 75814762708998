import { HStack, Text, VStack, Spinner, Tooltip } from '@chakra-ui/react';
import millify from 'millify';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { blocksToAge } from '../../utils/EcosystemHelpers';

export default function GotchiAge(props: any) {
    const { age } = props;
    const { block } = useContext(UserContext)

    return (
        <Tooltip variant='dash' label={<HStack spacing='2px' align='center' justify='center'>
            <Text
                variant='identifier'
                textColor='mainUi.ggMagenta'>
                BLOCKS 
                </Text>
                <Text
                variant='identifier'
                textColor='mainUi.warm'>
                {millify(block - age, {precision: 2})}
                </Text>
        </HStack>}>
        <VStack
            align='center'
            justify='center'
            w='60px'
            h='29px'
            bg='mainUi.darker'
            spacing='2px'
            border='1px'
            borderColor='mainUi.warmDark'>
            <HStack
                align='center'
                justify='center'
                spacing='2px'>
                {block ? (
                    <Text
                        variant='identifier'
                        textColor='mainUi.warm'
                        sx={{ lineHeight: '9px' }}>
                        {blocksToAge(Math.round((block - age) / 1000000) * 1000000)?.tier}
                    </Text>) : (
                    <Spinner
                        h='9px'
                        w='9px'
                        thickness='2px'
                        color='mainUi.warm' />
                )}
            </HStack>
        </VStack>
        </Tooltip>
    );
}