import { Button, Text, useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';

export default function Donate(props: any) {
    const [clicked, setClicked] = useState(false);
    const address = '0x395D3C23a0863919C76A9FdDb9c45ba91EE16615'
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    const handleOnClick = () => {
        setClicked(true);
        navigator.clipboard.writeText(address);
        setTimeout(() => setClicked(false), 3000)
    }

    return (
        <Button
            variant='dash'
            key='donate'
            size='sm'
            h='full'
            w='80px'
            textColor='mainUi.ggMagenta'
            isActive={clicked ? true : false}
            _active={{
                textColor: 'mainUi.warm'
            }}
            padding='-1'
            onClick={() => { handleOnClick() }}>
            <Text
                h='fit-content'
                variant='identifier'
                fontSize='10px'
                textColor='inherit'>
            {clicked ? 'address copied!' : 'donation address'}
            </Text>
        </Button>
    );
}