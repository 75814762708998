import { Image, HStack, Spacer, VStack, useMediaQuery, Text, Flex } from '@chakra-ui/react';
import ExternalLinks from './ExternalLinks';
import AssetLib from '../assets/images/AssetLib';
import { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import CurrencySelect from './CurrencySelect';
import Donate from './Donate';
import TokenTicker from './TokenTicker/TokenTicker';

export default function Header(props: any) {
  const { userAddress } = useContext(UserContext);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  // await provider.lookupAddress("0x5555763613a12D8F3e73be831DFf8598089d3dCa");
  // // 'ricmoo.eth'
  return (
      <HStack
        w='full'
        h='50px'
        pt='4px'
        pb='4px'
        pl='5px'
        pr='5px'
        align='center'
        justify='center'>
          {!isMobile ? (
        <Image
          h='full'
          src={AssetLib.nameToLogo('gotchidash')} />) : (null)}
          {!isMobile ? (
          <VStack align='start' spacing='4px' justify='center' h='fit-content'>
            <Donate />
          </VStack>
          ) : null}
        <Spacer />
        <ExternalLinks />
        <Spacer />
        {isMobile ? (
          null
          ) : (
        <HStack h='full' w='fit-content' align='center' justify='center'>
        <CurrencySelect />
        <Text
          w='fit-content'
          fontSize={['8px', '9px', '10px', '10px']}
          variant='identifier'>
          {userAddress.substring(0, 6)}
          <br />
          ...{userAddress.substring(37, 41)}
        </Text>
        </HStack>
          )}
        
      </HStack>
  );
}