import { Image, Flex, Spinner } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { fadeIn } from '../../styles/Animations';
import { rarityToColour } from '../../utils/EcosystemHelpers';

export default function ItemSvg(props: any) {
    const { id, rarity } = props;
    const componentMounted = useRef(true)
    const { userMedia } = useContext(UserContext)
    const [ loading, setLoading ] = useState(true);

    const getItemSvg = async () => {
        try {
            const response = await fetch(`https://app.aavegotchi.com/images/items/${id}.svg`)
            .then((res: any) => { return res.blob(); })
            .then((blob: any) => { return URL.createObjectURL(blob); })

            userMedia['items'][id] = response
            if (componentMounted.current) {
                setLoading(false)
            }
        } catch (error: any) {
            setTimeout(getItemSvg, 5000)
            throw new Error(error)
        }
    }

    useEffect(() => {
        if (!userMedia['items'][id]) {
            getItemSvg()
        } else {
            setLoading(false)
        }
        return () => {
            componentMounted.current = false;
        }
    }, [])
    
    return (
        <Flex
            h='100px'
            w='full'
            position='absolute'
            justify='center'
            align='center'>
            {!loading ? (
                <Image
                    animation={fadeIn}
                    // sx={{ filter: 'drop-shadow(0px 0px 12px rgb(0 0 0 / 0.4))' }}
                    h='full'
                    w='full'
                    src={userMedia['items'][id]} />
            ) : (
                <Spinner
                    h='15px'
                    w='15px'
                    color={rarityToColour[rarity].dark}
                    thickness='2px' />
            )}
        </Flex>
    )
}