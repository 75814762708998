import { HStack, Text, VStack } from '@chakra-ui/react';
import { collateralObjects } from '../../utils/EcosystemHelpers';

export default function GotchiCollateral(props: any) {
    const { collateral } = props;

    return (
        <HStack
            align='center'
            justify='center'
            w='full'
            h='29px'
            bg='mainUi.darker'
            spacing='2px'
            border='1px'
            borderColor='mainUi.warmDark'>
            <VStack
                align='center'
                justify='center'
                spacing='2px'>
                 <Text
                variant='identifier'
                sx={{ lineHeight: '9px' }}>
                {collateralObjects[collateral].name.slice(0,2)}
            </Text>   
                <Text
                variant='identifier'
                textColor='mainUi.warm'
                sx={{ lineHeight: '9px' }}>
                {collateralObjects[collateral].name.slice(2)}
            </Text>
            </VStack>
        </HStack>
    );
}