export default {
     traitToImage(trait: any) {
       return require(`../images/traits/${trait}.png`);
     },

     tickerToImage(ticker: any) {
       return require(`../images/tokens/${ticker.toLowerCase()}.png`);
     },

     nameToLogo(name: any) {
       return require(`../images/logos/${name}.png`)
     },

     customH1Background() {
       return require('../images/bgs/customHaunt1.png')
     },

     installationIdToImage(id: any) {
      try {
        const instImage = require(`../images/installations/${id}.png`);
        return (
          instImage
        )
      } catch (error: any) {
        return require(`../images/installations/placeholder.png`)
    
      }}
}