import { Button, Text, HStack, useMediaQuery, VStack, Icon, Tooltip } from '@chakra-ui/react';
import { AavegotchiDAO, AavegotchiHome, AavegotchiWiki, AavegotchiVerse, AavegotchiBaazaar, AavegotchiLending, AavegotchiStake, AavegotchiRecipes, TheVault, Aadventure, Fireball, Stats, AavegotchiRequests } from '../utils/EcosystemIcons';

const ecosystemPlaces = [
    {
        name: "home",
        url: "https://www.aavegotchi.com/",
        icon: <AavegotchiHome fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official Homepage'
    },
    {
        name: "dao",
        url: "https://dao.aavegotchi.com/",
        icon: <AavegotchiDAO fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official DAO'
    },
    {
        name: "wiki",
        url: "https://wiki.aavegotchi.com/",
        icon: <AavegotchiWiki fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official Wiki'
    },
    {
        name: "verse",
        url: "https://verse.aavegotchi.com/",
        icon: <AavegotchiVerse fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official Gotchiverse'
    },
    {
        name: "baazaar",
        url: "https://app.aavegotchi.com/baazaar",
        icon: <AavegotchiBaazaar fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official Baazaar'
    },
    {
        name: "lending",
        url: "https://app.aavegotchi.com/lending",
        icon: <AavegotchiLending fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official Lending page'
    },
    {
        name: "stake",
        url: "https://app.aavegotchi.com/stake",
        icon: <AavegotchiStake fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to the official Stake page'
    },
    {
        name: "recipes",
        url: "https://docs.gotchiverse.io/resources/crafting-recipes",
        icon: <AavegotchiRecipes fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to docs.gotchiverse'
    },
    {
        name: "aadventure",
        url: "https://www.aadventure.io/",
        icon: <Aadventure fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: "Link to Eitri's Aadventure.io"
    },
    {
        name: "stats",
        url: "https://aavegotchistats.com/",
        icon: <Stats fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to Aavegotchistats.com'
    },
    {
        name: "vault",
        url: "https://www.gotchivault.com/",
        icon: <TheVault fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: 'Link to GotchiVault.com (unaffiliated)'
    },
    {
        name: "fireball",
        url: "https://fireball.gg/",
        icon: <Fireball fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: "Link to Orden DAO's Fireball.gg"
    },
    {
        name: "requests",
        url: "https://aavegotchi.canny.io/",
        icon: <AavegotchiRequests fill='currentColor' boxSize={['16px', '18px', '20px', '20px']} />,
        label: "Link to the official Aavegotchi Canny.io"
    }
]

export default function ExternalLinks() {
    const [isMobile] = useMediaQuery("(max-width: 768px)");

    return (
        <HStack
            h='full'
            spacing={isMobile ? '2px' : '4px'}
            justify='center'
            align='center'
            overflowX='auto'
            w='full'
            // pl={isMobile ? '15px' : ''}
            sx={
                {
                    '&::-webkit-scrollbar': {
                        width: '3px',
                        height: '3px',
                        backgroundColor: 'mainUi.warmDark',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'mainUi.ggMagenta',
                    }
                }
            }
            overflowY='hidden'>
            {ecosystemPlaces.map((place) => {
                return (
                    <Tooltip 
                    key={place.name}
                    variant='dash' label={place.label}>
                        <Button
                            iconSpacing=''
                            key={place.name}
                            w='fit-content'
                            h='full'
                            aria-label={place.label}
                            onClick={() => window.open(place.url, "_blank")}
                            size='sm'
                            pr='-2'
                            pl='-2'
                            fontSize='x-small'
                            variant='dash'
                            role='group'>
                            <VStack spacing='' align='center' justify='center' h='full' w='fit-content'>
                                {place.icon}
                                {isMobile ? (
                                    null
                                ) : (
                                    <Text _groupHover={{ textColor: 'mainUi.ggMagenta' }} variant='identifier' color='mainUi.warm'>
                                        {place.name}
                                    </Text>)}
                            </VStack>
                        </Button>
                    </Tooltip>
                )
            })}
        </HStack>
    )
}

